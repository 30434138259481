import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-api-documentation',
  templateUrl: './api-documentation.component.html',
  styleUrls: ['./api-documentation.component.css'],
  encapsulation: ViewEncapsulation.Emulated
})
export class ApiDocumentationComponent implements OnInit {
  apiEndpointLink = 'https://alspeech.prod.algobrain.ai/bff/api/v1';
  supportMail = 'support@algobrain.ai';
  webhookURL = { "webhookUrl": "https://custom-url-example" }
  badSubmitJobRequest = {
    'parameter': {
      'source_config.url': [
        'The url field is required.'
      ]
    },
    "type": "https://www.rev.ai/api/v1/errors/invalid-parameters",
    "title": "Your request parameters didn't validate",
    "status": 400
  }
  // addWebhookCurl = 'curl -X POST "https://alspeech.prod.algobrain.ai/bff/user-manager-configuration/login" -H "accept: */*" -H "Content-Type: application/json" -d "{ \"email\": \"email@test.com\", \"password\": \"******\"}"'
  addWebhookCurl = 'curl -X POST "https://alspeech.prod.algobrain.ai/bff/api/v1/webhook?webhook=http://custom-url" -H "accept: */*" -H "api-key: secret-api-key" -d';
  ResponseExemple = {
    "message": "Well received, as wished you will be notified on https://custom-url-example"
  }
  ResponseExemple1 = {
    "file_name": "string",

    "transcription_id": "3fa85f64-5717-4562-b3fc-2c963f66afa6"
  }
  ResponseExemple3 = {
   "data": {
     "transcription_id": "123e4567-e89b-12d3-a456-426614174000",

     "transcription_status": "Transcribed",

     "total_time_decoding_left": 1200,

     "created_at": "11/30/2022, 23:15:35",
   },
    "seal": "f1f89fead5c782519a7a73d26d7a6509f62b511302039e3bf22aee7c33cc24d5"
  }

  exempleResponse4 = {
    "decoding_time": 10,
    "text": "transcribed text",
    "words": [
      {
        "confidenceScore": 0.8,
        "start": 6,
        "end": 12,
        "word": "transcribed"
      },
      {
        "confidenceScore": 0.7,
        "start": 12,
        "end": 14,
        "word": "text"
      }
    ]
  }
  exempleResponse6={
    "status" :"Transcribed"
  }

  exempleResponse5 ={
    "message": "The transcription is still underway. If you've already configured a webhook URL, stay tuned you'll get notified."
  }
  constructor() { }
  ngOnInit(): void {
  }
  copy(copyValue: any) {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = JSON.stringify(copyValue);
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }
  roundPercentage(value) {
    return Math.floor(value);
  }


 scrollOverView() {
  document.querySelector('#overview-section').scrollIntoView({ behavior: 'smooth', block: 'center' });
}
scrollAPIendpoint(){
  document.querySelector('#ApiEndpoint-Section').scrollIntoView({ behavior: 'smooth', block: 'center' });
}

scrollApiKey(){
  document.querySelector('#ApiKey-Section').scrollIntoView({ behavior: 'smooth', block: 'center' });

}

scrollApiFormats(){
  document.querySelector('#ApiFormat-Section').scrollIntoView({ behavior: 'smooth', block: 'center' });

}

scrollApiLimits(){
  document.querySelector('#ApiLimit-Section').scrollIntoView({ behavior: 'smooth', block: 'center' });

}

scrollAlspeeshApi(){
  document.querySelector('#alspeeshApi-Section').scrollIntoView({ behavior: 'smooth', block: 'center' });
}

scrollWebHookConfig() {
  document.querySelector('#webhook-config').scrollIntoView({ behavior: 'smooth', block: 'center' });
}

scrollUploadFile(){
  document.querySelector('#uploadfile').scrollIntoView({ behavior: 'smooth', block: 'start' });

}

scrollNotification(){
  document.querySelector('#notification-Section').scrollIntoView({ behavior: 'smooth', block: 'start' });

}

scrollTranscriptionResult(){
  document.querySelector('#transcriptionResult-Section').scrollIntoView({ behavior: 'smooth', block: 'start' });

}

scrollTranscriptionStatus(){
  document.querySelector('#transcriptionStatus-Section').scrollIntoView({ behavior: 'smooth', block: 'start' });

}
scrollError(){
  document.querySelector('#error-Section').scrollIntoView({ behavior: 'smooth', block: 'start' });

}

}

