import { MatPaginatorModule } from '@angular/material/paginator';
import { CustomTableComponent } from './custom-table/custom-table.component';
import {MatTableModule} from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import {MatCheckboxModule} from '@angular/material/checkbox';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ReactiveFormsModule } from '@angular/forms';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { CommonModule } from '@angular/common';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { NgModule } from '@angular/core';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MultiChartComponent} from './multi-chart/multi-chart.component';

@NgModule({
    declarations: [
        CustomTableComponent,
        MultiChartComponent],
    imports: [
      CommonModule,

      MatProgressBarModule,
      MatTooltipModule,
      MatIconModule,
      MatFormFieldModule,

      MatInputModule,
      MatButtonModule,
      MatSelectModule,
      MatDatepickerModule,

      MatMenuModule,

      ReactiveFormsModule,
      MatProgressSpinnerModule,
      MatSnackBarModule,
      MatTooltipModule,
      MatCheckboxModule,

      MatTableModule,
      MatSortModule,
      MatPaginatorModule,
    ],
    exports: [

        CustomTableComponent,
        MultiChartComponent
    ],
  })

export class SharedModule {}
