import {Component, Inject, OnInit, Optional, ViewEncapsulation} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {AuthService} from 'src/shared/service/auth/auth.service';
import {NotificationService} from 'src/shared/service/notification/notification.service';
import {TrascriptionServiceService} from 'src/shared/service/transcriptions/trascription-service.service';

@Component({
    selector: 'app-edit-model',
    templateUrl: './edit-model.component.html',
    styleUrls: ['./edit-model.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class EditModelComponent implements OnInit {
    speakerSeleted: any;
    valueToCheck = [

        {'value': 'true', ID: 'D2', 'name': 'Yes', 'checked': true},
        {'value': 'false', ID: 'D1', 'name': 'No', 'checked': false}
    ];
    selectedmodelchanged = false;
    selectedValue: any;
    modelsavailable: any = [];
    skillNameSelected: any;
    updateButtonDisable = false;

    checked = false;
    disabled = false;

    constructor(
        private dialogRef: MatDialogRef<EditModelComponent>,
        @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
        private transcriptionservice: TrascriptionServiceService,
        public authService: AuthService,
        public notificationService: NotificationService
    ) {
        console.log(data);
    }


    radioChange(event) {
        console.log(event);
        console.log(event.value);
        this.updateButtonDisable = true;
        this.speakerSeleted = event.value;
    }

    ngOnInit(): void {
        this.skillNameSelected = this.data.transcriptionModel;
        this.selectedValue = this.data.skill_id;
        this.speakerSeleted = this.data.speaker;
        console.log('rrrrrrrrrrrrrrrrrrrr', this.speakerSeleted);

        this.get_available_models();


    }

    onToggle(event) {
        console.log(event);
        this.speakerSeleted = event.checked;
        console.log(this.speakerSeleted);
        this.updateButtonDisable = true;
    }


    close(): void {
        this.updateButtonDisable = false;
    }


    updateTranscriptionCondition() {
        const dataToAdd = {
                'transcription_id': this.data.transcription_id,
                'skill_id': this.selectedValue,
                'skill_name': this.skillNameSelected,
                'speakers': this.speakerSeleted
            };


        this.transcriptionservice.updateTranscriptionCondition(dataToAdd).subscribe(response => {

                if (response.status === 200) {
                    console.log('response from updateTranscriptionCondition', response);
                    this.transcriptionservice.needRefreshAfterUpdateBehave(true);

                }
            },
            (error => {
                console.log('there is a problem updating the model');
            })
        );
    }

    selectChangeHandler(event: any) {
        this.selectedmodelchanged = true;
        this.updateButtonDisable = true;
        this.selectedValue = event.target.value;
        this.skillNameSelected = event.target.selectedOptions[0].id;
    }

    get_available_models() {
        this.modelsavailable = [];
        this.transcriptionservice.getModels().subscribe((data: any) => {
            Object.keys(data).forEach((key) => {
                this.modelsavailable.push({name: data[key].id, id: key, skillname: data[key].name});
            });
        });
    }


    closeModal() {
        this.dialogRef.close();
    }
}

