import {HttpEventType, HttpResponse} from '@angular/common/http';
import {AfterViewInit, Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild} from '@angular/core';
import {FormControl} from '@angular/forms';
import {DomSanitizer} from '@angular/platform-browser';

import {ToastrService} from 'ngx-toastr';
import {FileUploadService} from '../../../shared/service/file-upload.service';
import {fileDetailsModeltext} from './../../../shared/models/fileDetailsModeltext';
import {NgWaveformComponent} from 'ng-waveform';
import {UserInfoService} from '../../../shared/service/user-info/user-info.service';

interface Model {
    value: string;
}

interface uploadedfile {
    fileuploaded: any;
    modeloffile: string;
}

@Component({
    selector: 'app-file-drag',
    templateUrl: './file-drag.component.html',
    styleUrls: ['./file-drag.component.scss'],
})
export class FileDragComponent implements OnInit, AfterViewInit, OnChanges {
    panelOpenState = false;
    file: any = [];
    selectedValue = '';
    fileDetailsModeltext = new fileDetailsModeltext();
    spanwordisclicked: any;
    uploadedFiles: uploadedfile[] = [
        {
            fileuploaded: this.file,
            modeloffile: this.selectedValue,
        },
    ];
    models: Model[] = [
        {value: 'model1'},
        {value: 'model2'},
        {value: 'model3'},
    ];
    msbapTitle = 'Audio Title';

    msbapDisplayTitle = true;
    blobUrlofaudio: any;

    videofile = false;
    audiofile = false;
    start = false;
    uploadloadingerror = false;
    binaryData = [];
    @Input() files: any = [];
    name: string;
    loading = true;
    uploadloading = false;
    isSrcError = false;
    msg = '';
    test: any;
    url: any;
    filetypeuploaded = '';

    src: string;
    fileState: any;
    regionStartCtrl = new FormControl(0);
    regionEndCtrl = new FormControl(0);
    urlfile = '';
    uploaddone = false;
    videourl = '';
    videourlempty = true;
    clickplay = false;
    uploadcompleted = false;
    progress = 0;
    @Output() sendFileEvent = new EventEmitter<string>();
    @Output() sendFile = new EventEmitter<any>();
    @Output() uploadevent = new EventEmitter<boolean>();
    @Output() uploadeventdone = new EventEmitter<boolean>();
    @Output() startevent = new EventEmitter<boolean>();
    @Output() progressevent = new EventEmitter<any>();

    @ViewChild('content', {static: false}) el!: ElementRef;
    @ViewChild('fileDropRef', {static: false}) fileDropEl: any;
    @ViewChild('waveform') waveform: NgWaveformComponent;
    @ViewChild('myaudio') audioPlayerRef: any;
    @ViewChild('myVideo') videoPlayerRef: any;

    testurl: any;
    videotag = document.getElementById('myVideo');
    time = 0;
    audiopuase = false;
    audioplay = false;
    private userInfo: any;

    constructor(
        private sanitizer: DomSanitizer,
        private fileUploadService: FileUploadService,
        private toastr: ToastrService,
        private userInfoService: UserInfoService
    ) {
    }

    ngOnChanges(): void {
    }

    ngAfterViewInit(): void {
        // this.urlwave= this.blobUrl
    }

    ngOnInit() {
    }

    fileBrowseHandler1($event: any) {
        const userIdFromCookie = this.userInfoService.getCookie('user-info-id');
        if (!userIdFromCookie) {
            this.userInfoService.getUserInfo().subscribe((resp: any) => {
                const info = {
                    ipAddress: resp.ip,
                    browser: UserInfoService.getBrowser(),
                    browserVersion: this.userInfoService.getBrowserVersion(),
                    country: resp.country_name,
                    countryCode: resp.country_code,
                    timeZone: resp.time_zone,
                    regionCode: resp.region_code,
                    regionName: resp.region_name,
                    latitude: resp.latitude,
                    longitude: resp.longitude,
                    isLive: false,
                    decoding_model_id: this.selectedValue
                };
                this.userInfoService.sendUserInfo(info).subscribe(datas => {
                    this.userInfo = datas;
                    this.userInfoService.setCookie('user-info-id', this.userInfo.id, 30);
                });
            });
        }

        this.progress = 0;
        this.test = false;
        const file = $event.target.files[0];
        const mimeType = $event.target.files[0].type;
        const filesize = $event.target.files[0].size;
        const maxsize = 1024 * 1024 * 20;
        const maxsizemp3 = 1024 * 1024 * 5;
        console.log('testmimetype', mimeType, filesize);
        if (mimeType.match(/audio\/*/) == null) {
            this.msg = 'Only audio  files are supported';

            return;
        }
        if (filesize >= maxsizemp3 && mimeType == 'audio/mpeg') {
            console.log('Maximum size exceeded! maximum 5 MO for mp3 audio');
            this.msg = 'Maximum size exceeded! maximum 5 MO for mp3 audio';

            return;
        }
        if (filesize >= maxsize) {


            this.msg = 'Maximum size exceeded! maximum 20 MO';

            return;


        }

        const reader = new FileReader();
        reader.readAsDataURL(file);

        this.sendFileEvent.emit($event.target.files[0]);
        this.start = true;

        reader.onload = () => {
            this.msg = '';

            this.playAudio(reader.result);
            //  localStorage.setItem("files",this.blobUrl);

            this.testurl = this.blobUrlofaudio.changingThisBreaksApplicationSecurity;
            this.selectedValue = '';
        };

        this.fileUploadService.uploadFile(file).subscribe(
            (event) => {
                if (event.type === HttpEventType.UploadProgress) {
                    this.progress = Math.round((100 * event.loaded) / event.total);
                    this.progressevent.emit(this.progress);
                    this.uploadloading = true;
                    this.uploadevent.emit(this.uploadloading);
                } else if (event instanceof HttpResponse) {
                    this.uploadcompleted = true;
                    this.uploadeventdone.emit(this.uploadcompleted);
                    this.fileUploadService.fileUploadedResp(event.body);
                    this.startevent.emit(this.start);
                    this.prepareFilesList(
                        $event.target.files,
                        this.files.length,
                        this.testurl
                    );
                }

            },
            (err) => {
                console.log(err);
                if (err.status == 422) {
                    this.uploadloadingerror = true;
                    this.msg = 'Uploaded file is not valid';

                }

                this.uploadloading = false;

            }
        );
        this.uploadloadingerror = false;
        this.filetypeuploaded = mimeType;
        if (this.filetypeuploaded === 'video/mp4') {
            this.videofile = true;
        }
        if (this.filetypeuploaded === 'audio/wav') {
            this.audiofile = true;
        }

        this.uploaddone = true;
        this.uploadloading = true;
        console.log('this is bloburl', this.blobUrlofaudio);
    }

    /**
     * Simulate the upload process
     */
    // uploadFilesSimulator(index: number) {
    //   setTimeout(() => {
    //     if (index === this.files.length) {
    //       return;
    //     } else {
    //       const progressInterval = setInterval(() => {
    //         if (this.files[index].progress === 100) {
    //           clearInterval(progressInterval);

    //           this.uploadFilesSimulator(index + 1);
    //         } else {
    //           this.files[index].progress += 5;
    //         }
    //       }, 50);
    //     }
    //   }, 200);
    // }

    /**
     * Convert Files list to normal array list
     * @param files (Files List)
     */
    prepareFilesList(files: Array<any>, index: Number, urlvideos: any) {
        for (const item of files) {
            item.progress = 0;
            item.urlvideo = urlvideos;
            item.index = index;
            this.files.push(item);
            console.log(files[0]);
            this.sendFile.emit(files[0]);
        }

        // let filesStorage = JSON.parse(localStorage.getItem("files") || "{}");
        // if (filesStorage) {
        //   localStorage.removeItem("files");
        //   localStorage.setItem("files", JSON.stringify(this.files));
        // } else {
        //   localStorage.setItem("files", JSON.stringify(this.files));
        // }

        this.fileDropEl.nativeElement.value = '';
        // this.uploadFilesSimulator(0);
    }

    /**
     * format bytes
     * @param bytes (File size in bytes)
     * @param decimals (Decimals point)
     */
    formatBytes(bytes: any, decimals = 2) {
        if (bytes === 0) {
            return '0 Bytes';
        }
        const k = 1024;
        const dm = decimals <= 0 ? 0 : decimals;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }

    /**
     * format bytes
     *convert data to binary
     * this will be instanciated in playAuido
     */
    convertDataURIToBinary(dataURI: any) {
        const BASE64_MARKER = ';base64,';
        const base64Index = dataURI.indexOf(BASE64_MARKER) + BASE64_MARKER.length;
        const base64 = dataURI.substring(base64Index);
        const raw = window.atob(base64);
        const rawLength = raw.length;
        const array = new Uint8Array(new ArrayBuffer(rawLength));

        for (let i = 0; i < rawLength; i++) {
            array[i] = raw.charCodeAt(i);
        }
        return array;
    }

    /**
     *
     *make an url for the file uploaded
     *
     */

    playAudio(data: any) {
        const binary = this.convertDataURIToBinary(data);
        const blob = new Blob([binary]);
        // localStorage.setItem("test",JSON.stringify(binary));
        this.blobUrlofaudio = this.sanitizer.bypassSecurityTrustUrl(
            URL.createObjectURL(blob)
        );
    }

    /**
     *
     *get selected value from the child (transcript component)
     *
     */

    handleselectedvalue(selectedvaluemodel: any) {
        this.files[selectedvaluemodel.index].selectedModel =
            selectedvaluemodel.selectedModel;
    }
}
