import {Injectable} from '@angular/core';
import {HttpClient, HttpEvent, HttpHeaders, HttpRequest} from '@angular/common/http';
import {BehaviorSubject, Observable} from 'rxjs';

import {environment} from '../../environments/environment.prod';

const GATEWAY_URL = environment.GATEWAY_URL_DEMO || 'https://alspeech.dev.algobrain.ai/demo';
const endpoint1 = 'https://alspeech.dev.algobrain.ai/api';


const httpOptions = {
    headers: new HttpHeaders({'Access-Control-Allow-Origin': '*'}),
};

@Injectable({
    providedIn: 'root',
})
export class FileUploadService {
    // API url
    // GATEWAY_URL = "http://192.168.2.97:8000";
    selecteditem = [];
    uploadmodalSelected: BehaviorSubject<boolean> = new BehaviorSubject(false);
    selected: BehaviorSubject<any> = new BehaviorSubject(this.selecteditem);
    uploadFileResponse: BehaviorSubject<any> = new BehaviorSubject(null);

    constructor(private http: HttpClient) {
    }

    // Returns an observable
    createmodeltotextmodel1(file: any): Observable<any> {
        // Create form data
        const formData = new FormData();

        // Store form name as "file" with file data
        formData.append('file', file);

        // Make http post request over api
        // with formData as req
        return this.http.post(`${GATEWAY_URL}/model/predict`, formData);
    }


    uploadFile(file: File): Observable<HttpEvent<any>> {
        const formData: FormData = new FormData();

        formData.append('file', file);

        const request = new HttpRequest('POST', `${GATEWAY_URL}/file/upload`, formData, {
            reportProgress: true,
            responseType: 'json'
        });

        return this.http.request(request);
    }

    createmodeltotext(fileDetails: any): Observable<any> {
        return this.http.post(
            `${GATEWAY_URL}/model/transcript`,
            fileDetails,
            httpOptions
        );
    }

    getmodels() {
        return this.http.get<any[]>(`${GATEWAY_URL}/available_offline_decoders`);
    }

    createmodelKaldi(fileDetails: any): Observable<any> {
        const request = new HttpRequest('POST', `${GATEWAY_URL}/transcript_file`, fileDetails, {
            reportProgress: true,
            responseType: 'json'
        });
        return this.http.request(request);
    }

    transcribeNowDemo(modelId, userId, fileId, userInfoId): Observable<any> {

        return this.http.post(`${GATEWAY_URL}/transcript_offline`,
            {
                model_id : modelId,
                file_id: fileId,
                user_id: userId,
                user_info_id: userInfoId
            });
    }

    create1(file: any): Observable<any> {
        const formData = new FormData();

        // Store form name as "file" with file data
        formData.append('file', file);
        return this.http.post(`${GATEWAY_URL}/predict3`, formData, httpOptions);
    }

    fileSelected$() {
        return this.selected.asObservable();
    }

    returnIdFileSelected(i) {
        this.selected.next(i);
    }

    fileUploaded$() {
        return this.uploadFileResponse.asObservable();
    }

    fileUploadedResp(item) {
        this.uploadFileResponse.next(item);
    }

    uploadmodalSelected$() {
        return this.uploadmodalSelected.asObservable();
    }

    returnuploadmodalSelected(i: boolean) {
        this.uploadmodalSelected.next(i);
    }
}
