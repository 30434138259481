import { Component, OnInit } from '@angular/core';
import { NotificationService } from 'src/shared/service/notification/notification.service';

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.css']
})
export class PaymentComponent implements OnInit {

  constructor(
    private notificationService:NotificationService
  ) { }

  ngOnInit(): void {
    this.notificationService.pageHeaderContent('Payment')

  }

}
