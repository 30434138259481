import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, FormGroupDirective, NgForm, Validators} from '@angular/forms';
import {ErrorStateMatcher} from '@angular/material/core';
import {Mail} from 'src/shared/models/User';
import {AuthService} from 'src/shared/service/auth/auth.service';
import {ContactService} from 'src/shared/service/contact/contact.service';
import {NotificationService} from 'src/shared/service/notification/notification.service';

export class MyErrorStateMatcher implements ErrorStateMatcher {
    isErrorState(
        control: FormControl | null,
        form: FormGroupDirective | NgForm | null
    ): boolean {
        const isSubmitted = form && form.submitted;
        return !!(
            control &&
            control.invalid &&
            (control.dirty || control.touched || isSubmitted)
        );
    }
}

@Component({
    selector: 'app-contact-us',
    templateUrl: './contact-us.component.html',
    styleUrls: ['./contact-us.component.css'],
})
export class ContactUsComponent implements OnInit {

    email: any;
    message: any;
    name: any;
    subject: any;

    test: any;
    submitted = false;
    errorMsgFlag = false;
    errorMsg = '';
    user_id: any;
    mailForm: FormGroup;


    matcher = new MyErrorStateMatcher();

    constructor(
        private contactService: ContactService,
        private formBuilder: FormBuilder,
        private authService: AuthService,
        private notificationService: NotificationService
    ) {

    }

    get f() {
        return this.mailForm.controls;
    }

    ngOnInit(): void {

        this.notificationService.pageHeaderContent('Contact Us');
        this.user_id = this.getUser();
        this.authService.getUserInfo(this.user_id).subscribe((userInfo: any) => {

            this.email = userInfo.email;
            this.name = userInfo.first_name + ' ' + userInfo.last_name;
        });
        this.initForm();

    }

    getUser() {
        let user_id = 'test';
        if (this.authService.loggedIn()) {
            const jwtData = this.authService.getJwtData();
            user_id = jwtData.user_id;
        }
        return user_id;
    }

    initForm() {
        this.mailForm = this.formBuilder.group(
            {

                email: ['', [Validators.required, Validators.email]],
                name: ['', Validators.required],
                subject: ['', Validators.required],
                message: ['', Validators.required],

            },
        );
    }

    sendMessage() {
        this.submitted = true;

        const formValue = this.mailForm.value;

        const mail = new Mail(
            this.email,
            this.name,
            formValue['message'],
            formValue['subject']
        );

        this.contactService.contactUs(mail).subscribe(
            (response) => {
                this.mailForm.controls['message'].reset();
                this.mailForm.controls['subject'].reset();
                this.notificationService.showSuccess('An email has been sent successfully');
                    this.errorMsgFlag = false;
            }
            , (error) => {
                this.notificationService.showError('Please try again');
                this.errorMsgFlag = true;
                this.errorMsg = 'Something went wrong ';
            }
        );
    }
}
