import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {NgModule} from '@angular/core';
import {FormBuilder, FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {RouterModule} from '@angular/router';

import {AppComponent} from './app.component';

import {NgbModule} from '@ng-bootstrap/ng-bootstrap';

import {AppRoutingModule} from './app.routing';
import {ScrollPanelModule} from 'primeng/scrollpanel';

import {AlspeechLandingComponent} from './alspeech-landing/alspeech-landing.component';
import {AlspeechLandingBarComponent} from './alspeech-landing/alspeech-landing-bar/alspeech-landing-bar.component';
import {AlspeechDemoComponent} from './alspeech-landing/alspeech-demo/alspeech-demo.component';

import {AlspeechDemoUploadComponent} from './alspeech-landing/alspeech-demo/alspeech-demo-upload/alspeech-demo-upload.component';
import {FileDragComponent} from './alspeech-landing/file-drag/file-drag.component';
import {NgWaveformModule} from 'ng-waveform';
import {ToastrModule} from 'ngx-toastr';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {Playsimple1Component} from './alspeech-landing/alspeech-demo/playsimple1/playsimple1.component';
import {Playsimple2Component} from './alspeech-landing/alspeech-demo/playsimple2/playsimple2.component';
import {Playsample3Component} from './alspeech-landing/alspeech-demo/playsample3/playsample3.component';
import {UploadLoadingComponent} from './alspeech-landing/alspeech-demo/upload-loading/upload-loading.component';
import {SpinnerComponent} from './alspeech-landing/spinner/spinner.component';
import {TranscriptionComponent} from './alspeech-landing/alspeech-demo/transcription/transcription.component';
import {TranscriptionLoadingComponent} from './alspeech-landing/alspeech-demo/transcription-loading/transcription-loading.component';
import {LoadinglogospinnerComponent} from './alspeech-landing/alspeech-demo/loadinglogospinner/loadinglogospinner.component';
import {TextJsonComponent} from './alspeech-landing/alspeech-demo/text-json/text-json.component';
import {RecordVaskComponent} from './alspeech-landing/alspeech-demo/record-vask/record-vask.component';
import {LoginComponent} from './login/login.component';
import {RegisterComponent} from './register/register.component';
import {HttpCancelService} from 'src/shared/service/http-cancel/http-cancel.service';
import {HashLocationStrategy, LocationStrategy} from '@angular/common';
import {ManageHttpInterceptor} from './interceptors/managehttp.interceptor';
import {JwtInterceptor} from './interceptors/jwt.interceptor';
import {AuthGuardService} from 'src/shared/service/auth-guard/auth-guard.service';
import {DashboardModule} from './dashboard/dashboard.module';
import {MDBBootstrapModule} from 'angular-bootstrap-md';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {TrascriptionServiceService} from 'src/shared/service/transcriptions/trascription-service.service';
import {NgxPaginationModule} from 'ngx-pagination';
import {CacheInterceptor} from './interceptors/cache.interceptor';
import {CacheService} from 'src/shared/service/cache/cache.service';
import {AdminModules} from './admin/admin.module';
import {WebhookModule} from './webhook/webhook.module';
import { ApiDocumentationComponent } from './alspeech-landing/api-documentation/api-documentation.component';
import {OpenTelemetryInterceptor} from './interceptors/open-telemetry.interceptor';


@NgModule({
    imports: [

        BrowserAnimationsModule,
        MatProgressSpinnerModule,
        MatProgressBarModule,
        FormsModule,
        NgxPaginationModule,
        HttpClientModule,
        ReactiveFormsModule,
        NgWaveformModule,
        ToastrModule.forRoot(),
        NgbModule,
        RouterModule,
        AppRoutingModule,
        MatIconModule,
        ScrollPanelModule,
        MatButtonModule,
        DashboardModule,
        MatAutocompleteModule,
        AdminModules,
        WebhookModule,

        MDBBootstrapModule.forRoot(),

    ],
    declarations: [
        AppComponent,
        AlspeechLandingComponent,
        AlspeechLandingBarComponent,
        AlspeechDemoComponent,
        SpinnerComponent,
        AlspeechDemoUploadComponent,
        FileDragComponent,
        Playsimple1Component,
        Playsimple2Component,
        Playsample3Component,
        UploadLoadingComponent,
        TranscriptionComponent,
        TranscriptionLoadingComponent,
        LoadinglogospinnerComponent,
        TextJsonComponent,
        RecordVaskComponent,
        LoginComponent,
        RegisterComponent,
        ApiDocumentationComponent,
    ],
    providers: [

        HttpClientModule,
        {provide: LocationStrategy, useClass: HashLocationStrategy},
        HttpCancelService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ManageHttpInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: JwtInterceptor,
            multi: true,
        },
        CacheService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: CacheInterceptor,
            multi: true,
        },
        FormBuilder,
        AuthGuardService,
        TrascriptionServiceService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: OpenTelemetryInterceptor,
            multi: true,
        },
    ],
    exports: [FileDragComponent],
    bootstrap: [AppComponent],

})
export class AppModule {}
