import { formatDate } from '@angular/common';
import { Component } from '@angular/core';
import * as moment from 'moment';
import { TimeToDisplayService } from 'src/shared/service/TimeDay/time-to-display.service';

@Component({
  selector: 'app-time-and-day',
  templateUrl: './time-and-day.component.html',
  styleUrls: ['./time-and-day.component.css']
})
export class TimeAndDayComponent {
  
    

  currentDate = new Date();
  
  
  fullday: any;
 
  constructor(private timeService :TimeToDisplayService) {
    
    
 

    }
 
 
  TodayFormat(){
    var day = moment().format('dddd');
    var totalDay =  moment().format('LL');
    this.fullday= day +","+totalDay
    return this.fullday
  }
}
