import { Component, Input, OnChanges, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-text-record',
  templateUrl: './text-record.component.html',
  styleUrls: ['./text-record.component.scss'],
  encapsulation: ViewEncapsulation.None,


})
export class TextRecordComponent implements OnInit ,OnChanges  {
  statuetextopen = "textclicked"
  statuejsonopen = "textnotclicked"
  showJsonFormat = true;
  texttoshow = "";
  scrollableContainer: HTMLElement;
  @ViewChild("scrollPanel", { static: false }) scrollPanel: any;

  @Input()  textData;
  constructor() { }
  ngOnInit(): void {
    this.texttoshow=this.textData
  }
 
  ngOnChanges(){
    this.texttoshow = this.textData
    this.scrollToHighlight() 
  }
  getScrollableContent() {
    if (!this.scrollableContainer || !this.scrollableContainer.isConnected) {
      this.scrollableContainer =
        this.scrollPanel.el.nativeElement.querySelector(
          ".p-scrollpanel-content"
        );
    }
    
    return this.scrollableContainer;
  }
  scrollToHighlight() {
    if (!this.scrollPanel) return;

    const el: any = document.querySelector(".textjustifier");
    console.log("this is el", el, { ctnr: this.getScrollableContent() });
    const scrollableContainer = this.getScrollableContent();
    console.log("achrefff is testing here", );
     scrollableContainer.scrollTop = this.texttoshow.length;
  }
}
