import {HttpClient, HttpEvent, HttpHeaders, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment.prod';

import {BehaviorSubject, Observable} from 'rxjs';

const BFF_URL = environment.BFF_KEY || 'http://localhost:8000';

@Injectable({
    providedIn: 'root',
})
export class TrascriptionServiceService {
    FileDetails: any = {
        user_id: '',
        skill_id: '',
        skill_name: '',
    };
    url = false;
    urlaudio: any = '';
    dataFiltred: any = '';
    selecteditemDelete = [];
    transcriptionNumber = 0;
    sortItems = {
        offset: this.getselectedItemPerPageProductOffset() || 0,
        limit: this.getselectedItemPerPageProduct() || 5,
    };
    needRefreshAfterUpdateitem = false;
    needRefreshAfterUpdate: BehaviorSubject<any> = new BehaviorSubject(
        this.needRefreshAfterUpdateitem
    );
    resetPageIndex: BehaviorSubject<boolean> = new BehaviorSubject(false);

    transcriptionNumberBehave: BehaviorSubject<any> = new BehaviorSubject(
        this.transcriptionNumber
    );
    sortingStateProduct: BehaviorSubject<any> = new BehaviorSubject(
        this.sortItems
    );
    itemsProduct: BehaviorSubject<any> = new BehaviorSubject(null);

    selectedDelete: BehaviorSubject<any> = new BehaviorSubject(
        this.selecteditemDelete
    );
    sendFiltredData: BehaviorSubject<any> = new BehaviorSubject(this.dataFiltred);
    doRefresh: BehaviorSubject<boolean> = new BehaviorSubject(false);
    state: BehaviorSubject<boolean> = new BehaviorSubject(false);
    BlobFileUrl: BehaviorSubject<boolean> = new BehaviorSubject(this.url);
    BlobFileUrlaudio: BehaviorSubject<any> = new BehaviorSubject(this.urlaudio);
    httpOptions = {
        headers: new HttpHeaders({'Access-Control-Allow-Origin': '*'}),
    };

    constructor(private http: HttpClient) {
    }

    GetuserTranscriptions(user_id: any): Observable<any> {
        return this.http.get<any[]>(
            `${BFF_URL}/transcription/transcription_by_user/${user_id}`,
            this.httpOptions
        );
    }

    GetuserTranscriptionsPAginationForm(
        user_id: any,
        sortingItems
    ): Observable<any> {
        const request = new HttpRequest(
            'POST',
            `${BFF_URL}/transcription/transcription_by_user_updated/${user_id}`,
            sortingItems,

            this.httpOptions
        );

        return this.http.request(request);
    }

    editTranscription(
        editedTranscription: any,
        transcription_id
    ): Observable<any> {
        console.log('test service', editedTranscription);
        const request = new HttpRequest(
            'POST',
            `${BFF_URL}/transcription/edit_transcription/${transcription_id}`,
            editedTranscription,

            this.httpOptions
        );

        return this.http.request(request);
    }

    downloadFile(file_id: any, user_id: any, cache = true): Observable<any> {
        let headers: HttpHeaders;
        if (cache) {
            headers = new HttpHeaders({
                'Access-Control-Allow-Origin': '*',
                'cache-response': 'true',
            });
        }

        return this.http.get(
            `${BFF_URL}/file/file-by-id?file-id=${file_id}&user-id=${user_id}`,
            {
                responseType: 'blob',
                headers: headers,
            }
        );
    }

    downloadmanyFile(file_id, user_id: any): Observable<any> {
        const formData: FormData = new FormData();

        formData.append('files_list', file_id);

        const request = new HttpRequest(
            'POST',
            `${BFF_URL}/file/zip-file?user_id=${user_id}`,
            file_id,
            {
                headers: new HttpHeaders({
                    'Access-Control-Allow-Origin': '*',
                }),
                responseType: 'blob',
            }
        );

        return this.http.request(request);
    }

    download_Transcription_By_Id(
        transcription_id: any,
        file_format: any
    ): Observable<any> {
        return this.http.get(
            `${BFF_URL}/transcription/download_transcription_by_id/${transcription_id}?file_format=${file_format}`,
            {
                responseType: 'blob' as 'json',
            }
        );
    }

    download_Transcription_By_IdForVideo(
        transcription_id: any,
        file_format: any
    ) {
        return this.http.get(
            `${BFF_URL}/transcription/download_transcription_by_id/${transcription_id}?file_format=${file_format}`,
            {
                responseType: 'arraybuffer',
            }
        );
    }

    Get_Transcription_By_Id(
        transcription_id: any,
        cache = true,
        uri?
    ): Observable<any> {
        let headers: HttpHeaders;
        if (cache) {
            headers = new HttpHeaders({
                'Access-Control-Allow-Origin': '*',
                'cache-response': 'true',
            });
        }
        uri = uri
            ? uri
            : `${BFF_URL}/transcription/transcription_by_id/${transcription_id}`;
        return this.http.get(`${uri}`, {
            headers: headers,
        });
    }

    uploadFileUser(
        file: File,
        user_id: any,
        skill_id: any,
        skill_name,
        speakers
    ): Observable<HttpEvent<any>> {
        const formData: FormData = new FormData();

        formData.append('file', file);

        const request = new HttpRequest(
            'POST',
            `${BFF_URL}/file/upload?user_id=${user_id}&skill_id=${skill_id}&skill_name=${skill_name}&speakers=${speakers}`,
            formData,
            {
                reportProgress: true,
                responseType: 'json',
            }
        );

        return this.http.request(request);
    }

    deleteLogicalMultiple(listTranscriptionId) {
        const request = new HttpRequest(
            'POST',
            `${BFF_URL}/transcription/multiple_logical_delete`,
            listTranscriptionId,
            this.httpOptions
        );

        return this.http.request(request);
    }

    getModels() {
        return this.http.get<any[]>(`${BFF_URL}/available-skills`);
    }

    DeleteTranscription(transcription_id: any) {
        return this.http.delete(
            `${BFF_URL}/delete_transcription/${transcription_id}`,
            this.httpOptions
        );
    }

    getTranscriptionWithDiarization(
        transcription_id: any,
        cache = true
    ): Observable<any> {
        let headers: HttpHeaders;
        if (cache) {
            headers = new HttpHeaders({
                'Access-Control-Allow-Origin': '*',
                'cache-response': 'true',
            });
        }
        return this.http.get(
            `${BFF_URL}/transcription/transcription_by_id/${transcription_id}`,

            {
                headers: headers,
            }
        );
    }

    // this service is responsible of the changing of the state of the trascription
    // to change logical delete to 'true'
    LogicalDeleteTranscription(transcription_id: any) {
        const request = new HttpRequest(
            'POST',
            `${BFF_URL}/transcription/logical_delete/${transcription_id}`,

            this.httpOptions
        );

        return this.http.request(request);
    }

    updateTranscriptionCondition(data: any): Observable<any> {
        const request = new HttpRequest(
            'POST',
            `${BFF_URL}/transcription//update_transcription_condition`,
            data,

            this.httpOptions
        );

        return this.http.request(request);
    }

    transcribe_Now(transcription_id: any, option): Observable<any> {
        const request = new HttpRequest(
            'POST',
            `${BFF_URL}/transcription-manager/transcribe-now?id=${transcription_id}&speakers=${option}`,

            this.httpOptions
        );

        return this.http.request(request);
    }

    filterTranscriptionByUser(userId: any, dataFilter): Observable<any> {
        const request = new HttpRequest(
            'POST',
            `${BFF_URL}/transcription/infos_of_products_by_user_id/${userId}`,
            dataFilter,

            this.httpOptions
        );

        return this.http.request(request);
    }

    getFileNamesAutoComplete(userId, data, getall: boolean): Observable<any> {
        const request = new HttpRequest(
            'GET',
            `${BFF_URL}/transcription/file_names/${userId}?file_name=${data}&get_all=${getall}`,

            this.httpOptions
        );
        return this.http.request(request);
    }


    saveWebhookUrl(webhookObj, isTest: boolean): Observable<any> {
        const data = {
            webhookUrl: webhookObj.webhookUrl
        };
        const webhookPath = isTest ? 'webhook/test' : 'webhook';
        return this.http.post(`${BFF_URL}/api/v1/${webhookPath}?security=${webhookObj.securityType}`, data,
            {
            headers: {
                'api-key': webhookObj.apikey,
                'api-token': webhookObj.apiToken,
                'authorization': webhookObj.bearer,
                'username': webhookObj.username,
                'password': webhookObj.password,
            }
        });

    }

    refreshListTranscription() {
        this.doRefresh.next(true);
    }

    refreshCompleted() {
        this.doRefresh.next(false);
    }

    UploadFileState() {
        this.state.next(true);
    }

    UploadFileStateCompleted() {
        this.state.next(false);
    }

    isTranscriptionListNeedRefresh$() {
        return this.doRefresh.asObservable();
    }

    isUpload$() {
        return this.state.asObservable();
    }

    isUrl() {
        return this.BlobFileUrl.asObservable();
    }

    urlValue(url) {
        return this.BlobFileUrl.next(url);
    }

    isUrlAudio$() {
        return this.BlobFileUrlaudio.asObservable();
    }

    isUrlAudioValue(url) {
        return this.BlobFileUrlaudio.next(url);
    }

    isDataFiltred$() {
        return this.sendFiltredData.asObservable();
    }

    dataFiltredHandled(data) {
        return this.sendFiltredData.next(data);
    }

    fileSelectedDelete$() {
        return this.selectedDelete.asObservable();
    }

    returnIdFileSelectedDelete(i) {
        this.selectedDelete.next(i);
    }

    TranscriptionNumber$() {
        return this.transcriptionNumberBehave.asObservable();
    }

    returnTranscriptionNumber(i) {
        this.transcriptionNumberBehave.next(i);
    }

    isSortingStateProduct$() {
        return this.sortingStateProduct.asObservable();
    }

    isSortingStateValuesProduct(items) {
        return this.sortingStateProduct.next(items);
    }

    itemsProductObs$() {
        return this.itemsProduct.asObservable();
    }

    itemsProductValues(items) {
        return this.itemsProduct.next(items);
    }

    getselectedItemPerPageProduct() {
        return localStorage.getItem('itemPerPageProduct');
    }

    setselectedItemPerPageProduct(itemPerPage) {
        return localStorage.setItem('itemPerPageProduct', itemPerPage);
    }

    getselectedItemPerPageProductOffset() {
        return localStorage.getItem('itemPerPageProductOffset');
    }

    setselectedItemPerPageProductOffset(itemPerPageProductOffset) {
        return localStorage.setItem(
            'itemPerPageProductOffset',
            itemPerPageProductOffset
        );
    }

    getselectedIdToUpdate() {
        return localStorage.getItem('selectedIdToUpdate');
    }

    setselectedIdToUpdate(selectedIdToUpdate) {
        return localStorage.setItem('selectedIdToUpdate', selectedIdToUpdate);
    }

    getselectedIdTranscriptionToUpdate() {
        return localStorage.getItem('selectedIdTranscriptionToUpdate');
    }

    setselectedIdTranscriptionToUpdate(selectedIdTranscriptionToUpdate) {
        return localStorage.setItem(
            'selectedIdTranscriptionToUpdate',
            selectedIdTranscriptionToUpdate
        );
    }

    setselectedFileNameToUpdate(selectedFileNameToUpdate) {
        return localStorage.setItem(
            'selectedFileNameToUpdate',
            selectedFileNameToUpdate
        );
    }

    setDataForEditPage(data) {
        return localStorage.setItem('data', data);
    }

    getDataForEditPage() {
        return localStorage.getItem('data');
    }

    getselectedFileNameToUpdate() {
        return localStorage.getItem('selectedFileNameToUpdate');
    }

    needRefreshAfterUpdateBehave$() {
        return this.needRefreshAfterUpdate.asObservable();
    }

    needRefreshAfterUpdateBehave(item) {
        return this.needRefreshAfterUpdate.next(item);
    }

    resetPageIndexBehave$() {
        return this.resetPageIndex.asObservable();
    }

    resetPageIndexBehave(item) {
        return this.resetPageIndex.next(item);
    }
}
