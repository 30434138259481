
export class LoginCredentials {
  constructor(public password: string, public email: string) {}
}
export class User {
  constructor(
   public password: string,
    public email: string,
    public first_name: string,
    public last_name: string,
    public role: string,
    public organization_name?: string,
    public id?: string
  ) {}
}
export class Mail {
  constructor(
   public emailFrom: string,
    public name: string,
    public message: string,
    public subject: string,

  ) {}
}
export class LoginData {
  constructor(public success: string, public token: string) {}
}
export class ApiResponse {
  constructor(public detail: string) {}
}

export class JwtJsonData {
  constructor(
    public iat: string,
    public scope: string,
    public sub: string,
    public user_type: string,
    public user_id: string
  ) {}
}
