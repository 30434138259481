import {Component, OnInit, ViewEncapsulation, Input,  ViewChild} from '@angular/core';
import { NotificationService } from 'src/shared/service/notification/notification.service';
import {AnalyticsService} from '../../../shared/service/analytics/analytics.service';
import {AuthService} from 'src/shared/service/auth/auth.service';
import { MultiChartComponent } from 'src/shared/multi-chart/multi-chart.component';


@Component({
  selector: 'app-analytics',
  templateUrl: './analytics.component.html',
  styleUrls: ['./analytics.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class AnalyticsComponent implements OnInit {

  showChart = false;
  fromDate = new Date().toISOString().split('T')[0];
  toDate = new Date().toISOString().split('T')[0];
  entity: string = null;
  chart1: any;
  chart2: any;
  chartList: any[] = [];
  chartMultiData = [];
  chartsOneLine = false;
  user_id: any;
  dateRange: number;
  noAnalyticsData: boolean;

  constructor(
    private notificationService: NotificationService,
    public authService: AuthService,
    private analyticsService: AnalyticsService
  ) {}

  getUser() {
      let user_id = '';
      if (this.authService.loggedIn()) {
          const jwtData = this.authService.getJwtData();
          user_id = jwtData.user_id;
      }
      return user_id;
  }


  ngOnInit(): void {
    this.user_id = this.getUser();
    this.dateRange = 12;
    this.notificationService.pageHeaderContent('Analytics');
    const gradientChartOptionsConfigurationWithTooltip: any = {
            maintainAspectRatio: false,
            responsive: true,
            legend: {
              display: false,
              position: null
            },
            scales: {
              xaxes: null,
              yAxes: [
                {
                  ticks: {
                    beginAtZero: true
                  }
                }
              ]
        }
    };
    const pieChartOptionsConfigurationWithTooltip: any = {
      maintainAspectRatio: false,
      legend: {
        display: true,
        position: 'right'
      },
      responsive: true,
    };
    this.getCharts()

  }


onRangeChoice(rangeChoice: number) {
  console.log("range choice", rangeChoice);
  this.dateRange = rangeChoice;
  this.getCharts();
 }

getCharts(){
 this.analyticsService.getCharts(this.user_id, this.dateRange).subscribe( result => {
      console.log("user", this.user_id);
      console.log("dateRange", this.dateRange);
      this.chartMultiData = result.body;
      if (this.chartMultiData[0]['data']['datasets'] === undefined || this.chartMultiData[0]['data']['datasets'].length == 0)
      {
          this.noAnalyticsData = true;
      }
      else
      {
      this.noAnalyticsData = false;
      this.chart2 = this.chartMultiData[2];
      this.chartMultiData = this.chartMultiData.slice(0, 2);
      }


    });}
}


