import {
  Component,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild,
  ElementRef,
  ViewEncapsulation,
} from "@angular/core";
import { DictateService } from "src/shared/service/dictate-service";
import * as MicrophonePlugin from "wavesurfer.js/dist/plugin/wavesurfer.microphone.js";
import WaveSurfer from "wavesurfer.js";
import * as moment from "moment";
import { ToastrService } from "ngx-toastr";
import { RecordService } from "src/shared/service/record.service";
import { AuthService } from "src/shared/service/auth/auth.service";
import { environment } from "src/environments/environment";
import { TrascriptionServiceService } from "src/shared/service/transcriptions/trascription-service.service";
const LIVE_KEY = environment.LIVE_KEY;

@Component({
  selector: "app-recorde-dashboard",
  templateUrl: "./record-dashboard.component.html",
  styleUrls: ["./record-dashboard.component.scss"],
  encapsulation: ViewEncapsulation.None,
  providers: [DictateService],
})
export class RecordeDashboardComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild("waveformtest", { static: false }) wavesurfer: any;
  modelsavailable: any = [];
  selectedValue: any = "";
  selectedmodelchanged = false;
  statuerecord = "boutonnotrecord";
  @ViewChild("results") private results: ElementRef;
  microOn = "false";
  textDataBase = "";
  textData = "";
  AudioContext: any;
  webkitAudioContext: any;
  mediaRecorder: any;
  recordingBlob: any;
  time: number = 0;
  timestart: any;
  interval: any;
  canceled = false;
  user_id: any;
  microphoneOn= false;
     context:any;
  constructor(
    private dictateService: DictateService,
    private toester: ToastrService,
    private recordservice: RecordService,
    private authService: AuthService,
    private transcriptionService: TrascriptionServiceService,
  ) {}
  ngOnDestroy(): void {
    console.log("test");
  }
  getUser() {
    let user_id = "";
    if (this.authService.loggedIn()) {
      const jwtData = this.authService.getJwtData();
      user_id = jwtData.user_id;
    }
    return user_id;
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (this.selectedmodelchanged && this.dictateService.isInitialized()) {
      this.reset();
    } 
  }

  ngOnInit(): void {
    this.user_id = this.getUser();
    this.get_available_live_decoders();
    if (this.selectedmodelchanged && this.dictateService.isInitialized()) {
      this.reset();
    } 
    var display = moment().startOf("day").seconds(this.time).format("H:mm:ss");
    this.statuerecord = "boutonnotrecord";
    this.timestart = display;
  }
  selectChangeHandler(event: any) {
    if (event && this.dictateService.isInitialized()) {
      this.reset();
    }
    this.selectedmodelchanged = true;
    this.selectedValue = event.target.value;
  }
  getWaveContainer() {
    return this.wavesurfer.container;
  }
  get_available_live_decoders() {
    this.modelsavailable = [];
    this.recordservice
      .getmodelsforlivedecodingFromBff()
      .subscribe((data: any) => {
        this.getdata(data);
      });
  }
  getdata(data: any) {
    Object.keys(data).forEach((key) => {
      this.modelsavailable.push({ name: data[key].name, id: key });
    });
  }
  stop() {
    this.wavesurfer.microphone.stop();
    this.statuerecord = "boutonnotrecord";
    this.clearWaveContainer();
  }

  clearWaveContainer() {
    this.getWaveContainer().innerHTML = "";
  }

  start() {
    this.statuerecord = "boutonrecord";
    this. AudioContext = window.AudioContext;
    this.context = new AudioContext();
    var processor = this.context.createScriptProcessor(1024, 1, 1);
    this.wavesurfer = WaveSurfer.create({
      container: "#waveformtest",
      waveColor: "#242e3a",
      barHeight: 3,
      interact: false,
      cursorWidth: 0,
      audioContext: this.context || null,
      audioScriptProcessor: processor || null,
      plugins: [
        MicrophonePlugin.create({
          bufferSize: 4096,
          numberOfInputChannels: 1,
          numberOfOutputChannels: 1,
          constraints: {
            video: false,
            audio: true,
          },
        }),
      ],
    });

    this.wavesurfer.microphone.on("deviceReady", function () {
      this.microphoneOn = true
      console.info("Device ready!");
    });
    this.wavesurfer.microphone.on("deviceError", function (code) {
      console.warn("Device error: " + code);
    });
    this.wavesurfer.microphone.start();
  }

  startTimer() {
    this.interval = setInterval(() => {
      this.time++;
      console.log("timing", this.time);
      var display = moment()
        .startOf("day")
        .seconds(this.time)
        .format("H:mm:ss");
      this.timestart = display;
    }, 1000);
  }

  pauseTimer() {
    clearInterval(this.interval);
  }
  endTimer() {
    clearInterval(this.interval);
    this.time = 0;
    var display = moment().startOf("day").seconds(this.time).format("H:mm:ss");
    this.timestart = display;
  }
  switchSpeechRecognition() {
    this.selectedmodelchanged = false;
    if (this.selectedValue != "") {
      if (!this.dictateService.isInitialized()) {
        this.reset();
        this.startTimer();
        this.start();
        let serverSocket: any;
        if (this.selectedValue == "Kaldi_English_medium") {
          serverSocket =
            `${LIVE_KEY}/dashlivedecoding-en/ws_audio/` + this.user_id;
        } else if (this.selectedValue == "Kaldi_French_small") {
          serverSocket =
            `${LIVE_KEY}/dashlivedecoding-fr/ws_audio/` + this.user_id;
        } else if (this.selectedValue == "Kaldi_Arabic_medium") {
          serverSocket =
            `${LIVE_KEY}/dashlivedecoding-ar/ws_audio/` + this.user_id;
        }
        this.dictateService.init({
          server: serverSocket,
          onResults: (hyp) => {
            this.textDataBase = this.textDataBase + hyp + "\n";
            this.textData = this.textDataBase;
            this.results.nativeElement.scrollTop =
              this.results.nativeElement.scrollHeight;
            this.statuerecord = "boutonrecord";
          },
          onPartialResults: (hyp) => {
            this.textData = this.textDataBase + hyp;
          },
          onError: (code, data) => {
            console.log(code, data);
          },
          onEvent: (code, data) => {
            if (code == 5) {
              console.log("test for code 5", data);
            }
          },
        });

        this.microOn = "true";
      } else if (this.dictateService.isRunning()) {
        this.dictateService.resume();
        this.start();
        this.startTimer();
        this.microOn = "true";
      } else {
        this.dictateService.pause();
        this.stop();
        this.statuerecord = "boutonnotrecord";
        this.pauseTimer();
      }
    } else {
      this.toester.info("Please choose a model to start recording!");
    }
  }

  cancelRecordinng() {
    this.canceled = true;
    this.dictateService.pause();
    this.dictateService.cancel();
    this.dictateService.MSG_SEND_EOS;
    this.stop();
    this.statuerecord = "boutonnotrecord";
    this.microOn = "false";
    this.endTimer();
    this.selectedmodelchanged = false;
    this.time = 0;
  }
  reset() {
    if (this.dictateService.isInitialized()) {
      this.cancelRecordinng();
    }
   
  
    setTimeout(() => {

      this.transcriptionService.refreshListTranscription();


    }, 1000);
    this.textDataBase = "";
    this.textData = "";
  }
 
}
