import { Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import * as moment from 'moment';

@Component({
  selector: 'app-audio-player',
  templateUrl: './audio-player.component.html',
  styleUrls: ['./audio-player.component.css']
})
export class AudioPlayerComponent implements OnInit ,OnChanges{
  @ViewChild("testAudior", { static: false }) audioPlayer: ElementRef;
  testAudio: any=null;
  @Input()urlvideo
  audiook=false
  clickplay=false
  timer=0
  timerprogress=0
  audioStart:any = '00:00'
  mute = false
  constructor() { }
  ngOnChanges(changes: SimpleChanges): void {
    throw new Error('Method not implemented.');
  }

  ngOnInit(): void {
    console.log(this.urlvideo);
    this.playAudio()
  }
  playAudio() {
    let x: any = "";
   

    if (this.urlvideo) {
      x = this.urlvideo;
      setTimeout(() => {
        console.log("relleolelreolreroelrero");
      //  this.audiook == true
        this.testAudio = <HTMLAudioElement>(
          document.getElementById("testAudior")
        );
       
        this.testAudio.addEventListener("timeupdate", (currentTime) => {
          console.log("currenttime 111", this.testAudio.currentTime);
          this.testvideo(this.testAudio.currentTime)
          console.log("currenttime 1 22", currentTime.timeStamp / 1000);
        });

        console.log("eeseesssssssssssssssswwwwwwwwxxxx", x, this.testAudio);
      }, 1000);
    }
    if (this.audiook == true) {
      this.testAudio = <HTMLAudioElement>document.getElementById("testAudior");
      console.log("eeseesssssssssssssssswwwwwwwwxxxx", x, this.testAudio);
    }
    console.log(this.urlvideo);
    let elem = document.querySelector("testAudior");
    console.log("elem", elem);

    this.testAudio = <HTMLAudioElement>document.getElementById("testAudior");
    console.log("eesees", this.testAudio);
  }
  togglePlayPause() {
    
    this.clickplay=  !this.clickplay
    this.testAudio = <HTMLAudioElement>document.getElementById("testAudior");
    if(this.testAudio != null){
      console.log('this.testAudio',this.testAudio)
      if (this.testAudio.play() && !this.clickplay ) {
        
         this.testAudio?.pause();
       } else {
       if(this.clickplay){
        this.testAudio.play(this.testAudio?.getCurrentTime());
       }
       
       }
    }
    
  }
 
  readAudioFromProgress(event){
   
    let percentage: number = Math.floor(
      (event.layerX / (event.target.offsetWidth - 3)) * 100
    );
    this.timerprogress=percentage
var timeAudio= (this.testAudio.duration * percentage)/100
this.testAudio.currentTime = timeAudio
    
  }
  testvideo(time: any) {
    console.log('time in test video',time)
    this.timer = time;

        if(this.clickplay){
          console.log('time in test video1111',this.testAudio.duration)

            this.timerprogress = (100 * this.timer) / this.testAudio.duration;
            console.log('time in test video111111',  this.timerprogress)

            this.audioStart = 0;
            var display = moment()
                .startOf('day')
                .seconds(this.timer)
                .format('H:mm:ss');
            this.audioStart = display;
            if (time.progress >= 100) {
                this.clickplay = false;
                this.testAudio.pause()
            }
        }
        if (time.progress >= 100) {
            this.clickplay = false;
            this.testAudio.pause()
        }
       
    

   
}
forwardCurrentTimeBy10(){
  console.log('testing the current time',this.testAudio.currentTime)
  this.testAudio.currentTime =  this.testAudio.currentTime  + 10
}
redoCurrentTimeBy10(){
  console.log('testing the current time',this.testAudio.currentTime)
  this.testAudio.currentTime =  this.testAudio.currentTime  - 10
}

muteSound(){
  this.mute = !this.mute
  
    this.testAudio.muted = this.mute
  
}

}
