import {Component, OnInit} from '@angular/core';
import {AuthService} from '../../../shared/service/auth/auth.service';
import {TrascriptionServiceService} from '../../../shared/service/transcriptions/trascription-service.service';
import {NotificationService} from '../../../shared/service/notification/notification.service';


@Component({
    selector: 'app-user-dashboard-management',
    templateUrl: './user-dashboard-management.component.html',
    styleUrls: ['./user-dashboard-management.component.css']
})
export class UserDashboardManagementComponent implements OnInit {
    statusOpenCreateUser = 'text-clicked btn-nav-live-radius';
    statusOpenGenerateApiKey = 'text-not-clicked';
    showCreateUser = true;
    listUsers = [];
    listSkills = [];
    APIList: any;
    user: any = '';
    model: any = '';
    apiKey: any;
    displayModal = 'none';
    apikeyToBeDeleted = '';
    deleteIsLoading = false;

    constructor(private authUser: AuthService, private trService: TrascriptionServiceService,
                private notificationService: NotificationService) {
    }

    ngOnInit(): void {
        if (sessionStorage.getItem('tabSelected') === 'apikey') {
        this.showGenerateApiKeySection();
        }
        this.getData();
        this.authUser.reloadApiKeyDataTableBehave$().subscribe((data) => {
            if (data) {
              // tslint:disable-next-line:no-shadowed-variable
             this.getAllApiKeys();
                this.authUser.reloadApiKeyDataTableBehave(false);
            }
        });


    }

    showCreateUserSection() {
        this.showCreateUser = true;
        this.statusOpenCreateUser = 'text-clicked btn-nav-live-radius';
        this.statusOpenGenerateApiKey = 'text-not-clicked';
        sessionStorage.removeItem('tabSelected');
    }

    showGenerateApiKeySection() {
        this.showCreateUser = false;
        this.statusOpenGenerateApiKey = 'text-clicked btn-nav-offline-radius';
        this.statusOpenCreateUser = 'text-not-clicked';
    }

    getApikey(event: any) {
        this.user = event.user_name;
        this.model = event.model_name;
        this.apiKey = event;
    }

    getAllApiKeys() {
        this.authUser.getAllApiKeys().subscribe(data => {
            this.APIList = data;
            }
        );

    }
    getData() {

        setTimeout(() => this.getAllApiKeys(), 2000);
        this.authUser.getAllUsers().subscribe(data =>
            this.listUsers = data
        );
        this.trService.getModels().subscribe(data =>

            Object.keys(data).forEach((key) => {
                this.listSkills.push({
                    name: data[key].name,
                    id: data[key].id
                });
            })
        );
    }

    private extractModels(data) {
        data.forEach((ele: any) => {
            this.listSkills.push({name: ele.name, id: ele.id});
        });
    }


    deleteApiKey(apikey: any) {
     this.apikeyToBeDeleted = apikey;
     this.displayModal = 'block';
    }

    onCloseHandled() {
        this.displayModal = 'none';
        this.apikeyToBeDeleted = '';
    }

    confirmDeleteApiKey() {
        this.deleteIsLoading = true;

        this.authUser.deleteApiKey(this.apikeyToBeDeleted).subscribe(() => {

                this.notificationService.showSuccess('ApiKey deleted successfully !');
                setInterval(() => {   sessionStorage.setItem('tabSelected', 'apikey');
                        window.location.reload();
                    }
            , 2000);

            },
            error => {
                console.log(error);
                this.deleteIsLoading = false;
                this.notificationService.showError('Error while deleting, please retry');
                this.onCloseHandled();

            }
        );

    }
}
