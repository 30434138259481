import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-upload-loading-modal',
  templateUrl: './upload-loading-modal.component.html',
  styleUrls: ['./upload-loading-modal.component.css']
})
export class UploadLoadingModalComponent  {

 
  prog: string;
  @Input() progress :any;

  ngOnChanges() {
    console.log({ SpinnerChanges: this.progress });
  }
  
}
