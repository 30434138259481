import {Component, EventEmitter, HostListener, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {AuthService} from 'src/shared/service/auth/auth.service';
import {SideBarEventService} from 'src/shared/service/sideBarEvent/side-bar-event.service';


@Component({
    selector: 'app-small-side-bar',
    templateUrl: './small-side-bar.component.html',
    styleUrls: ['./small-side-bar.component.css']
})
export class SmallSideBarComponent implements OnInit, OnChanges {
    Productclicked = 'active';
    ProductclickedDiv = 'activeDiv';
    productClicked: boolean = true;
    contactUsClickedCss = 'side-bar-icon';
    contactUsclickedDiv = 'NotactiveDiv';
    contactUSClicked: boolean = false;
    textdashboardstyle = 'textGras';
    textContactstyle = 'side-bar-link mx-3 ';
    isSelected = 0;
    @Input() toggleSide = false;
    @Output() isProductClicked = new EventEmitter<any>();
    @Output() isCategorieClicked = new EventEmitter<any>();
    @ViewChild('sidenav', {static: true}) public el: any;
    menu = [
        {value: 'Dashboard', icon: 'Home.svg', icons: 'HomeBlanc.svg'},
        {value: 'Products', icon: 'ProductNormal.svg', icons: 'ProductBlanc.svg', route: '/dashboard/Products'},
        {value: 'History', icon: 'Historysvg.svg', icons: 'DiscoveryBlanc.svg', route: '/dashboard/history'},
        {value: 'Analytics', icon: 'Analyticsvg.svg', icons: 'GraphBlanc.svg'},
        {value: 'Notification', icon: 'Notification.svg', icons: 'Notification.svg'},
        {value: 'Payment', icon: 'Wallet.svg', icons: 'Wallet.svg'},
        {value: 'Contact us', icon: 'Contact.svg', icons: 'Contact.svg', route: '/dashboard/contact'},
        {value: 'Webhook', icon: 'Historysvg.svg', icons: 'Historysvg.svg', route: '/dashboard/webhook'}

    ];
    adminMenu = [
        {value: 'Admin View', icon: 'Settingnav.svg', icons: 'Settingnav.svg', route: '/admin'},
        {value: 'User Management', icon: 'Settingnav.svg', icons: 'Settingnav.svg', route: '/admin/user'}
    ];

    constructor(private router: Router, private authService: AuthService, private sideBarService: SideBarEventService
    ) {
    }

    @HostListener('swiperight', ['$event'])
    public swipePrev(event: any) {
        this.el.show();
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.sideBarService
            .stateSideBar$()
            .subscribe((sideBarstate) => {
                console.log(sideBarstate);
            });
    }

    ngOnInit(): void {
        const token = this.authService.getJwtData();
        if (token.user_type) {
            this.menu =  this.menu.concat(this.adminMenu);
        }
        this.sideBarService
            .stateSideBar$()
            .subscribe((sideBarstate) => {
                console.log(sideBarstate);
            });
        this.sideBarService
            .stateSideBarselected$()
            .subscribe((item) => {
                this.isSelected = item;
                console.log('test', item);

            });
    }


    changeDisplay() {
        if (this.toggleSide) {
            this.toggleSide = false;
            this.sideBarService.StateClosed();

        } else if (!this.toggleSide) {
            this.toggleSide = true;
            this.sideBarService.StateOpen();

        }
        //  this.changeSideBarDisplay.emit(this.toggleSide);
    }

    selectItem(i) {

        this.isSelected = i;
        this.sideBarService.returnSelected(i);
    }

    goToDashboard() {
        this.router.navigate(['dashboard']);
    }

    goToUpload() {
        this.router.navigate(['demo']);
    }

    signOut() {
        this.authService.logout();
    }
}
