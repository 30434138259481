import { Component, OnInit } from '@angular/core';
import { NotificationService } from 'src/shared/service/notification/notification.service';
import {AuthService} from '../../../shared/service/auth/auth.service';

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.css']
})
export class ProductsComponent implements OnInit {
  route: String;
  constructor(
    private notificationService: NotificationService,
    private authService: AuthService
  ) {
    this.route = '/dashboard/ProductsAlspeech';
  }
  ngOnInit(): void {
    this.notificationService.pageHeaderContent('Products');
  }
}
