import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {LoginCredentials} from '../../shared/models/User';
import {AuthService} from '../../shared/service/auth/auth.service';
import {Router} from '@angular/router';
import {SideBarEventService} from 'src/shared/service/sideBarEvent/side-bar-event.service';
import {HistoryService} from 'src/shared/service/history/history.service';
import {switchMap} from 'rxjs/operators';
import { of } from 'rxjs';


@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.css'],
})
export class LoginComponent implements OnInit {
    loginForm: FormGroup;
    errorMsg = '';
    loginError = false;

    constructor(
        private authService: AuthService,
        private formBuilder: FormBuilder,
        private router: Router,
        private sideBarItemService: SideBarEventService,
        private historyService: HistoryService
    ) {
    }

    get f() {
        // console.log({ controls: this.loginForm.controls });
        return this.loginForm.controls;
    }

    ngOnInit(): void {
        this.initForm();
    }

    initForm() {
        this.loginForm = this.formBuilder.group({
            email: ['', [Validators.required, Validators.email]],
            password: ['', Validators.required],
        });
    }

    onSubmitForm() {
        const formValue = this.loginForm.value;
        const newUser = new LoginCredentials(
            formValue['password'],
            formValue['email']
        );

        this.authService.loginUser(newUser)  .pipe(
            switchMap((response) => {
                const status = response.status;
                // @ts-ignore
                return of({body: response.body, status: status});
            }),
        ).subscribe(
            ({body, status}) => {
                this.loginError = false;
                console.log({loginData: body});
                localStorage.setItem(
                    'token',
                    JSON.parse(JSON.stringify(body)).token
                );

                localStorage.setItem(
                    'refresh_token',
                    JSON.parse(JSON.stringify(body)).refresh_token
                );
                if (status === 200) {
                    this.router.navigate(['dashboard']);
                 //   this.sideBarItemService.returnSelected(1);
                    this.historyService.setselectedItemPerPage(10);
                    this.sideBarItemService.returnSelected(null);
                }
                this.authService.getJwtData();
            },
            (error) => {
                this.loginError = true;
                console.log({loginError: error});
                if (error.status === 404) {
                    this.errorMsg = 'User not found with this email.';
                } else if (error.status === 400) {
                    this.errorMsg = 'Password is wrong.';
                } else {
                    this.errorMsg = 'Something went wrong, try again.';
                }
            }
        );
    }
}

