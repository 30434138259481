import { Injectable } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class NotificationService {
  pageHeader: string = "";
  constructor(private toastr: ToastrService) {}

  // BlobFileUrlaudio: BehaviorSubject<any> = new BehaviorSubject(this.urlaudio);
  PageHeaderBehavior: BehaviorSubject<any> = new BehaviorSubject(
    this.pageHeader
  );
  notificationBehave = new BehaviorSubject<{
    message: string;
    status: string;
    date: string;
    seen: any;
    icon: any;
  }>(null);
  pushNotificationMessage(
    notificationMessage: string,
    notificationStatus: string,
    notificationDate: string,
    notificationSeen: any,
    notificationIcon: any
  ): void {
    const payload = {
      message: notificationMessage,
      status: notificationStatus,
      date: notificationDate,
      seen: notificationSeen,
      icon: notificationIcon,
    };
    return this.notificationBehave.next(payload);
  }

  notificationBehavior$() {
    return this.notificationBehave.asObservable();
  }

  showSuccess(message) {
    this.toastr.success(message);
    this.pushNotificationMessage(
      message,
      "Success",
      new Date().toLocaleTimeString(),
      "isNotClicked",
      "success.png"
    );
  }

  showError(message) {
    this.toastr.error(message);
    this.pushNotificationMessage(
      message,
      "Error",
      new Date().toLocaleTimeString(),
      "isNotClicked",
      "error.png"
    );
  }

  showInfo(message) {
    this.toastr.info(message);
    this.pushNotificationMessage(
      message,
      "Info",
      new Date().toString(),
      "isNotClicked",
      "info.png"
    );
  }

  showWarning(message) {
    this.toastr.warning(message);
    this.pushNotificationMessage(
      message,
      "Warning",
      new Date().toLocaleTimeString(),
      "isNotClicked",
      "warning.png"
    );
  }
  showConsulting(message) {
    this.pushNotificationMessage(
      message,
      "Info",
      new Date().toString(),
      "isNotClicked",
      "info.png"
    );
  }
  pageHeader$(){
    return this.PageHeaderBehavior.asObservable();
  }
  pageHeaderContent(header){
    return this.PageHeaderBehavior.next(header)
  }
}
