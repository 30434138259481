import { Component, Input, SimpleChange } from "@angular/core";

@Component({
  selector: "app-spinner",
  templateUrl: "./spinner.component.html",
  styleUrls: ["./spinner.component.css"],
})
export class SpinnerComponent {
  @Input() transcriptionstart :any
  ngOnChanges(changes: SimpleChange) {
    console.log({ SpinnerChanges: changes });
  }
}
