import {HttpClient, HttpHeaders, HttpResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Mail} from 'src/shared/models/User';
import {environment} from '../../../environments/environment.prod';
import {Observable} from 'rxjs';

const BFF_URL = environment.BFF_KEY;

@Injectable({
    providedIn: 'root'
})
export class ContactService {

    httpOptions = {
        headers: new HttpHeaders({'Access-Control-Allow-Origin': '*'}),
    };

    constructor(private http: HttpClient) {
    }

    contactUs(mail: Mail): Observable<HttpResponse<any>> {
        return this.http.post<HttpResponse<any>>(`${BFF_URL}/email/contact`, mail,  this.httpOptions);
    }
}
