import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class TimeToDisplayService {
  constructor() {}

  gettime(time: any) {
    var splitted = time.split("/ ");
    return splitted[0];
  }
  getdate(time: any) {
    var splitted = time.split("/ ");
    return splitted[1];
  }
  getAMorPm(time: any) {
    var splitted = time.split(" ");
    return splitted[1];
  }
  getseconds(time: any) {
    var splitted = time.split(" ");
    return splitted[0];
  }
  setseconds(time: any, minute) {
    setInterval(() => {
      time++;

      if (time == 60) {
        clearInterval(time);
        time == "00";
        minute += 1;
      }
    }, 60000);
    if (Number(minute) < 10) {
      minute = "0" + minute;
      return time + ":" + minute;
    } else {
      return time + ":" + minute;
    }
  }
  getHours(time: any) {
    var splitted = time.split(":");
    return splitted[1];
  }
  getMinute(time: any) {
    var splitted = time.split(":");
    return splitted[0];
  }
}
