import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SideBarEventService {
  selecteditem:number=1

  EditTranscription: BehaviorSubject<boolean> = new BehaviorSubject(false);
  state: BehaviorSubject<boolean> = new BehaviorSubject(true);
  selected: BehaviorSubject<number> = new BehaviorSubject(parseInt(this.getselectedItem()) );
  constructor() { }
  StateClosed() {
    this.state.next(false);
  }

  StateOpen() {
    this.state.next(true);
  }
  stateSideBarselected$() {
    return this.selected.asObservable();
  }

  EditTranscriptionOpened(){
    this.EditTranscription.next(true)
  }
  EditTranscriptionClosed(){
    this.EditTranscription.next(false)
  }

  EditTranscription$() {
    return this.EditTranscription.asObservable();
  }

 returnSelected(i){
   this.selected.next(i)
  
   this.setselectedItem(i)
 }
  stateSideBar$() {
    return this.state.asObservable();
  }
  getselectedItem() {
   
    return localStorage.getItem('selecteditem');
  }

  setselectedItem(selecteditem) {

    return localStorage.setItem('selecteditem', selecteditem);
  }
}
