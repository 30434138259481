import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-text-json',
  templateUrl: './text-json.component.html',
  styleUrls: ['./text-json.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class TextJsonComponent implements OnInit   {
  statuetextopen = "textclicked"
  statuejsonopen = "textnotclicked"
  showJsonFormat = true;
texttoshow = "";
@Input()  textData;
  constructor() { }
  ngOnInit(): void {
    this.texttoshow=this.textData
  }
 
  ngOnChanges(){
    this.texttoshow = this.textData
  }
  
  


  showJson() {
    this.showJsonFormat = true;
    this.statuetextopen = "textnotclicked"
    this.statuejsonopen = "textclicked"
    this.texttoshow = this.textData
  }
  showForm() {
    this.showJsonFormat = false;
    this.statuetextopen = "textclicked"
    this.statuejsonopen = "textnotclicked"
    this.texttoshow = this.textData
  }

}
