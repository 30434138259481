import {Component, OnDestroy, OnInit} from '@angular/core';
import {TrascriptionServiceService} from 'src/shared/service/transcriptions/trascription-service.service';
import {saveAs} from 'file-saver';
import {FileUploadService} from 'src/shared/service/file-upload.service';
import {AuthService} from 'src/shared/service/auth/auth.service';
import {ToastrService} from 'ngx-toastr';
import {NotificationService} from '../../../shared/service/notification/notification.service';
import {Router} from '@angular/router';
import {ModalService} from '../../../shared/service/modal.service';
import {DatePipe} from '@angular/common';

@Component({
    selector: 'app-gerer-files',
    templateUrl: './gerer-files.component.html',
    styleUrls: ['./gerer-files.component.css'],
})
export class GererFilesComponent implements OnInit, OnDestroy {
    selectedValueCheked = [];
    selectedValuechekedDelete = [];
    transcriptionNumber: number;
    disable = true;
    disableDelete = true;
    user_id: any;
    status;
    errorMessage;

    constructor(private transcriptionService: TrascriptionServiceService,
                private uploadFile: FileUploadService,
                public authService: AuthService,
                private toester: ToastrService,
                private notificationService: NotificationService,
                private route: Router,
                private modalService: ModalService,
                private datePipe: DatePipe
    ) {
    }

    ngOnDestroy(): void {
        this.selectedValueCheked = [];

        this.uploadFile.returnIdFileSelected(this.selectedValueCheked);
        this.selectedValuechekedDelete = [];

        this.transcriptionService.returnIdFileSelectedDelete(this.selectedValuechekedDelete);
    }

    ngOnInit(): void {

        this.user_id = this.getUser();

        this.transcriptionService.TranscriptionNumber$().subscribe((number) => {
            this.transcriptionNumber = number;
        });
        this.uploadFile
            .fileSelected$()
            .subscribe((item) => {


                this.selectedValueCheked = item;

                if (this.selectedValueCheked.length > 0) {
                    this.disable = false;
                    console.log(this.disable);
                } else {
                    this.disable = true;
                    console.log(this.disable);
                }
            });
        this.transcriptionService
            .fileSelectedDelete$()
            .subscribe((item) => {


                this.selectedValuechekedDelete = item;

                if (this.selectedValuechekedDelete.length > 0) {
                    this.disableDelete = false;
                    console.log(this.disable);
                } else {
                    this.disableDelete = true;
                    console.log(this.disable);
                }
            });

    }

    getUser() {
        let user_id = 'test';
        if (this.authService.loggedIn()) {
            const jwtData = this.authService.getJwtData();
            user_id = jwtData.user_id;
        }
        return user_id;
    }

    delete_Transcription(transcription_id: any) {
        this.transcriptionService.DeleteTranscription(transcription_id).subscribe({
            next: (data) => {
                this.status = 'Delete successful';
                this.toester.info(this.status);
                this.transcriptionService.refreshListTranscription();
            },
            error: (error) => {
                this.errorMessage = error.message;
                this.toester.error('There was an error!', this.errorMessage);
            },
        });
    }

    deleteManyTranscriptions() {
        console.log('rrrrrrrrr', this.selectedValuechekedDelete);

        if (this.selectedValuechekedDelete.length === 1) {
            this.delete_Transcription(this.selectedValuechekedDelete[0]);
            this.transcriptionService.refreshListTranscription();

        } else {
            console.log('tesgrdd', this.selectedValuechekedDelete);
            this.transcriptionService.deleteLogicalMultiple(this.selectedValuechekedDelete).subscribe(data => {
                    console.log('this is data deleted', data);
                    this.selectedValuechekedDelete = [];
                    this.transcriptionService.returnIdFileSelectedDelete(this.selectedValuechekedDelete);
                    this.transcriptionService.refreshListTranscription();

                }
            );

        }
    }

    download(file_id: any, file_name: any) {
        this.transcriptionService
            .downloadFile(file_id, this.user_id)
            .subscribe((response) => {

                const dataType = response.type;

                const binaryData = [];
                binaryData.push(response);
                const downloadLink = document.createElement('a');
                downloadLink.href = window.URL.createObjectURL(
                    new Blob(binaryData, {type: dataType})
                );
                if (file_name) {
                    downloadLink.setAttribute(
                        'download',
                        file_name + '.mp3'
                    );
                }
                document.body.appendChild(downloadLink);
                downloadLink.click();
                this.selectedValueCheked = [];
                this.uploadFile.returnIdFileSelected(this.selectedValueCheked);
            });
    }

    downloadMAnyFiles() {
        const a = {
            files_list: this.selectedValueCheked,
        };
        console.log('this is files to downlod', a);

        if (this.selectedValueCheked.length === 1) {
            this.download(this.selectedValueCheked[0], 'Files');
        } else {
            this.transcriptionService
                .downloadmanyFile(a, this.user_id)
                .subscribe((response) => {

                    if (response.status === 200) {
                        const binaryData = [];
                        binaryData.push(response.body);

                        const blob = new Blob(binaryData);

                        saveAs(blob, 'Files.zip');
                        this.selectedValueCheked = [];
                        this.uploadFile.returnIdFileSelected(this.selectedValueCheked);
                    }
                });
        }

    }

    editFile() {
        console.table(this.selectedValuechekedDelete);
        if (this.selectedValuechekedDelete.length > 1) {
            this.notificationService.showError('Please select one item for edit!');
            return;
        }
      if (this.selectedValuechekedDelete.length === 1) {
          this.transcriptionService.getTranscriptionWithDiarization(this.selectedValuechekedDelete, false).subscribe(data => {
                console.table(data);

                if (data.status !== 'Transcribed') {
                    this.notificationService.showError('Transcription not transcribed yet!');
                    return;
                }

              this.transcriptionService.setselectedFileNameToUpdate(data.file_name);

              this.modalService.ModalStatePened();
              this.transcriptionService.setselectedIdToUpdate(data.file_id);
              this.transcriptionService.setselectedIdTranscriptionToUpdate(
                  data.id
              );
              this.modalService.ModalStatePened();
              this.modalService.fileInoformation([
                  data.file_id,
                  data.id,
                  data.file_name,
                  data.speakers,
                  data.file_type,
                  data.process_time,
                  this.datePipe.transform(data.created_at, 'yyyy-MM-dd'),
                  this.datePipe.transform(data.updated_at, 'yyyy-MM-dd'),
                  data.skill_name
              ]);
              this.route.navigate(['dashboard/EditTranscription']);

          });
        }


    }
}
