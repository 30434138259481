    export interface SearchProductRequest {
    offset: number;
    limit: number;
    sorting_column: string;
    sorting_direction: SortingDirection;
    status: string;
    file_type:string;
    date_range: [string, string];
    skill_id: string;
    file_name: string;
    file_size: string;
    processing_date: string;
}

export enum SortingDirection {
    desc = 'desc',
    asc = 'asc'
}
