// managehttp.interceptor.ts
import { Injectable } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
    HttpErrorResponse,
} from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { AuthService } from '../../shared/service/auth/auth.service';
import { catchError, filter, switchMap, take } from 'rxjs/operators';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {

    constructor(private authService: AuthService) {}
    data: any;
    private isRefreshing = false;
    private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

    private static addTokenHeader(request: HttpRequest<any>, token: string) {
        return request.clone({ setHeaders: { Authorization: `Bearer ${token}` } });
    }

    intercept(
        req: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<Object>> {
        let authReq = req;
        const token = this.authService.getJwtToken();
        if (token) {
            console.log('token expired', this.authService.isTokenExpired(token));
            authReq = JwtInterceptor.addTokenHeader(req, token);
        }
        // @ts-ignore
        return next.handle(authReq).pipe(catchError(error => {
            if (error instanceof HttpErrorResponse && !authReq.url.includes('login')) {
                if (error.status === 408) {
                    return this.handle401Error(authReq, next);
                }
                if (error.status === 401) {
                    this.authService.logout();
                }
            }
            return throwError(error);
        }));
    }

    private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
        if (!this.isRefreshing) {
            this.isRefreshing = true;
            this.refreshTokenSubject.next(null);
            const refreshToken = this.authService.getRefreshToken();
            if (refreshToken) {
                return this.authService.refreshToken(refreshToken).pipe(
                    switchMap((data: any) => {
                        this.isRefreshing = false;
                        const newToken = JSON.parse(JSON.stringify(data.body));
                        this.authService.setJwtToken(newToken.token);
                        this.refreshTokenSubject.next(newToken.token);
                        return next.handle(JwtInterceptor.addTokenHeader(request, newToken.token));
                    }),
                    catchError((err) => {
                        this.isRefreshing = false;
                        console.log(err);
                        this.authService.logout();
                        return throwError(err);
                    })
                );
            }
        }
        return this.refreshTokenSubject.pipe(
            filter(token => token !== null),
            take(1),
            switchMap((token) => next.handle(JwtInterceptor.addTokenHeader(request, token)))
        );
    }
    }
