import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment.prod';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';

const GATEWAY_URL = environment.GATEWAY_URL_DEMO;

@Injectable({
    providedIn: 'root'
})
export class UserInfoService {
    httpOptions = {
        headers: new HttpHeaders({'Access-Control-Allow-Origin': '*'}),
    };

    constructor(private http: HttpClient) {
    }

    public static getBrowser() {
        return (this.contains('Opera') || this.contains('OPR')) ? 'Opera' : this.contains('Chrome') ? 'Chrome' : this.contains('Safari') ?
            'Safari' : this.contains('Firefox') ? 'Firefox' : this.contains('MSIE') ? 'IE' : 'unknown';
    }

    private static contains(browser: string) {
        return navigator.userAgent.indexOf(browser) !== -1;
    }

    public getBrowserVersion() {
        const userAgent = navigator.userAgent;
        let temp, matchTest = userAgent.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];

        if (/trident/i.test(matchTest[1])) {
            temp = /\brv[ :]+(\d+)/g.exec(userAgent) || [];
            return 'IE ' + (temp[1] || '');
        }

        if (matchTest[1] === 'Chrome') {
            temp = userAgent.match(/\b(OPR|Edge)\/(\d+)/);
            if (temp !== null) {
                return temp.slice(1).join(' ').replace('OPR', 'Opera');
            }
        }
        matchTest = matchTest[2] ? [matchTest[1], matchTest[2]] : [navigator.appName, navigator.appVersion, '-?'];
        if ((temp = userAgent.match(/version\/(\d+)/i)) !== null) {
            matchTest.splice(1, 1, temp[1]);
        }

        return matchTest.join(' ');
    }

    getAllUsersInfos(): Observable<any> {
        // @ts-ignore
        return this.http.get(`${GATEWAY_URL}/users_infos`);
    }

    getUserInfo() {
       const httpOptions = {
            headers: new HttpHeaders({'Access-Control-Allow-Origin': '*'}),
        };
        return this.http.get('https://api.ipbase.com/v1/json?apikey=d2b30010-6195-11ec-bbad-35414fcb4c48', httpOptions);
    }

    sendUserInfo(info): Observable<any> {
        return this.http.post(`${GATEWAY_URL}/user_info`, info, this.httpOptions);

    }

    getUserInfoAndTranscriptions(size, pageNumber, is_live: boolean, filter):  Observable<any>  {
        return this.http.post(`${GATEWAY_URL}/users_info_transcriptions?limit=${size}&page_number=${pageNumber}&is_live=${is_live}`,
            filter);
    }

    setCookie(name, value, days) {
        let expires = '';
        if (days) {
            const date = new Date();
            date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
            expires = '; expires=' + date.toUTCString();
        }
        document.cookie = name + '=' + (value || '')  + expires + '; path=/';
    }
    getCookie(name) {
        const nameEQ = name + '=';
        const ca = document.cookie.split(';');
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) === ' ') { c = c.substring(1, c.length); }
            if (c.indexOf(nameEQ) === 0) { return c.substring(nameEQ.length, c.length); }
        }
        return null;
    }
}
