import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-loadinglogospinner',
  templateUrl: './loadinglogospinner.component.html',
  styleUrls: ['./loadinglogospinner.component.scss']
})
export class LoadinglogospinnerComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
