import { Injectable } from '@angular/core';
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: 'root'
})

export class AudioFilesService {
  state: BehaviorSubject<boolean> = new BehaviorSubject(false);



  readFileState() {
    this.state.next(true);
  }

  readFileStateCompleted() {
    this.state.next(false);
  }

 
  isReading$() {
    return this.state.asObservable();
  }
 
}
