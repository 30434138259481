import {AfterViewInit, Component, EventEmitter, Inject, Input, Output, OnInit} from '@angular/core';
import * as Chartjs from 'chart.js';
import {DOCUMENT} from '@angular/common';
import {animate, state, style, transition, trigger} from '@angular/animations';


interface dateRange {
    name: string;
    code: string;
}

@Component({
  selector: 'app-multi-chart',
  templateUrl: './multi-chart.component.html',
  styleUrls: ['./multi-chart.component.css']
})
export class MultiChartComponent implements OnInit, AfterViewInit {

  public myChartData;
  public canvas: any;
  public ctx;
  public chartNumber = 0;
  public range : number = 12;
  @Input() chart: any;
  dateRanges: dateRange[];
  @Output() rangeChoice: EventEmitter<number> = new EventEmitter<number>();
  public datasets: any[] = [];
  constructor() {
    this.dateRanges = [
                {name: 'dateRange', code: 'dateRange'},
                {name: '6 months', code: '6'},
                {name: '12 months', code: '12'}
            ];
  }

  ngOnInit(): void {}

  ngAfterViewInit() {
    if (this.chart.data.datasets.length) {
      this.canvas = document.getElementById(this.chart.id);
      this.ctx = this.canvas.getContext('2d');
      if (!this.chart.overlapCharts) {
        const gradientStroke = this.ctx.createLinearGradient(0, 365, 0, 50);
        gradientStroke.addColorStop(1, '#34A9DA');
        gradientStroke.addColorStop(0, '#8dcfaa');
        this.chart.data.datasets.forEach(dataset => {
          dataset.backgroundColor = gradientStroke;
          this.datasets.push(dataset);
        });
        this.chart.data.datasets.splice(1);
      }

      this.myChartData = new Chartjs.Chart(this.ctx, this.chart);
    }
  }

  public updateOptions(i: number) {
    this.chartNumber = i;
    this.myChartData.data.datasets[0] = this.datasets[i];
    this.myChartData.update();
  }

  public changeDateRange(range: number){
    this.rangeChoice.emit(range);
  }

  onChange(deviceValue) {
          this.range = deviceValue.target.value === 'dateRange' ? 12 : deviceValue.target.value;
          this.changeDateRange(this.range);
      }

}
