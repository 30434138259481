import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-gestion-commun',
  templateUrl: './gestion-commun.component.html',
  styleUrls: ['./gestion-commun.component.css']
})
export class GestionCommunComponent implements OnInit {
  disable=true
  constructor() { }

  ngOnInit(): void {
  }

}
