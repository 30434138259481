import {AfterViewInit, Component, Input, OnInit, ViewChild} from '@angular/core';
import {UserInfoService} from '../../../shared/service/user-info/user-info.service';
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import {DemoTranscriptionsService} from '../../../shared/service/demo-transcriptions/demo-transcriptions.service';
import {MatDialog} from '@angular/material/dialog';
import {ModalService} from '../../../shared/service/modal.service';
import {environment} from '../../../environments/environment.prod';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {DateAdapter} from '@angular/material/core';
import {DemoPopupModalComponent} from '../../dashboard/demo-popup-modal/demo-popup-modal.component';
import {FileUploadService} from '../../../shared/service/file-upload.service';

const ENGLISH_DEMO_USER = environment.ENGLISH_DEMO_USER || '833d5278-dc23-4a3b-85c8-85873916402c';
const ARABIC_DEMO_USER = environment.ARABIC_DEMO_USER || '73446d44-9e40-42b1-96ff-c981f129be5f';
const FRENCH_DEMO_USER = environment.FRENCH_DEMO_USER || '2157bab5-5807-49b8-82a1-a42ca0e552b2';

const GATEWAY_URL = environment.GATEWAY_URL_DEMO;

@Component({
    selector: 'app-user-info',
    templateUrl: './user-info.component.html',
    styleUrls: ['./user-info.component.css'],
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({height: '0px', minHeight: '0'})),
            state('expanded', style({height: '*'})),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ]),
    ],
})
export class UserInfoComponent implements OnInit, AfterViewInit {
    models = [{ id: 'All_Models', name: 'All Models'}];
    isLoading = true;
    test: any;
    displayedColumns;
    displayedColumnsUsers;
    displayedColumnsTranscriptions;
    totalUsers;
    name_file_selected = '';
    id_file_selected = '';
    transcription_id = '';
    speakerChecked = false;
    fileType = 'audio';
    isTableExpanded = false;
    totalRows = 0;
    pageSize = 10;
    currentPage = 0;
    @Input() isLive: boolean;
    pageSizeOptions: number[] = [10, 25, 100];
    dataSources: MatTableDataSource<any> = new MatTableDataSource();
    @ViewChild(MatPaginator)
    paginator!: MatPaginator;
    private date_from: string;
    private date_to: string;
    private skill_id: any;
    private uriTranscription: string;
    private modelId: any;
    private modelUser: any;

    constructor(private userInfoService: UserInfoService,
                private demoTranscriptionService: DemoTranscriptionsService,
                private fileUploadService: FileUploadService,
                public dialog: MatDialog,
                private modalService: ModalService,
                private adapter: DateAdapter<any>) {
    }

    ngAfterViewInit() {
        this.dataSources.paginator = this.paginator;
    }

    ngOnInit(): void {
        this.adapter.setLocale('fr');
        this.getModels();
        //  this.getAllDemoUsers();
        this.loadData();
        this.isLoading = false;
    }

    loadData() {
        const filter = {
            date_from: this.date_from,
            date_to: this.date_to,
            skill_id: this.skill_id
        };
        this.userInfoService.getUserInfoAndTranscriptions(this.pageSize, this.currentPage, this.isLive, filter).subscribe(data => {
            this.totalUsers = data.data.length;
            this.displayedColumnsUsers = data.user_headers;
            this.displayedColumnsTranscriptions = data.transcriptions_headers;
            this.displayedColumns = this.displayedColumnsUsers.map(e => e.key);
            this.dataSources.data = data.data;
            setTimeout(() => {
                this.paginator.pageIndex = this.currentPage;
                this.paginator.length = data.count;
            });
        });
    }

    getModels() {
        if (this.isLive) {
            this.demoTranscriptionService.getModelsForLiveDecoding().subscribe(data => {
                this.extractModels(data);
            });
        } else {
            this.fileUploadService.getmodels().subscribe(data => {
                console.clear();
                console.table(data);
                this.extractOfflineModels(data);
            });

        }

    }

    pageChanged(event: PageEvent) {
        console.log({event});
        this.pageSize = event.pageSize;
        this.currentPage = event.pageIndex;
        this.loadData();

    }

    // Toggel Rows
    toggleTableRows() {
        this.isTableExpanded = !this.isTableExpanded;

        this.dataSources.data.forEach((row: any) => {
            row.isExpanded = this.isTableExpanded;
        });
    }

    onSelect(e) {
        console.table(e);
        this.name_file_selected = e.file_name;
        this.modalService.ModalStatePened();
        this.id_file_selected = e.file_id;
        this.transcription_id = e.id;
        this.uriTranscription = `${GATEWAY_URL}/transcription_by_id/${e.id}`;
        this.modelId = e.skill_id;
        this.modelUser = this.chekModelUser('english') ? ENGLISH_DEMO_USER : this.chekModelUser('french') ?
            FRENCH_DEMO_USER : ARABIC_DEMO_USER;
        this.dialog.open(DemoPopupModalComponent, {
            panelClass: 'custom-dialog-container-demo',
        });
        this.modalService.fileInoformation([
            this.id_file_selected,
            this.transcription_id,
            this.name_file_selected,
            this.speakerChecked,
            this.fileType,
            this.uriTranscription
        ]);
        if (!this.isLive) {
            this.modelUser = 0;
        }
        this.modalService.urlAudioObservable(
            `${GATEWAY_URL}/streaming_media_file?file_id=${this.id_file_selected}&user_id=${this.modelUser}`
        );
    }

    dateRangeChange(dateRangeStart: HTMLInputElement, dateRangeEnd: HTMLInputElement) {
        if (dateRangeEnd.value && dateRangeEnd.value !== '') {
            this.date_from = dateRangeStart.value;
            this.date_to = dateRangeEnd.value;
            this.currentPage = 0;
            this.loadData();
        }
    }

    private chekModelUser(str) {
        return this.modelId.toLocaleLowerCase().includes(str);
    }

    private extractModels(data) {
        data.forEach((ele: any) => {
            this.models.push({name: ele.name, id: ele.id});
        });
    }
    private extractOfflineModels(data) {
        data.forEach(ele => {
            this.models.push({name: ele?.name, id: ele.id});
        });
    }

    onChangeModel(event) {
        this.skill_id = event.target.value === 'All_Models' ? undefined : event.target.value;
        this.currentPage = 0;
        this.loadData();
    }
}
