import { Component, OnInit } from "@angular/core";
import { SideBarEventService } from "src/shared/service/sideBarEvent/side-bar-event.service";

@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.css"],
})
export class DashboardComponent implements OnInit  {
 
  constructor(
    private sideBarService:SideBarEventService
  ){
    
  }
  ngOnInit(): void {
    this.sideBarService
    .stateSideBar$()
    .subscribe((isRefreshNeeded) => {
      console.log(isRefreshNeeded)
    });
  }

  show = false;
  toggled = false;
 
  onToggle() {
    this.toggled = !this.toggled;
  }
 
  toggle() {
    this.show = !this.show;
  }
 
  
}
