import {Component, OnChanges, OnInit, ViewChild } from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import {NgWaveformComponent} from 'ng-waveform';
import {ToastrService} from 'ngx-toastr';
import {FileUploadService} from 'src/shared/service/file-upload.service';
import {fileDetailsModeltext} from '../../../../shared/models/fileDetailsModeltext';
import {RecordService} from 'src/shared/service/record.service';

interface Model {
    id: any;
}

declare var require: any;

@Component({
    selector: 'app-alspeech-demo-upload',
    templateUrl: './alspeech-demo-upload.component.html',
    styleUrls: ['./alspeech-demo-upload.component.scss'],
})
export class AlspeechDemoUploadComponent implements OnInit, OnChanges {
    @ViewChild('waveform') waveform: NgWaveformComponent;
    clickplay = false;
    uploading = false;
    models: Model[];

    statusClasssimple1 = 'not-active';
    statusClasssimple2 = 'not-active';
    statusClasssimple3 = 'not-active';
    statueMicrophone = 'not-active';
    uploadclas = 'not-active-upload';
    statuetextopen = 'textclicked';
    statuejsonopen = 'jsonclicked';
    tanscriptstyle = 'divstart';
    filetestwav: any = 'srcsharedaudio\test.wav';
    start = true;
    playsimple1clicked = true;
    microphoneclicked = false;
    playsimple3clicked = false;
    playsimple2clicked = false;
    uploadaudiofileclicked = false;
    Textclicked = true;
    jsonclicket = false;
    statusuploadfile = 'not-active';
    timer: number;
    timerprogress: any;
    urlvideo: any;
    file: any;
    scrollableContainer: HTMLElement;
    responsetranscription: any = '';
    showJsonFormat;
    wordstohighlight: any;
    textresponse: any = require('../../../../shared/models/jsonfortestwav.json');
    currentStyles: {};
    colorit = false;
    onpauseaudio = false;
    playaudiotest: boolean;
    ishttpLoaded = false;
    selectedValue: any = 'Kaldi_English_medium';
    texttotest: any;
    errorMessage = ';';
    speech = '';
    checkedmodeltotext = true;
    testingjson: any;
    progress: any;
    fileDetailsModeltext = new fileDetailsModeltext();
    blobUrlofaudio: any;
    element: HTMLElement;
    uploadingcompleted = false;
    transcriptionstart = false;
    progresstranscription: any;
    transcriptioncompleted = false;
    modelsavailable: any = [];
    selectedmodelchanged = false;
    @ViewChild('scrollPanel', {static: false}) scrollPanel: any;
    spanwordclicked: any;

    constructor(
        private fileUploadService: FileUploadService,
        private recordservice: RecordService,
        private sanitizer: DomSanitizer,
        private toastr: ToastrService
    ) {
    }

    ngOnInit(): void {
        this.transcriptionstart = false;

        console.log('this test selected value 1', this.selectedValue);
        console.log('this is uploadinf', this.uploading);


        if (this.playsimple1clicked) {
            this.selectedValue = 'Kaldi_English_medium';
            this.getstatusClasssimple1event(this.statusClasssimple1);
            console.log('uploadcomponent', this.statusClasssimple1);
        }
        if (this.playsimple2clicked) {
            this.selectedValue = 'Kaldi_Arabic_medium';
            this.getstatusClasssimple1event(this.statusClasssimple2);
            console.log('uploadcomponent', this.statusClasssimple2);
        }
        if (this.playsimple3clicked) {
            this.selectedValue = 'Kaldi_French_small';
            this.getstatusClasssimple3event(this.statusClasssimple3);
            console.log('uploadcomponent', this.statusClasssimple3);
        } else {
            this.get_available_models();
            this.selectedValue = 'Kaldi_English_medium';
        }


    }

    ngOnChanges() {
        console.log('Changes');
        console.log('this test selected value', this.selectedValue);

        this.scrollToHighlight();
        this.getstatusClasssimple1event(this.statusClasssimple1);
        this.getstatusClasssimple2event(this.statusClasssimple2);
        this.getstatusClasssimple3event(this.statusClasssimple3);
    }

    getstatusClasssimple1event(event) {
        this.statusClasssimple1 = event;
        console.log(this.statusClasssimple1);
    }

    getstatusClasssimple2event(event) {
        this.statusClasssimple2 = event;
        console.log(this.statusClasssimple2);
    }

    getstatusClasssimple3event(event) {
        this.statusClasssimple3 = event;
        console.log(this.statusClasssimple3);
    }

    getColor(statue: boolean): object | undefined {
        if (!statue) {
            return {'background-color': 'white'};
        } else if (statue) {
            return {
                'background-color': '#39A9DA',
                color: 'white',
                'border-radius': '25px',
                'padding-left': '3px',
            };
        }
    }

    getprogress(event: any) {
        console.log('this is progress event', event);
        this.progress = event;
    }

    playsimple1() {
        // this.urlvideo  =
        this.get_available_models();
        this.selectedValue = 'Kaldi_English_medium';
        this.microphoneclicked = false;
        this.playsimple1clicked = true;
        this.playsimple2clicked = false;
        this.uploadaudiofileclicked = false;
        this.playsimple3clicked = false;
        this.transcriptionstart = false;
        this.statusClasssimple1 = 'active';
        this.statusClasssimple2 = 'not-active';
        this.statusuploadfile = 'not-active';
        this.statusClasssimple3 = 'not-active';
        this.statueMicrophone = 'not-active';


    }

    recordAudio() {
        this.microphoneclicked = true;
        this.playsimple1clicked = false;
        this.playsimple2clicked = false;
        this.uploadaudiofileclicked = false;
        this.playsimple3clicked = false;
        this.statueMicrophone = 'microphone-active';
        this.statusClasssimple1 = 'not-active';
        this.statusClasssimple2 = 'not-active';
        this.statusuploadfile = 'not-active';
        this.statusClasssimple3 = 'not-active';
        this.get_available_live_decoders();
    }

    playsimple2() {
        // this.urlvideo  =

        this.microphoneclicked = false;
        this.transcriptionstart = false;

        this.playsimple1clicked = false;
        this.playsimple2clicked = true;
        this.uploadaudiofileclicked = false;
        this.playsimple3clicked = false;
        this.statusClasssimple1 = 'not-active';
        this.statusClasssimple2 = 'active';
        this.statusClasssimple3 = 'not-active';
        this.statusuploadfile = 'not-active';
        this.statueMicrophone = 'not-active';
        this.get_available_models();
        this.selectedValue = 'Kaldi_Arabic_medium';
    }

    playsimple3() {
        // this.urlvideo  =
        this.microphoneclicked = false;
        this.transcriptionstart = false;

        this.playsimple1clicked = false;
        this.playsimple2clicked = false;
        this.playsimple3clicked = true;
        this.uploadaudiofileclicked = false;
        this.statusClasssimple1 = 'not-active';
        this.statusClasssimple2 = 'not-active';
        this.statusClasssimple3 = 'active';
        this.statusuploadfile = 'not-active';
        this.statueMicrophone = 'not-active';
        this.get_available_models();
        this.selectedValue = 'Kaldi_French_small';
    }

    playupload() {
        this.microphoneclicked = false;
        this.transcriptionstart = false;

        this.playsimple1clicked = false;
        this.playsimple2clicked = false;
        this.playsimple3clicked = false;
        this.uploadaudiofileclicked = true;
        this.statusClasssimple1 = 'not-active';
        this.statusClasssimple2 = 'not-active';
        this.statusuploadfile = 'active';
        this.statusClasssimple3 = 'not-active';
        this.statueMicrophone = 'not-active';
        this.get_available_models();
    }

    gettranscriptionstate(event) {
        this.transcriptioncompleted = event;
    }

    get_available_models() {
        this.modelsavailable = [];
        this.fileUploadService.getmodels().subscribe((data: any) => {
            data.forEach(e => {
                this.modelsavailable.push({name: e.name, id: e.id});

            });
        });
    }

    get_available_live_decoders() {
        this.modelsavailable = [];
        this.recordservice.getmodelsforlivedecoding().subscribe((data: any) => {
            this.getData(data);
        });
    }

    getData(data: any) {
        data.forEach((ele: any) => {
            this.modelsavailable.push({name: ele.name, id: ele.id});
        });
    }

    gettranscriptionstart(event) {
        this.transcriptionstart = event;
        if (!this.transcriptionstart) {
            this.tanscriptstyle = 'divstart';
        } else {
            this.tanscriptstyle = 'divnostart';
        }
    }

    getprogresseventtranscription(event) {
        this.progresstranscription = event;
    }

    selectChangeHandler(event: any) {
        this.selectedmodelchanged = true;
        this.selectedValue = event.target.value;
        console.log(event);
        console.log('this is selected value changed', this.selectedValue);
    }

    convertDataURIToBinary(dataURI: any) {
        const BASE64_MARKER = ';base64,';
        const base64Index = dataURI.indexOf(BASE64_MARKER) + BASE64_MARKER.length;
        const base64 = dataURI.substring(base64Index);
        const raw = window.atob(base64);
        const rawLength = raw.length;
        const array = new Uint8Array(new ArrayBuffer(rawLength));

        for (let i = 0; i < rawLength; i++) {
            array[i] = raw.charCodeAt(i);
        }
        return array;
    }

    playAudio(data: any) {
        const binary = this.convertDataURIToBinary(data);
        const blob = new Blob([binary]);
        this.blobUrlofaudio = this.sanitizer.bypassSecurityTrustUrl(
            URL.createObjectURL(blob)
        );
    }

    getaudiotimeron(tag: any) {
        this.urlvideo = '../../';
        console.log(tag);
        this.spanwordclicked = tag;

        this.timer = this.spanwordclicked.start;
        console.log('donee', this.waveform.setRegionStart(this.timer));
        console.log('this is region for waveform', this.waveform);
    }

    isLastWord(tag: any) {
        return tag.start <= this.timer && tag.end >= this.timer;
    }

    getuploadstate($event) {
        this.uploading = $event;
        console.log('uploading in demo', this.uploading);
    }

    getuploadstatedone($event) {
        console.log('this is getupload state done', $event);
        this.uploadingcompleted = $event;
        console.log('uploading in demo completed', this.uploadingcompleted);
    }

    getstartstate($event) {
        this.start = $event;
        console.log('start', this.start);
    }

    sendFile(file: any) {
        console.log('this is handle file', file);
        this.file = file;
        this.urlvideo = file.urlvideo;
        console.log('file to test', this.file);
    }

    showJson() {
        this.showJsonFormat = true;
        this.jsonclicket = true;
        this.Textclicked = false;
    }

    showForm() {
        this.showJsonFormat = false;
        this.jsonclicket = false;
        this.Textclicked = true;
    }

    onDurationChange(event: any) {
        console.log('this is duration change', event);
    }

    onPlayButtonClick() {
        this.waveform.play();
    }

    onPauseButtonClick() {
        this.waveform.pause();
    }

    onPlayandOnpauseClick() {
        if (!this.clickplay) {
            this.onPlayButtonClick();
        } else {
            this.onPauseButtonClick();
        }
        this.clickplay = !this.clickplay;
    }

    highlight_word(time: any) {
        const array: any[] = [];
        const arraystart: any[] = [];
        const arrayend: any[] = [];
        for (let i = 0; i < this.wordstohighlight.length; i++) {
            array[i] = this.wordstohighlight[i];
            arraystart[i] = this.wordstohighlight[i].start;
            arrayend[i] = this.wordstohighlight[i].end;
            if (arraystart[i] <= time && arrayend[i] >= time) {
                this.wordstohighlight[i].statue = true;
                let _lastTime;
                setTimeout(() => {
                    this.wordstohighlight[i].statue = false;
                    _lastTime =
                        this.wordstohighlight[i].end - this.wordstohighlight[i].start;
                }, (this.wordstohighlight[i].end - this.wordstohighlight[i].start) * 1000);

            }
        }
        return array;
    }

    scrollToHighlight() {
        if (!this.scrollPanel) {
            return;
        }
        const el: any = document.querySelector('.highlighted');
        console.log('this is el', el, {ctnr: this.getScrollableContent()});
        const scrollableContainer = this.getScrollableContent();
        const clientHeight = (el && el.offsetTop) || null;
        console.log(clientHeight);
        if (clientHeight) {
            scrollableContainer.scrollTop = clientHeight;
        }
    }

    getScrollableContent() {
        if (!this.scrollableContainer) {
            this.scrollableContainer =
                this.scrollPanel.el.nativeElement.querySelector(
                    '.p-scrollpanel-content'
                );
        }

        return this.scrollableContainer;
    }

    additemtowortds(words: Array<any>, success: boolean) {
        for (let i = 0; i < words.length; i++) {
            words[i].statue = success; // Add "success": 2 to all objects in array
        }
    }

    testt(time: any) {
        this.timer = time.srcElement.currentTime;
    }

    testvideo(time: any) {
        this.timer = time.time;
        this.timerprogress = (100 * this.timer) / this.waveform.region.end;
        console.log(this.timerprogress);
        this.scrollToHighlight();
    }
}
