import {HttpClient, HttpHeaders, HttpRequest} from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as moment from 'moment';
import {BehaviorSubject, Observable, Subject, of, empty, throwError} from 'rxjs';
import {catchError, map, takeWhile} from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { takeUntil } from 'rxjs/operators';

const BFF_URL = environment.BFF_KEY || 'http://localhost:8000';

@Injectable({
  providedIn: 'root'
})
export class StreamingService {

  private stop$ = new Subject();

  constructor(private http: HttpClient) { }

  getFileStreaming(userId, fileId): Observable<any> {
    let chunck = 1024 * 1000;
    const request = new HttpRequest(
      'GET',
      `http://192.168.1.40:8009/videostreaming?file_id=d42622b7-8547-4e8d-b830-d8de2ada9e71&user_id=9a53d001-f5fc-4323-a98c-46a658fa367c`,

       { responseType: 'arraybuffer' }
    );

    return this.http.request(request)



// return this.http.get(`http://192.168.1.40:8009/videostreaming?file_id=d42622b7-8547-4e8d-b830-d8de2ada9e71&user_id=9a53d001-f5fc-4323-a98c-46a658fa367c`,
//
//     { responseType: 'arraybuffer' }).pipe(
//         map((data: ArrayBuffer) => {
//           return data;
//         }), catchError( error => {
//           return throwError( 'Something went wrong!', error );
//         })
//     );
  }

   myBrowser() {
          if((navigator.userAgent.indexOf("Opera") || navigator.userAgent.indexOf('OPR')) != -1 ) {
              return 'Opera';
          }else if(navigator.userAgent.indexOf("Chrome") != -1 ){
              return 'Chrome';
          }else if(navigator.userAgent.indexOf("Safari") != -1){
              return 'Safari';
          }else if(navigator.userAgent.indexOf("Firefox") != -1 ) {
               return 'Firefox';
          }
      }

  formatTime(time: number, format: string = 'HH:mm:ss') {
    const momentTime = time * 1000;
    return moment.utc(momentTime).format(format);
  }

}
