import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { Subscription } from "rxjs";
import { AuthService } from "src/shared/service/auth/auth.service";
import { ModalService } from "src/shared/service/modal.service";
import {StreamingService} from '../../../shared/service/Streaming/streaming.service';
import { TrascriptionServiceService } from "src/shared/service/transcriptions/trascription-service.service";
import { environment } from "../../../environments/environment.prod";

const BFF_URL = environment.BFF_KEY || "http://localhost:8000";

@Component({
  selector: "app-video-player",
  templateUrl: "./video-player.component.html",
  styleUrls: ["./video-player.component.css"],
  encapsulation: ViewEncapsulation.None,
})
export class VideoPlayerComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild("target", { static: true }) target: ElementRef;

  @ViewChild("waveform", { static: false }) waveform: any;
  @ViewChild("testAudior", { static: false }) audioPlayer: ElementRef;
  speackers: any;
  urlvideo: any;
  texttotest: any;
  wordstohighlight: any;
  statuetextopen = "textclicked";
  statuejsonopen = "textnotclicked";
  start: boolean = true;
  timer: number;
  timerprogress: any;
  file: any;
  scrollableContainer: HTMLElement;
  responsetranscription: any = "";
  showJsonFormat;
  colorit: boolean = false;
  onpauseaudio: boolean = false;
  playaudiotest: boolean;
  ishttpLoaded = false;
  selectedValue: any = {};
  errorMessage: string = ";";
  speech: string = "";
  cellcolor = "primary";
  checkedmodeltotext: boolean = true;
  testingjson: any;
  audiook = false;
  progress: any;
  @ViewChild("scrollPanel", { static: false }) scrollPanel: any;
  @Output() statusClasssimple1event = new EventEmitter<any>();
  @ViewChild("canvas", { static: true })
  isCheckedColor = false;
  spanwordclicked: any;
  ngformstart = false;
  modaluploadSelected = false;
  user_id: any;
  audioStart: any;
  audioEnd: any;
  @Input() id: string;
  @Input() element: any;
  getTranscription: Subscription;
  modalIsOpen: boolean;
  statusClasssimple1 = "active";
  uploadstart = "startNotactive";
  clickplay: boolean = false;
  uploading: boolean = false;
  testAudio: any;
  @Input() FileForModal;
  testColor;
  transcription_id;
  id_file_selected;
  name_file_selected;
  name_File: string;
  elementPressed: any;
  @Input() blobUrlofaudio;
  urlAudio;
  endoffile;
  urlWaveForm: any;
  confCode: any;
  speackerData: any;
  datashow: any;
  speakerCheked: boolean;
  extension: any;
  videoVtt: any = "";
  showDownload = false;
  constructor(
    private transcriptionService: TrascriptionServiceService,
    public authService: AuthService,
    private modalService: ModalService,
    private sanitizer: DomSanitizer,
    private streamingService: StreamingService
  ) {}

  ngOnDestroy(): void {
    this.videoVtt = "";
    this.urlAudio = "";
    if (this.getTranscription) {
      this.getTranscription.unsubscribe();
    }

    this.urlvideo = "";
    this.uploadstart = "";
    this.uploadstart = "startNotactive";
  }

  ngOnChanges(): void {
    this.testAudio.ontimeupdate = function (time) {};

    console.log("audio url", this.testAudio);
    this.testAudio.addEventListener("timeupdate", (currentTime) => {
      console.log("currenttime", currentTime);
    });
    this.testAudio.addEventListener(
      "timeupdate",
      function () {
        var currentTimeMs = this.testAudio.currentTime * 1000;
        console.log(currentTimeMs);
      },
      false
    );

    let elem = document.querySelector("testAudior");
    console.log("elem", elem);
  }

  ngOnInit(): void {
    let elem = document.querySelector("testAudior");
    console.log("elem", elem);

    this.user_id = this.getUser();

    this.modalService.fileInoformation$().subscribe((test) => {
      console.log("thgis is informatuon from test", test);
      this.speakerCheked = test[3];
      this.transcription_id = test[1];
      this.id_file_selected = test[0];
      this.name_file_selected = test[2];
      this.extension = test[4];
    });
    console.log("extension", this.extension);
    //this.playAudio()
    this.transcriptionService.isUrlAudio$().subscribe((url) => {
      this.urlAudio = url;
    });
    this.urlvideo = "";
    this.ngformstart = false;
    this.uploadstart = "startNotactive";
    console.log(
      "test id file selected in wave player in iniyt",
      this.id_file_selected
    );
    this.getSubtitleForVideo();
    this.playAudio();
    this.name_File = this.truncate(this.name_file_selected, 70);

    console.log(this.transcription_id);

    this.transcriptionService.isUrl().subscribe((url) => {
      this.elementPressed = url;
    });
    this.modalService.isOpen$().subscribe((isOpen) => {
      this.modalIsOpen = isOpen;
    });

    if (this.elementPressed && this.modalIsOpen) {
      this.ngformstart = false;
      this.testColor = "#fffff";
      this.urlvideo = "";
      this.uploadstart = "startNotactive";
    }
  }
  getSubtitleForVideo() {
    console.log("fffff", this.videoVtt);

    this.download_Transcription_By_Id(this.transcription_id, "vtt");
  }

  getUser() {
    let user_id = "test";
    if (this.authService.loggedIn()) {
      const jwtData = this.authService.getJwtData();
      user_id = jwtData.user_id;
    }
    return user_id;
  }
  downloadJSON() {
    this.download_Transcription_By_Id(this.transcription_id, "json");
  }

  download_Transcription_By_Id(
    transcription_id: any,

    type: any
  ) {
    this.transcriptionService
      .download_Transcription_By_Id(transcription_id, type)
      .subscribe(
        (response) => {
          if (response) {
            this.showDownload = true;
            if (type == "json") {
              let dataType = response.type;
              let binaryData = [];
              binaryData.push(response);
              let downloadLink = document.createElement("a");
              downloadLink.href = window.URL.createObjectURL(
                new Blob(binaryData, { type: dataType })
              );

              downloadLink.setAttribute(
                "download",
                this.name_file_selected + "." + type
              );
              document.body.appendChild(downloadLink);
              downloadLink.click();
            } else if (type == "vtt") {
              let binaryData = [];
              binaryData.push(response);

              let downloadLink;
              downloadLink = new Blob(binaryData, {
                type: "text/plain; charset=utf-8",
              });
              var a;
              a = this.sanitizer.bypassSecurityTrustUrl(
                URL.createObjectURL(downloadLink)
              );
              this.videoVtt = a;
            }
          }
        },
        (error) => {
          console.log("there is no Transcription", error);
        }
      );
  }
  download_Transcription_By_Id_vtt(type: any) {
    this.transcriptionService
      .download_Transcription_By_Id(this.transcription_id, type)
      .subscribe((response) => {
        console.log("test the response", response);
        let dataType = response.type;
        let binaryData = [];
        binaryData.push(response);
        let downloadLink = document.createElement("a");
        downloadLink.href = window.URL.createObjectURL(
          new Blob(binaryData, { type: dataType })
        );
        downloadLink.setAttribute(
          "download",
          this.name_file_selected + "." + type
        );
        document.body.appendChild(downloadLink);
        downloadLink.click();
      });
  }

  playAudio() {
      if (this.streamingService.myBrowser() == 'Firefox') {
      this.urlvideo = `${BFF_URL}/streaming_media_file_condition?file_id=${this.id_file_selected}&user_id=${this.user_id}`;
      }
      else{
      this.urlvideo = `${BFF_URL}/streaming_media_file?file_id=${this.id_file_selected}&user_id=${this.user_id}`;
      }

    if (this.urlvideo) {
      setTimeout(() => {
        this.audiook = true;
        this.testAudio = <HTMLAudioElement>(
          document.getElementById("testAudior")
        );
        this.testAudio.addEventListener("timeupdate", (currentTime) => {
          this.timer = this.testAudio.currentTime;
        });
      }, 1000);
    }
    if (this.audiook == true) {
      this.testAudio = <HTMLAudioElement>document.getElementById("testAudior");
    }

    let elem = document.querySelector("testAudior");
    console.log("elem", elem);

    this.testAudio = <HTMLAudioElement>document.getElementById("testAudior");
    console.log("show the audio url", this.testAudio);
  }

  getprogress(event: any) {
    this.progress = event;
  }

  close(): void {
    this.modalService.ModalStateisclosed();
  }

  cancel(): void {
    this.close();
  }
  truncate(str: any, n: any) {
    if (str) {
      return str.length > n ? str.substr(0, n - 1) + "..." : str;
    }
  }
}
