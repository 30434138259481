import { Component, ElementRef, EventEmitter, OnChanges, OnInit, Output, ViewChild, ViewEncapsulation } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { NgWaveformComponent } from "ng-waveform";
import { FileUploadService } from "src/shared/service/file-upload.service";
import { fileDetailsModeltext } from "../../../../shared/models/fileDetailsModeltext";
import * as moment from 'moment';
interface Model {
  value: string;
}
declare var require: any;

@Component({
  selector: 'app-playsample3',
  templateUrl: './playsample3.component.html',
  styleUrls: ['./playsample3.component.scss'],
  encapsulation:ViewEncapsulation.None
})
export class Playsample3Component implements  OnInit, OnChanges {
  @ViewChild("waveform") waveform: NgWaveformComponent;
  clickplay: boolean = false;
  audioStart :any;
  uploading: boolean = false;
  models: Model[] = [
    { value: "model_kaldiASR.English" },
    { value: "model_kaldiASR.English" },
  ];
  statuetextopen = "textclicked"
  statuejsonopen = "textnotclicked"
  filetestwav: any="src\shared\audio\test.wav";
  start: boolean = true;
  timer: number;
  timerprogress:any;
  urlvideo: any;
  file: any;
  scrollableContainer: HTMLElement;
  responsetranscription: any = "";
  showJsonFormat;
  wordstohighlight: any;
  textresponse: any= require("../../../../shared/models/fr_transcription.json"); 
  currentStyles: {};
  colorit: boolean = false;
  onpauseaudio: boolean = false;
  playaudiotest: boolean;
  ishttpLoaded = false;
  selectedValue: any = {};
  texttotest: any;
  errorMessage: string = ";";
  speech: string = "";
  checkedmodeltotext: boolean = true;
  testingjson: any;
  progress: any;
  fileDetailsModeltext = new fileDetailsModeltext();
  blobUrlofaudio:any;
  statusClasssimple3 = "active";
  ngformstart = false;
  @ViewChild("scrollPanel", { static: false }) scrollPanel: any;
  @Output() statusClasssimple3event = new EventEmitter<any>();
  @ViewChild("canvas", { static: true })
  uploadstart = "startNotactive"
  canvas: ElementRef<HTMLCanvasElement>;
  spanwordclicked: any;
  options = {
    responsive: true,
    maintainAspectRatio: false
  };
  
  constructor(
    private fileUploadService: FileUploadService,
    private sanitizer: DomSanitizer,
  ) {}
  
  
  ngOnInit(): void {
    this.statusClasssimple3 = "active";
    this.statusClasssimple3event.emit(this.statusClasssimple3)
    this. playsimple3();
   
    
    
    
  }

  ngOnChanges() {
    this.scrollToHighlight();
  }
  getColor(statue: boolean): object | undefined {
    if (statue == false) {
      return { "background-color": "white" };
    } else if (statue == true) {
      return {
        "background-color": "#39A9DA",
        color: "white",
        "border-radius": "25px",
        "padding-left": "3px",
      };
    }
  }
  getprogress(event: any) {
    
 
    this.progress = event;
  }
  playsimple3(){
   // this.urlvideo  = 
   this.urlvideo= "../../../../../../assets/audio/test_fr_10m.mp3"
    this.wordstohighlight = this.textresponse.words;
    this.testingjson = JSON.stringify(this.wordstohighlight);
    this.additemtowortds(this.wordstohighlight, this.colorit);
    this.texttotest = this.highlight_word(this.timer);
  }
  
  getaudiotimeron(tag: any) {
    //  this.urlvideo = "../../"
    console.log(tag);
    this.spanwordclicked = tag;

    this.timer = this.spanwordclicked.start;
    console.log("donee", this.waveform.setRegionStart(this.timer));
    console.log("this is region for waveform", this.waveform);
    this.waveform.setRegionStart;
  }

  isLastWord(tag: any) {
    return tag.start <= this.timer && tag.end >= this.timer;
  }

  getuploadstate($event) {
    this.uploading = $event;
    console.log("uploading", this.uploading);
  }
  getstartstate($event) {
    this.start = $event;
    console.log("start", this.start);
  }

  showJson() {
    this.showJsonFormat = true;
    this.statuetextopen = "textnotclicked";
    this.statuejsonopen = "textclicked";
    this.scrollToHighlight();
  }
  showForm() {
    this.showJsonFormat = false;
    this.statuetextopen = "textclicked";
    this.statuejsonopen = "textnotclicked";
    this.scrollToHighlight();
  }
  onDurationChange(event: any) {
    console.log("this is duration change", event);
    if (event != 0) {
      this.ngformstart = true;
    }
    if (this.ngformstart == true) {
      this.uploadstart = "startactive";
    }
  }
  onTrackLoaded(event) {
    console.log("renred", event);
  }
  onPlayButtonClick() {
    this.waveform.play();
  }
  onPauseButtonClick() {
    this.waveform.pause();
  }
  onPlayandOnpauseClick() {
    if (!this.clickplay) {
      this.onPlayButtonClick();
    } else {
      this.onPauseButtonClick();
    }
    this.clickplay = !this.clickplay;
  }
  onUpload1(file: any) {
    this.ishttpLoaded = true;
    console.log("this is file for uploade1", file);
    this.selectedValue = "model_kaldiASR.English";

    this.fileDetailsModeltext.file_name = file.name;
    this.fileDetailsModeltext.from_cache = this.checkedmodeltotext;
    //this.toastr.info("transcription in progress!");

    this.fileUploadService
      .createmodelKaldi(this.fileDetailsModeltext)
      .subscribe(
        (data) => {
          this.responsetranscription = data;
          console.log("this is transcription", this.responsetranscription);

          this.wordstohighlight = this.responsetranscription.words;
          this.testingjson = JSON.stringify(this.wordstohighlight);
          this.additemtowortds(this.wordstohighlight, this.colorit);
          this.texttotest = this.highlight_word(this.timer);
        //  this.toastr.success("Transcription succeeded !");
          this.ishttpLoaded = false;
        },
        (error) => {
          //Error callback
          this.errorMessage = error;
         // this.toastr.error("Something went wrong, please try again !");
          this.ishttpLoaded = true;
        }
      );

    /*   this.valueselected.emit({
      selectedModel: this.selectedValue,
      index: this.file.index,
    });*/
  }
  onTrackRendered(event: any) {
    console.log("this is rendred event", event);
  }
  highlight_word(time: any) {
    const array: any[] = [];
    const arraystart: any[] = [];
    const arrayend: any[] = [];
    for (let i = 0; i < this.wordstohighlight.length; i++) {
      array[i] = this.wordstohighlight[i];
      arraystart[i] = this.wordstohighlight[i].start;
      arrayend[i] = this.wordstohighlight[i].end;
      if (arraystart[i] <= time && arrayend[i] >= time) {
        this.wordstohighlight[i].statue = true;
        let _lastTime;

        setTimeout(() => {
          this.wordstohighlight[i].statue = false;
          _lastTime =
            this.wordstohighlight[i].end - this.wordstohighlight[i].start;
        }, (this.wordstohighlight[i].end - this.wordstohighlight[i].start) * 1000);
        /*  if (this.audioPause == true) {
          this.wordstohighlight[i].statue = true;
          this.texttotest = array;
        }*/
      }
    }

    return array;
  }

  scrollToHighlight() {
    if (!this.scrollPanel) return;

    const el: any = document.querySelector(".highlighted");
    console.log("this is el", el, { ctnr: this.getScrollableContent() });
    const scrollableContainer = this.getScrollableContent();
    const clientHeight = (el && el.offsetTop) || null;
    console.log("achrefff is testing here", clientHeight);
    if (clientHeight) scrollableContainer.scrollTop = clientHeight;
  }

  getScrollableContent() {
    if (!this.scrollableContainer || !this.scrollableContainer.isConnected) {
      this.scrollableContainer =
        this.scrollPanel.el.nativeElement.querySelector(
          ".p-scrollpanel-content"
        );
    }
    
    return this.scrollableContainer;
  }

  additemtowortds(words: Array<any>, success: boolean) {
    for (var i = 0; i < words.length; i++) {
      words[i].statue = success; // Add "success": 2 to all objects in array
    }
  }

  testt(time: any) {
    this.timer = time.srcElement.currentTime;
  }

  testvideo(time: any) {
    this.timer = time.time;
    if (this.waveform != null) {
    console.log(time);
    this.timerprogress = (100 * this.timer) / this.waveform.region.end;
    console.log(
      "this is last test of ng waveform",
      this.waveform.region.end / 60
    );
    console.log("this is time endinng", this.waveform.region.end);
    console.log("test video methode for end time ", this.waveform.region.end);
    this.audioStart = this.waveform.region.start;

    var display = moment().startOf("day").seconds(this.timer).format("H:mm:ss");

    this.audioStart = display;
    }
    this.scrollToHighlight();
  }
  

}


