import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import * as moment from "moment";
import { Subscription } from "rxjs";
import { AuthService } from "src/shared/service/auth/auth.service";
import { ModalService } from "src/shared/service/modal.service";
import { TrascriptionServiceService } from "src/shared/service/transcriptions/trascription-service.service";
import {StreamingService} from '../../../shared/service/Streaming/streaming.service';

import { environment } from "../../../environments/environment.prod";
import WaveSurfer from "wavesurfer.js";
import { MatSlideToggleChange } from "@angular/material/slide-toggle";
const BFF_URL = environment.BFF_KEY || "http://localhost:8000";
@Component({
  selector: "app-wave-player",
  templateUrl: "./wave-player.component.html",
  styleUrls: ["./wave-player.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class WavePlayerComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild("target", { static: true }) target: ElementRef;
  @ViewChild("testAudior", { static: false }) audioPlayer: ElementRef;

  @ViewChild("waveform", { static: false }) waveform: any;
  speackers: any;
  urlvideo: any;
  texttotest: any;
  wordstohighlight: any;
  statuetextopen = "textclicked";
  statuejsonopen = "textnotclicked";
  start: boolean = true;
  timer: number;
  timerprogress: any;
  file: any;
  scrollableContainer: HTMLElement;
  responsetranscription: any = "";
  showJsonFormat;
  colorit: boolean = false;
  onpauseaudio: boolean = false;
  playaudiotest: boolean;
  ishttpLoaded = false;
  selectedValue: any = {};
  errorMessage: string = ";";
  speech: string = "";
  cellcolor = "primary";
  checkedmodeltotext: boolean = true;
  testingjson: any;
  audiook = false;
  progress: any;
  urlAudioObs;
  @ViewChild("scrollPanel", { static: false }) scrollPanel: any;
  @Output() statusClasssimple1event = new EventEmitter<any>();
  @ViewChild("canvas", { static: true })
  isCheckedColor = false;
  spanwordclicked: any;
  ngformstart = false;
  modaluploadSelected = false;
  user_id: any;
  audioStart: any;
  audioEnd: any;
  @Input() id: string;
  @Input() element: any;
  getTranscription: Subscription;
  modalIsOpen: boolean;
  statusClasssimple1 = "active";
  uploadstart = "startNotactive";
  clickplay: boolean = false;
  uploading: boolean = false;
  testAudio: any;
  @Input() FileForModal;
  testColor;
  transcription_id;
  id_file_selected;
  name_file_selected;
  name_File: string;
  elementPressed: any;
  @Input() blobUrlofaudio;
  urlAudio;
  endoffile;
  urlWaveForm: any;
  confCode: any;
  speackerData: any;
  datashow: any;
  speakerCheked: boolean = false;
  extension: any;
  videoVtt: any = "";
  uriTranscription: any;
  wave: WaveSurfer | null = null;
  jsonWave = null;
  skillID: any;
  showChecked = false;
  TextStyleingWavePlayer = "textjustifierWave";
  showSpeakerStyle = "slideColor";
  constructor(
    private transcriptionService: TrascriptionServiceService,
    public authService: AuthService,
    private modalService: ModalService,
    private sanitizer: DomSanitizer,
    private streamingService: StreamingService
  ) {
    this.element;
  }

  ngOnDestroy(): void {
    this.videoVtt = "";
    this.urlAudio = "";
    if (this.getTranscription) {
      this.getTranscription.unsubscribe();
    }
    if (this.urlAudioObs) {
      this.urlAudioObs.unsubscribe();
    }

    this.urlvideo = "";
    this.uploadstart = "";
    this.uploadstart = "startNotactive";
  }

  ngOnChanges(): void {
    this.testAudio.ontimeupdate = function (time) {};

    console.log("audio url", this.testAudio);
    this.testAudio.addEventListener("timeupdate", (currentTime) => {
      console.log("currenttime", currentTime);
    });
    this.testAudio.addEventListener(
      "timeupdate",
      function () {
        var currentTimeMs = this.testAudio.currentTime * 1000;
      },
      false
    );
    this.scrollToHighlight();

    this.getTranscriptionWithDiarization(
      this.transcription_id,
      this.speakerCheked
    );

    console.log(this.urlvideo);
    let elem = document.querySelector("testAudior");
    console.log("elem", elem);
  }

  ngOnInit(): void {
    let elem = document.querySelector("testAudior");
    console.log("elem", elem);

    this.user_id = this.getUser();

    this.modalService.fileInoformation$().subscribe((test) => {
      this.showChecked = test[3];
      this.transcription_id = test[1];
      this.id_file_selected = test[0];
      this.name_file_selected = test[2];
      this.extension = test[4];
      this.uriTranscription = test[5];
    });
    this.transcriptionService.isUrlAudio$().subscribe((url) => {
      this.urlAudio = url;
    });
    this.urlvideo = "";
    this.ngformstart = false;
    this.uploadstart = "startNotactive";
    /* this.wave = WaveSurfer.create({
      ...WaveParams,
      container: "#waveform",
    });*/
    // this.getSubtitleForVideo();
    //  this.urlvideo = `${BFF_URL}/streaming_media_file?file_id=${this.id_file_selected}&user_id=${this.user_id}`;
    console.log("test audio", this.urlvideo);
    this.modalService.urlAudio$().subscribe((data) => {
      if (data) {
        this.urlvideo = data;
      }
    });
    this.playAudio();
    this.name_File = this.truncate(this.name_file_selected, 70);
    this.scrollToHighlight();
    this.transcriptionService.isUrl().subscribe((url) => {
      this.elementPressed = url;
    });
    this.modalService.isOpen$().subscribe((isOpen) => {
      this.modalIsOpen = isOpen;
    });
    if (this.showChecked) {
      this.showSpeakerStyle = "slideColor";
    } else {
      this.showSpeakerStyle = "NotslideColor";
    }
    this.getTranscriptionWithDiarization(this.transcription_id, false);

    this.getTranscriptionById(this.transcription_id);
    this.testAudio = document.getElementById("testAudior") as HTMLElement;
    /* this.wave.on("play", (event) => {});
    this.wave.on("audioprocess", (event) => {
      this.testvideo(this.wave.getCurrentTime());
    });
    this.wave.on("finish", (event) => {
      this.clickplay = !this.clickplay;
    });
*/
    if (this.elementPressed && this.modalIsOpen) {
      this.ngformstart = false;
      this.testColor = "#fffff";
      this.urlvideo = "";
      this.uploadstart = "startNotactive";
    }
  }

  /*getSubtitleForVideo() {
    

    this.download_Transcription_By_Id(this.transcription_id, "vtt");
  }

  togglePlayPause() {
    this.clickplay = !this.clickplay;
    if (this.wave?.isPlaying()) {
      this.wave?.pause();
    } else {
      this.wave?.play(this.wave?.getCurrentTime());
    }
  }*/

  cssForWaveText() {
    if (this.skillID == "ASR Arabic Model") {
      console.log("tessttt wavee id");
      this.TextStyleingWavePlayer = "arabicTextWavePlayer";
    } else {
      console.log("tessttt wavee not id");
      this.TextStyleingWavePlayer = "textjustifierWave";
    }
  }

  getTranscriptionWithDiarization(id_transcription, options) {
    console.log("test speaker");

    this.transcriptionService
      .getTranscriptionWithDiarization(id_transcription)
      .subscribe((data) => {
        // this.wordstohighlight = data.speakers

        // console.log('test server',this.getKeyFromResponse(this.getKEy((data.body.speakers))))
        if (data == null) {
          this.texttotest = "";
        } else if (this.speakerCheked) {
          this.skillID = data.skill_name;
          console.log(this.getKeyFromResponse(data.transcription.speakers));
          this.wordstohighlight = data.transcription.speakers;
          this.cssForWaveText();
          this.getKeyFromResponse(this.wordstohighlight);
          this.testingjson = JSON.stringify(this.wordstohighlight);
          this.additemtowortds(this.wordstohighlight, this.colorit);
          this.texttotest = this.highlight_word(this.timer);
          console.log("-------llllll", this.texttotest, this.jsonWave);
          console.log("text to test", this.texttotest);
        } else if (!this.speakerCheked) {
          this.skillID = data.skill_name;
          this.wordstohighlight = data.transcription.words;
          this.cssForWaveText();
          this.testingjson = JSON.stringify(this.wordstohighlight);
          this.additemtowortds(this.wordstohighlight, this.colorit);
          this.texttotest = "eeezze";
          this.texttotest = this.highlight_word(this.timer);
        }
      });
  }
  changedSpeaker($event: MatSlideToggleChange) {
    console.log("testtting speakerrs", this.speakerCheked, $event);
    this.getTranscriptionWithDiarization(
      this.transcription_id,
      this.speakerCheked
    );
  }

  playAudio() {

      if (this.streamingService.myBrowser() == 'Firefox') {
      this.urlvideo = `${BFF_URL}/streaming_media_file_condition?file_id=${this.id_file_selected}&user_id=${this.user_id}`;
      }
      else{
      this.urlvideo = `${BFF_URL}/streaming_media_file?file_id=${this.id_file_selected}&user_id=${this.user_id}`;
      }

    console.log("test urlk video", this.urlvideo);
    if (this.urlvideo) {
      this.urlvideo;
      setTimeout(() => {
        this.audiook = true;
        this.testAudio = <HTMLAudioElement>(
          document.getElementById("testAudior")
        );
        this.testAudio.addEventListener("timeupdate", (currentTime) => {
          console.log("currenttime", this.testAudio.currentTime);
          this.timer = this.testAudio.currentTime;
          console.log("currenttime", currentTime.timeStamp / 1000);
        });
      }, 1000);
    }
    if (this.audiook == true) {
      this.testAudio = <HTMLAudioElement>document.getElementById("testAudior");
    }

    let elem = document.querySelector("testAudior");
    console.log("elem", elem);

    this.testAudio = <HTMLAudioElement>document.getElementById("testAudior");
    console.log("show the audio url", this.testAudio);
  }

  getKeyFromResponse(data) {
    this.speackerData = [];
    Object.keys(data).forEach((key) => {
      this.speackerData.push({
        spk: data[key].speaker_id,
        color: this.randomColor(this.authService.encrypt(data[key].speaker_id)),
        speakers: data[key].words,
      });
    });
    // this.wordstohighlight = this.speackerData.speakers
    return this.speackerData;
  }

  trackByStartTime(index, item) {
    return item.start;
  }

  randomColor(str) {
    /* var randomColor = Math.floor(Math.random() * 16777215).toString(16);
    return randomColor;*/

    var hash = 0;
    for (var i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    var colour = "#";
    for (var i = 0; i < 3; i++) {
      var value = (hash >> (i * 8)) & 0xff;
      colour += ("00" + value.toString(16)).substr(-2);
    }

    return colour;
  }
  getKEy(data) {
    this.speackers = [];
    Object.keys(data).forEach((key) => {
      this.speackers.push({
        speackers: data[key],
      });
    });
    return this.speackers;
  }

  getUser() {
    let user_id = "test";
    if (this.authService.loggedIn()) {
      const jwtData = this.authService.getJwtData();
      user_id = jwtData.user_id;
    }
    return user_id;
  }

  /* getFileToDisplay(id_file) {
       this.getTranscription = this.transcriptionService
         .downloadFile(id_file, this.user_id)
         .subscribe((response) => {
           console.log("ccccc");
           this.playAudio(response);
           console.log("teserrrrr", this.audioPlayer);
         });
     }*/
  download_Transcription_By_Id(transcription_id: any, type: any) {
    this.transcriptionService
      .download_Transcription_By_Id(transcription_id, type)
      .subscribe(
        (response) => {
          console.log("test the response", response);
          const binaryData = [];
          binaryData.push(response);

          let downloadLink;
          downloadLink = new Blob(binaryData, {
            type: "text/plain; charset=utf-8",
          });
          let a;
          a = this.sanitizer.bypassSecurityTrustUrl(
            URL.createObjectURL(downloadLink)
          );
          this.videoVtt = a;

          console.log("test video tt ", this.videoVtt);
        },
        () => {
          console.log("there is no Transcription");
        }
      );
  }

  download_Transcription_By_Id_vtt(type: any) {
    this.transcriptionService
      .download_Transcription_By_Id(this.transcription_id, type)
      .subscribe((response) => {
        console.log("test the response", response);
        const dataType = response.type;
        const binaryData = [];
        binaryData.push(response);
        const downloadLink = document.createElement("a");
        downloadLink.href = window.URL.createObjectURL(
          new Blob(binaryData, { type: dataType })
        );
        downloadLink.setAttribute(
          "download",
          this.name_file_selected + "." + type
        );
        document.body.appendChild(downloadLink);
        downloadLink.click();
      });
  }

  getTranscriptionById(id_transcription) {
    const fromCache =
      this.uriTranscription === undefined || this.uriTranscription === null;
    this.transcriptionService
      .Get_Transcription_By_Id(id_transcription, false, this.uriTranscription)
      .subscribe((response) => {
        if (response == null) {
          this.texttotest = "";
        } else {
          console.log({ test: response });
          if (response.transcription) {
            this.wordstohighlight = response.transcription.words;
          }
          console.log({ test: this.wordstohighlight });
          this.testingjson = JSON.stringify(this.wordstohighlight);
          this.additemtowortds(this.wordstohighlight, this.colorit);
          this.texttotest = "eeezze";
          this.texttotest = this.highlight_word(this.timer);
        }
      });
  }

  ngDoCheck() {
    this.scrollToHighlight();
  }

  getColor(statue: boolean): object | undefined {
    if (!statue) {
      return { "background-color": "" };
    } else if (statue) {
      return {
        "text-decoration": this.getAudioConf(this.confCode),
        "text-underline-offset": "3px",
        "text-decoration-thickness": "4px",
        "background-color": this.getAudioConf(this.confCode),
        'color': "white",
        "border-radius": "5px",
        "padding-left": "3px",
      };
    }
  }
  speakerColor(tag) {
    if (this.skillID === "ASR Arabic Model") {
      return {
        color: "white",
        "background-color": tag,
        "font-style": "italic;",
        "font-size": "20px",
        "border-radius": "77px",
        "margin-left": "2%",
        "padding-right": "1%",
        "padding-left": "3px",
      };
    } else {
      return {
        color: "white",
        "background-color": tag,
        "font-size": "20px",
        "border-radius": "77px",
        "margin-right": "2%",
        "padding-right": "1%",
        "padding-left": "2%",
      };
    }
  }
  getAudioConf(confCode) {
    if (this.isCheckedColor) {
      if (confCode >= 0.6) {
        return "  #8dcfaa"; //vert
      } else if (0.4 <= confCode && confCode < 0.6) {
        return "  #f88e55"; //jaune
      } else if (confCode < 0.4) {
        return "  red"; //red
      }
    } else {
      return "#39A9DA";
    }
  }

  getprogress(event: any) {
    this.progress = event;
  }

  getaudiotimeron(tag: any) {
    this.spanwordclicked = tag;

    this.timer = this.spanwordclicked.start;
    this.testAudio.currentTime = this.timer;

    /* console.log("wave Start", this.wave.start);
    //this.testAudio.currentTime = this.timer;
    this.wave.start = this.timer;
    this.wave.seekTo(this.wave.start / this.wave.getDuration());
    console.log("wave Start", this.wave.start);*/
    // this.waveform.setRegionStart;
  }

  isLastWord(tag: any) {
    this.confCode = tag.conf;
    return tag.start <= this.timer && tag.end >= this.timer;
  }

  getTimeForAudioTag() {}

  getuploadstate($event) {
    this.uploading = $event;
  }

  getstartstate($event) {
    this.start = $event;
    console.log({ startiingg: this.start });
  }

  showJson() {
    this.showJsonFormat = true;
    this.statuetextopen = "textnotclicked";
    this.statuejsonopen = "textclicked";
    this.scrollToHighlight();
  }

  showForm() {
    this.showJsonFormat = false;
    this.statuetextopen = "textclicked";
    this.statuejsonopen = "textnotclicked";
    this.scrollToHighlight();
  }

  onTrackLoaded() {}

  onTrackRendered($event) {
    console.log("this is time for waveform rendering", $event);
    if ($event != 0 && this.urlvideo) {
      this.ngformstart = true;

      this.uploadstart = "startactive";
      this.getTranscription.unsubscribe();
    }
  }

  onPlayButtonClick() {
    this.waveform.play();
  }

  onPauseButtonClick() {
    this.waveform.pause();
  }

  onPlayandOnpauseClick() {
    if (!this.clickplay) {
      this.onPlayButtonClick();
    } else {
      this.onPauseButtonClick();
    }
    this.clickplay = !this.clickplay;
  }

  highlight_word(time: any) {
    const array: any[] = [];
    const arraystart: any[] = [];
    const arrayend: any[] = [];
    if (this.speakerCheked) {
      this.wordstohighlight = this.speackerData;
    }

    for (let i = 0; i < this.wordstohighlight.length; i++) {
      array[i] = this.wordstohighlight[i];
      arraystart[i] = this.wordstohighlight[i].start;
      arrayend[i] = this.wordstohighlight[i].end;
      if (arraystart[i] <= time && arrayend[i] >= time) {
        this.wordstohighlight[i].statue = true;
        let _lastTime;

        setTimeout(() => {
          this.wordstohighlight[i].statue = false;
          _lastTime =
            this.wordstohighlight[i].end - this.wordstohighlight[i].start;
        }, (this.wordstohighlight[i].end - this.wordstohighlight[i].start) * 1000);
      }
    }

    return array;
  }

  scrollToHighlight() {
    if (!this.scrollPanel) {
      return;
    }

    const el: any = document.querySelector(".highlighted");

    const scrollableContainer = this.getScrollableContent();
    const clientHeight = (el && el.offsetTop) || null;

    if (clientHeight) {
      console.log(
        "testin g the client height",
        clientHeight,
        "rerer",
        scrollableContainer.clientHeight,
        "rere",
        scrollableContainer.scrollHeight
      );

      scrollableContainer.scrollTop = clientHeight - 40;
    }
  }

  getScrollableContent() {
    if (!this.scrollableContainer || !this.scrollableContainer.isConnected) {
      this.scrollableContainer =
        this.scrollPanel.el.nativeElement.querySelector(
          ".p-scrollpanel-content"
        );
    }

    return this.scrollableContainer;
  }

  getcanvascontent() {}

  additemtowortds(words: Array<any>, success: boolean) {
    for (var i = 0; i < words.length; i++) {
      words[i].statue = success; // Add "success": 2 to all objects in array
    }
  }

  testt(time: any) {
    this.timer = time.srcElement.currentTime;
  }

  testvideo(time: any) {
    console.log("time", time);
    this.timer = time;

    if (this.waveform != null) {
      if (this.clickplay) {
        this.timerprogress = (100 * this.timer) / this.wave.getDuration();
        this.audioStart = 0;
        var display = moment()
          .startOf("day")
          .seconds(this.timer)
          .format("H:mm:ss");
        this.audioStart = display;
        if (time.progress >= 100) {
          this.clickplay = false;
          this.wave.pause();
        }
      }
      if (time.progress >= 100) {
        this.clickplay = false;
        this.wave.pause();
      }
    }

    this.scrollToHighlight();
  }

  onRegionChange($event) {
    console.log("region changed", $event.end);
    this.endoffile = $event.end;
  }

  close(): void {
    this.modalService.ModalStateisclosed();
  }

  cancel(): void {
    this.close();
  }

  truncate(str: any, n: any) {
    if (str) {
      return str.length > n ? str.substr(0, n - 1) + "..." : str;
    }
  }
}
