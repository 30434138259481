import { Component, OnInit } from '@angular/core';
import {DemoTranscriptionsService} from '../../../shared/service/demo-transcriptions/demo-transcriptions.service';

@Component({
  selector: 'app-admin-dashboard',
  templateUrl: './admin-dashboard.component.html',
  styleUrls: ['./admin-dashboard.component.css']
})
export class AdminDashboardComponent implements OnInit {
    totalUsers = 190;
    totalTranscriptions = 45;
    statusOpenLiveDecoding = 'text-clicked btn-nav-live-radius';
    statusOpenUploadedFiles = 'text-not-clicked';
    showUploadFiles = false;
    statisticsData;

  constructor(private demoTranscriptionService: DemoTranscriptionsService) {
  }

  ngOnInit(): void {
      this.demoTranscriptionService.getDemoStatistic().subscribe(data => {
          this.statisticsData = data;
      });
  }
    showLiveDecoding() {
        this.showUploadFiles = false;
        this.statusOpenLiveDecoding = 'text-clicked btn-nav-live-radius';
        this.statusOpenUploadedFiles = 'text-not-clicked';
    }
    showUploadedFiles() {
        this.showUploadFiles = true;
        this.statusOpenUploadedFiles = 'text-clicked btn-nav-offline-radius';
        this.statusOpenLiveDecoding = 'text-not-clicked';
    }
}
