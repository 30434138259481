import { HttpEventType, HttpResponse } from "@angular/common/http";
import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewEncapsulation,
} from "@angular/core";
import { Subscription } from "rxjs";

import { fileDetailsModeltext } from "src/shared/models/fileDetailsModeltext";
import { AuthService } from "src/shared/service/auth/auth.service";
import { ModalService } from "src/shared/service/modal.service";
import { NotificationService } from "src/shared/service/notification/notification.service";
import { TrascriptionServiceService } from "src/shared/service/transcriptions/trascription-service.service";

@Component({
  selector: "jw-modal",
  templateUrl: "./file-upload-modal.component.html",
  styleUrls: ["./file-upload-modal.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class FileUploadModalComponent implements OnInit, OnChanges, OnDestroy {
  @Input() id: string;
  favoriteSeason: string;
  speakerSeleted: any;
  speakerToCheck = [
    { value: "true", ID: "D2", name: "Yes", checked: false },
    { value: "false", ID: "D1", name: "No", checked: true },
  ];
  files: any;
  private element: any;
  selectedmodelchanged = false;
  selectedValue: any;
  modelsavailable: any = [];
  file: any;
  fileIsReady: boolean = false;
  blobUrlofaudio: any;
  msg: any;
  isUpload = false;
  testurl: any;
  uploaddisabled = false;
  progress = 0;
  uploadloading: boolean = false;
  uploadcompleted: boolean = false;
  skillNameSelected: any;
  idModal: any;
  fileDetailsModeltext = new fileDetailsModeltext();
  @Output() sendmodelFile = new EventEmitter<any>();
  uploadFileSubscription: Subscription;
  constructor(
    private modalService: ModalService,
    private el: ElementRef,
    private transcriptionservice: TrascriptionServiceService,
    public authService: AuthService,
    public notificationService: NotificationService
  ) {
    this.element = el.nativeElement;
  }
  ngOnChanges(): void {
  }
  @HostListener("document:keypress", ["$event"])
  handleKeyboardEvent(event: KeyboardEvent) {
    
  }
  @HostListener("document:keydown.escape", ["$event"]) onKeydownHandler(
    event: KeyboardEvent
  ) {
    if (event.key === "Escape") {
      this.modalService.close(this.id);
      this.close();
    }
  }
  radioChange(event) {
    this.speakerSeleted = event.value;
  }
  ngOnInit(): void {
    this.speakerSeleted = false;
    if (!this.id) {
      console.error("modal must have an id");
      return;
    }
    this.get_available_models();

    // move element to bottom of page (just before </body>) so it can be displayed above everything else
    document.body.appendChild(this.element);

    // close modal on background click
    this.element.addEventListener("keypress", (el) => {
      console.log("this is ezdzdzdzdzdzdl", el.key);

      if (el.target.className === "jw-modal") {
        this.close();
      }
    });

    this.element.addEventListener("click", (el) => {
      if (el.target.className === "jw-modal") {
        this.close();
      }
    });
    document.addEventListener("keydown", function (event) {
      if (event.key === "Escape") {
        //do something
      }
    });

    this.element.addEventListener("keyup", (el) => {
      console.log(el.key);
      if (el.key === "Escape") {
        console.log("test class escape");
        this.modalService.close(this.id);
        this.close();
        if (el.target.className === "jw-modal") {
          console.log("test class");
          this.close();
        }
      }
    });
    this.transcriptionservice.isUpload$().subscribe((isUploading) => {
      this.isUpload = isUploading;
    });
    // add self (this modal instance) to the modal service so it's accessible from controllers
    this.modalService.add(this);
  }

  // remove self from modal service when component is destroyed
  ngOnDestroy(): void {
    this.modalService.remove(this.id);
    this.element.remove();
  }

  // open modal
  open(): void {
    this.element.style.display = "block";
    document.body.classList.add("jw-modal-open");
  }

  // close modal

  close(): void {
    this.file = [];
    this.selectedValue = "";
    this.skillNameSelected = "";
    this.selectedmodelchanged = false;
    this.msg = "";
    this.uploadcompleted = false;
    this.fileIsReady = false;
    this.uploadloading = false;
    this.element.style.display = "none";
    document.body.classList.remove("jw-modal-open");
  }

  save(): void {
    this.uploadFile();
    this.fileIsReady = false;
    this.selectedmodelchanged = false;
    this.selectedValue = "";
    this.skillNameSelected = "";
  }

  cancel(): void {
    console.log("testcancel");
    this.element.style.display = "none";
    document.body.classList.remove("jw-modal-open");
  }
  cancelUpload() {
    this.uploadFileSubscription.unsubscribe();
    this.transcriptionservice.UploadFileStateCompleted();
    this.msg = "";
    this.file = [];
    this.selectedValue = "";
    this.skillNameSelected = "";
    this.selectedmodelchanged = false;
    this.uploadcompleted = false;
    this.fileIsReady = false;
    this.uploadloading = false;
  }

  selectChangeHandler(event: any) {
    this.selectedmodelchanged = true;
    this.selectedValue = event.target.value;
    this.skillNameSelected = event.target.selectedOptions[0].id;
  }
  get_available_models() {
    this.modelsavailable = [];
    this.transcriptionservice.getModels().subscribe((data: any) => {
      Object.keys(data).forEach((key) => {
        this.modelsavailable.push({
          name: data[key].id,
          id: key,
          skillname: data[key].name,
        });
      });
    });
  }

  uploadFile() {
    this.selectedmodelchanged = false;
    this.uploadcompleted = false;
    this.progress = 0;
    this.msg = "";
    const file = this.file;
    const jwtData = this.authService.getJwtData();
    const user_id = jwtData.user_id;
    //,this.skillNameSelected
    this.uploadFileSubscription = this.transcriptionservice
      .uploadFileUser(
        file,
        user_id,
        this.selectedValue,
        this.skillNameSelected,
        this.speakerSeleted
      )
      .subscribe((event) => {
        if (event.type === HttpEventType.UploadProgress) {
          this.progress = Math.round((100 * event.loaded) / event.total);
          this.uploadloading = true;
          this.transcriptionservice.UploadFileState();
        } else if (event instanceof HttpResponse) {
          this.uploadcompleted = true;
          this.transcriptionservice.UploadFileStateCompleted();
          this.transcriptionservice.refreshListTranscription();
          this.notificationService.showSuccess("File Uploaded Successfully");
        } else if (event.type === HttpEventType.ResponseHeader) {
          if (event.status == 404) {
            this.transcriptionservice.UploadFileStateCompleted();
            this.transcriptionservice.refreshListTranscription();
            this.msg = "File name too long, Please rename and re-upload.";
          }
          if (event.status == 422) {
            this.msg = "Uploaded file is not valid";
            this.transcriptionservice.UploadFileStateCompleted();
            this.transcriptionservice.refreshListTranscription();
          }
          this.uploadcompleted = false;
          this.transcriptionservice.UploadFileStateCompleted();
          this.transcriptionservice.UploadFileStateCompleted();
          this.transcriptionservice.refreshListTranscription();
          if (this.selectedValue != "") {
            this.selectedmodelchanged = true;
          }
        }
      });
    this.transcriptionservice.UploadFileStateCompleted();
    this.uploadloading = false;
  }
  sendFile(file: any) {
    this.file = file;
    this.fileIsReady = true;
  }
}
