import { NgxPaginationModule } from 'ngx-pagination';
import { NgModule } from "@angular/core";
import {MatSliderModule} from '@angular/material/slider';
import { CommonModule } from "@angular/common";
import { SideBarComponent } from "./side-bar/side-bar.component";
import { RouterModule } from "@angular/router";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { DashboardLayoutComponent } from "../layouts/dashboard-layout/dashboard-layout.component";
import { DashboardNavBarComponent } from './dashboard-nav-bar/dashboard-nav-bar.component';
import { MDBBootstrapModule, ModalModule, TooltipModule } from "angular-bootstrap-md";
import { DashboardComponent } from "./dashboard.component";
import { GererFilesComponent } from './gerer-files/gerer-files.component';
import { FileDescriptionComponent } from './file-description/file-description.component';
import {MatMenuModule} from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import {MatDialogModule} from '@angular/material/dialog';
import { FileUploadModalComponent } from './file-upload-modal/file-upload-modal.component';
import { UploadcomponentComponent } from './uploadcomponent/uploadcomponent.component';
import { NgWaveformModule } from "ng-waveform";
import { ScrollPanelModule } from "primeng/scrollpanel";

import { ToastrModule } from "ngx-toastr";
import { ContactUsComponent } from './contact-us/contact-us.component';
import { ErrorStateMatcher, ShowOnDirtyErrorStateMatcher } from "@angular/material/core";
import { UploadLoadingModalComponent } from "./upload-loading-modal/upload-loading-modal.component";
import { SmallSideBarComponent } from './small-side-bar/small-side-bar.component';
import { WavePlayerComponent } from "./wave-player/wave-player.component";
import { ProductsComponent } from './products/products.component';
import { AlspeechProductComponent } from './alspeech-product/alspeech-product.component';
import { TimeAndDayComponent } from './time-and-day/time-and-day.component';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import { HistoryComponent } from './history/history.component';
import { GestionCommunComponent } from './gestion-commun/gestion-commun.component';
import { SharedModule } from "src/shared/shared.Module";
import { AnalyticsComponent } from './analytics/analytics.component';
import { NotificationComponent } from './notification/notification.component';
import { PaymentComponent } from './payment/payment.component';
import { NotAvailableComponent } from './not-available/not-available.component';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import {CalendarModule} from 'primeng/calendar';
import {DropdownModule} from 'primeng/dropdown';
import { EditTranscriptionsPageComponent } from './edit-transcriptions-page/edit-transcriptions-page.component';
import { RecordeDashboardComponent } from './recorde-dashboard/record-dashboard.component';
import { TextRecordComponent } from './text-record/text-record.component';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatRadioModule} from '@angular/material/radio';
import {MatDatepickerModule} from "@angular/material/datepicker";
import {MatFormFieldModule} from "@angular/material/form-field";
import { MatNativeDateModule } from '@angular/material/core';
import {BrowserModule} from '@angular/platform-browser';
import { VideoPlayerComponent } from './video-player/video-player.component';
import { EditModelComponent } from './edit-model/edit-model.component';
import { AudioPlayerComponent } from './audio-player/audio-player.component';
import { HighlighterPipe } from 'src/shared/constants/highlighter.pipe';
import {DragDropModule} from '@angular/cdk/drag-drop';
import { DemoPopupModalComponent } from './demo-popup-modal/demo-popup-modal.component';
import {MatTooltipModule} from '@angular/material/tooltip';
import { VimeModule } from '@vime/angular';

const materialModules = [
  MatButtonModule,
  MatIconModule,
  MatAutocompleteModule,
  MatInputModule,
  MatDialogModule,
  MatMenuModule,
  MatSlideToggleModule,
  MatRadioModule,
  MatDatepickerModule,
  MatFormFieldModule,
  MatNativeDateModule,
  MatSliderModule,
  MatTooltipModule



];
@NgModule({
  declarations: [

    SideBarComponent,
    DashboardLayoutComponent,
    DashboardNavBarComponent,
    DashboardComponent,
    GererFilesComponent,
    FileDescriptionComponent,
    FileUploadModalComponent,
    UploadcomponentComponent,
    ContactUsComponent,
    UploadLoadingModalComponent,
    SmallSideBarComponent,
    WavePlayerComponent,
    ProductsComponent,
    AlspeechProductComponent,
    TimeAndDayComponent,
    HistoryComponent,
    GestionCommunComponent,
    AnalyticsComponent,
    NotificationComponent,
    PaymentComponent,
    NotAvailableComponent,
    EditTranscriptionsPageComponent,
    RecordeDashboardComponent,
    TextRecordComponent,
    VideoPlayerComponent,
    EditModelComponent,
    AudioPlayerComponent,
    HighlighterPipe,
    DemoPopupModalComponent,


  ],
  imports: [

    NgxPaginationModule,
    ToastrModule.forRoot(),
    BrowserModule,
    CommonModule,
    RouterModule,
    MatPaginatorModule,
    NgWaveformModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    ScrollPanelModule,
    TooltipModule,
    SharedModule,
    NgbDropdownModule,
    MDBBootstrapModule.forRoot(),
    CalendarModule,
    ModalModule,
    DropdownModule,
    BrowserModule,
    DragDropModule,
    VimeModule,
    
    ...materialModules,


  ],
    exports: [
        ...materialModules,
        NotAvailableComponent
    ],
  providers: [
    MatDatepickerModule,
    MatNativeDateModule  ,
    {provide: ErrorStateMatcher, useClass: ShowOnDirtyErrorStateMatcher}
  ]

})
export class DashboardModule {}
