import { Component, OnInit } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { User } from "../../shared/models/User";
import { AuthService } from "../../shared/service/auth/auth.service";
import { Router } from "@angular/router";
import { OrganizationService } from "src/shared/service/organization/organization.service";
import { Organization } from "src/shared/models/Organization";
@Component({
  selector: "app-register",
  templateUrl: "./register.component.html",
  styleUrls: ["./register.component.css"],
})
export class RegisterComponent implements OnInit {
  registerForm: FormGroup;
  isSamePassword: boolean = true;
  submitted: boolean = false;
  errorMsgFlag: boolean = false;
  errorMsg: string = "";
  organizationsList: Organization[] = [];
  filteredOptions; //: Observable<Organization[]>;
  organization;
  constructor(
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private organizationService: OrganizationService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.initForm();
    this.getAllOrganizations();
  }
  initForm() {
    this.registerForm = this.formBuilder.group(
      {
        password: ["", [Validators.required, Validators.minLength(8)]],
        email: ["", [Validators.required, Validators.email]],
        first_name: ["", Validators.required],
        last_name: ["", Validators.required],
        confirm_password: ["", Validators.required],
        organization: ["", Validators.required],
      },
      {
        validator: mustMatch("password", "confirm_password"),
      }
    );
  }
  onSubmitForm() {
    this.submitted = true;
    const formValue = this.registerForm.value;
    this.isSamePassword = true;
    const newUser = new User(
      formValue["password"],
      formValue["email"],
      formValue["first_name"],
      formValue["last_name"],
      "user",
      formValue["organization"]
    );
    console.log({ formValue,newUser});
    this.authService.registerUser(newUser).subscribe(
      (data) => {
        this.errorMsgFlag = false;
        console.log({ register: data });
        this.registerForm.reset();
        this.router.navigate(["login"]);
      },
      (error) => {
        this.errorMsgFlag = true;
        console.log({ register: error });
        if (error.status == 403) {
          this.errorMsg = "Email already exists";
        } else {
          this.errorMsg = "Something went wrong ";
        }
      }
    );
  }
  get f() {
    return this.registerForm.controls;
  }
  getAllOrganizations() {
    this.organizationService.getAllOrganizations().subscribe(
      (data) => {
        console.log(data.organizations);
        this.organizationsList = data.organizations;
      },
      (err) => {
        console.log(err);
      }
    );
  }
  private _filter(value: string) {
    const filterValue = value.toLowerCase();
    return this.organizationsList.filter((option) =>
      option.name.toLowerCase().includes(filterValue)
    );
  }

  suggest() {
    this.filteredOptions = this._filter(this.registerForm.value.organization);
  }
}
//helper function
function mustMatch(controlName: string, matchingControlName: string) {
  return (formGroup: FormGroup) => {
    const control = formGroup.controls[controlName];
    const matchingControl = formGroup.controls[matchingControlName];
    if (matchingControl.errors && !matchingControl.errors.mustMatch) {
      // return if another validator has already found an error on the matchingControl
      return;
    }
    if (control.value !== matchingControl.value) {
      matchingControl.setErrors({ mustMatch: true });
    } else {
      matchingControl.setErrors(null);
    }
  };
}
