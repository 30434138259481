import { Component, EventEmitter, Inject, OnInit, Optional, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AuthService } from '../../../shared/service/auth/auth.service';
import { NotificationService } from '../../../shared/service/notification/notification.service';

@Component({
  selector: 'app-modal-confirm-generate-api-key',
  templateUrl: './modal-confirm-generate-api-key.component.html',
  styleUrls: ['./modal-confirm-generate-api-key.component.css']
})
export class ModalConfirmGenerateApiKeyComponent implements OnInit {
  apiKeyResponse: any;



  submitted = false;
  onError = false;
  constructor(public dialogRef: MatDialogRef<ModalConfirmGenerateApiKeyComponent>,
    private notificationService: NotificationService,
    private authUser: AuthService,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
  }



  confirm() {
    if (this.data.isEditMode) {
      this.authUser.updateApiKey(this.data.data).subscribe(response => {
        this.apiKeyResponse = response.user_api_key;
        this.submitted = true;
        this.notificationService.showSuccess('ApiKey Updated successfully');
            this.authUser.reloadApiKeyDataTableBehave(true);

            this.authUser.clearFieldsBehave(true)

      },
        error => {
          this.notificationService.showError('Error occurred , please retry!');
          this.dialogRef.close();
        });
    } else {
      this.authUser.generateApiKey(this.data.data).subscribe(response => {
        this.apiKeyResponse = response.user_api_key;
        this.submitted = true;
        this.notificationService.showSuccess('ApiKey generated successfully');
            this.authUser.reloadApiKeyDataTableBehave(true);

          },
        error => {
          this.notificationService.showError('Error occurred , please retry!');
          this.dialogRef.close();
        });
    }

  }
}
