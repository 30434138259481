import { Component, Inject, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ModalConfirmGenerateApiKeyComponent } from '../modal-confirm-generate-api-key/modal-confirm-generate-api-key.component';
import { UserResponse } from '../../../shared/interfaces/user-response';
import { DOCUMENT } from '@angular/common';
import { AuthService } from 'src/shared/service/auth/auth.service';

@Component({
    selector: 'app-api-key-management',
    templateUrl: './api-key-management.component.html',
    styleUrls: ['./api-key-management.component.css']
})
export class ApiKeyManagementComponent implements OnInit, OnChanges {
    formGroup: FormGroup;
    submitted: boolean;
    apiKeyRequest;
    userSelectedName;
    todayDate: Date = new Date();
    toppings = new FormControl('');
    toppingList: string[] = ['Extra cheese', 'Mushroom', 'Onion', 'Pepperoni', 'Sausage', 'Tomato'];
    @Input() listUsers: UserResponse[];
    @Input() listSkills: any[];
    @Input() apiKey: any;
    apiKeyUser: any = '';
    isEditMode = false;
     myOptions: ({ name: string; id: number })[];

    constructor(private formBuilder: FormBuilder,
        private authUser: AuthService,
        private dialog: MatDialog, @Inject(DOCUMENT) document) {
    }

    get formControls() {
        return this.formGroup.controls;
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.apiKey && changes.apiKey.currentValue) {
            this.isEditMode = true;
            console.log(changes.apiKey.currentValue, 'aa');
            this.userSelectedName = changes.apiKey.currentValue.user_name;
            this.formGroup.patchValue({
                user: changes.apiKey.currentValue.user_id,
                model: changes.apiKey.currentValue.model_name,
                transcriptionLimit: changes.apiKey.currentValue.transcription_limit,
                expiredAfter: changes.apiKey.currentValue.expired_at
            });
            this.apiKeyUser = changes.apiKey.currentValue.api_key;
        }

    }

    ngOnInit(): void {
        this.createForm();
        this.authUser.clearFieldsBehave$().subscribe((data) => {
            if (data) {
                this.resetForm();
                this.isEditMode = false;
            }
        });
        this.myOptions = [
            { id: 1, name: 'Option 1' },
            { id: 2, name: 'Option 2' },
        ];
    }

    createForm() {
        this.formGroup = this.formBuilder.group({
            user: ['', Validators.required],
            model: ['', Validators.required],
            transcriptionLimit: ['', Validators.required],
            expiredAfter: ['', Validators.required]
        });
    }

    onSubmit() {
        this.submitted = true;

        // stop here if form is invalid
        if (this.formGroup.invalid) {
            return;
        }

        const formValue = this.formGroup.value;
        if (this.isEditMode) {
            this.apiKeyRequest = {
                models: formValue['model'],
                transcription_limit: formValue['transcriptionLimit'],
                expired_at: formValue['expiredAfter'],
                user_id: formValue['user'],
                user_name: this.userSelectedName,
                api_key: this.apiKeyUser
            };
        } else {
            this.apiKeyRequest = {
                models: formValue['model'],
                transcriptionLimit: formValue['transcriptionLimit'],
                expiredAt: formValue['expiredAfter'],
                userId: formValue['user'],
                userName: this.userSelectedName,
            };
        }

        this.dialog.open(ModalConfirmGenerateApiKeyComponent, {
            data: {
                isEditMode: this.isEditMode,
                data: this.apiKeyRequest
            },
            disableClose: true
        });


    }

    resetForm() {
        this.submitted = false;
        this.formGroup.reset();
        this.isEditMode = false;
    }

    onChangeUser(event) {
        const userSelected = this.listUsers.find(ele => ele.id === event.target.value);
        this.userSelectedName = userSelected.first_name + ' ' + userSelected.last_name;
    }
}
