import { Component, Input, OnInit, SimpleChange } from '@angular/core';

@Component({
  selector: 'app-transcription-loading',
  templateUrl: './transcription-loading.component.html',
  styleUrls: ['./transcription-loading.component.css']
})
export class TranscriptionLoadingComponent implements OnInit {

  prog: string;
  @Input() progresstranscription :any;
  constructor() {
   
  }

  ngOnInit() {}
  ngOnChanges() {
    console.log({ SpinnerChanges: this.progresstranscription });
  }
}

