import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {TrascriptionServiceService} from '../../../shared/service/transcriptions/trascription-service.service';
import {NotificationService} from '../../../shared/service/notification/notification.service';

@Component({
  selector: 'app-webhook-dashboard',
  templateUrl: './webhook-dashboard.component.html',
  styleUrls: ['./webhook-dashboard.component.css']
})
export class WebhookDashboardComponent implements OnInit {
  formGroup: FormGroup;
  submitted = false;
  selectedSecurityType;
  webhookTestedSuccess = false;
  testIsLoading = false;
  display = 'none';
  saveIsLoading = false;
  webhookObj;
  constructor(private formBuilder: FormBuilder, private transcriptionService: TrascriptionServiceService,
              private notificationService: NotificationService) { }

  ngOnInit(): void {
    this.createForm();
  }
  createForm() {
    this.formGroup = this.formBuilder.group({
      apikey: ['', Validators.required],
      webhookUrl: ['', Validators.required],
      securityType: ['', Validators.required],
      username: [''],
      password: [''],
      apiToken: [''],
      bearer: ['']
    });
  }
  get formControls() {
    return this.formGroup.controls;
  }

  onSubmit() {
    this.webhookTestedSuccess = false;
    this.submitted = true;

    if (this.formGroup.invalid) {
     return;
    }
    this.testIsLoading = true;
    const formValue = this.formGroup.value;
    this.webhookObj = {
      apikey: formValue['apikey'],
      webhookUrl: formValue['webhookUrl'],
      securityType: formValue['securityType'],
      username: formValue['username'],
      password: formValue['password'],
      apiToken: formValue['apiToken'],
      bearer: formValue['bearer']
    };

    this.transcriptionService.saveWebhookUrl(this.webhookObj, true).subscribe(data => {
      if (data.status_code === 200) {
          this.webhookTestedSuccess = true;
          this.notificationService.showSuccess('Webhook url tested Successfully !!');
        this.display = 'block';


      } else if (data.status_code === 404) {
        this.notificationService.showError('The webhook url response not found!');
      } else if (data.status_code >= 400 && data.status_code < 500) {
        this.notificationService.showError('Please verify the body request');

      }
      this.testIsLoading = false;

    }, error => {
      this.notificationService.showError(error.status === 500 ?
          'Verify your url and credentials' : error.status === 410 ?
              error.error.cause : error.error.detail);
      this.testIsLoading = false;

    });
  }

  onChange(event) {
    this.selectedSecurityType = event.target.value;
    if (this.selectedSecurityType === 'BASIC') {
      this.formGroup.controls['username'].setValidators(Validators.required);
      this.formGroup.controls['password'].setValidators(Validators.required);
      this.clearValidationFormControl('bearer');
      this.clearValidationFormControl('apiToken');
    } else if (this.selectedSecurityType === 'BEARER') {
      this.formGroup.controls['bearer'].setValidators(Validators.required);
      //
      this.clearValidationFormControl('username');
      this.clearValidationFormControl('password');
      this.clearValidationFormControl('apiToken');

    } else if (this.selectedSecurityType === 'APITOKEN') {
      this.formGroup.controls['apiToken'].setValidators(Validators.required);
      //
      this.clearValidationFormControl('bearer');
      this.clearValidationFormControl('username');
      this.clearValidationFormControl('password');
    } else {
      this.clearValidationFormControl('apiToken');
      this.clearValidationFormControl('bearer');
      this.clearValidationFormControl('username');
      this.clearValidationFormControl('password');
    }
    this.formGroup.updateValueAndValidity();

  }

  resetForm() {
    this.submitted = false;
    this.selectedSecurityType = undefined;
    this.formGroup.reset();
  }

  onCloseHandled() {
    this.display = 'none';
  }

  clearValidationFormControl(formControl: string) {
  this.formGroup.controls[formControl].clearValidators();
  this.formGroup.controls[formControl].setErrors(null);
  this.formGroup.controls[formControl].updateValueAndValidity();
}

  saveWebhook() {
    this.saveIsLoading = true;
    this.transcriptionService.saveWebhookUrl(this.webhookObj, false).subscribe(response => {

      this.webhookTestedSuccess = true;
      this.notificationService.showSuccess(response.message);
      this.saveIsLoading = false;
      this.resetForm();
      this.onCloseHandled();

    }, error => {
      this.notificationService.showError('Please verify and retry');
      this.onCloseHandled();
      this.saveIsLoading = false;
    });
  }
}
