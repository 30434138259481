import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import CryptoJS from 'crypto-js';

import {JwtJsonData, LoginCredentials} from '../../models/User';
import {Router} from '@angular/router';
import {environment} from '../../../environments/environment';
import {BehaviorSubject, Observable} from 'rxjs';

const BFF_URL = environment.BFF_KEY || 'http://localhost:8000';

@Injectable({
    providedIn: 'root',
})
export class AuthService {
    keyCrypto = environment.CRYPTO_KEY;
    private confirmUserCreate: BehaviorSubject<any> = new BehaviorSubject<any>(false);
    private apiKeychanges: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    private clearFieldsChanges: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);


    JwtJsonData: JwtJsonData;
    xhr = new XMLHttpRequest();

    constructor(private http: HttpClient, private router: Router) {
        this.xhr.withCredentials = true;
        this.xhr.addEventListener('readystatechange', function () {
            if (this.readyState === 4) {
                console.log(this.responseText);
            }
        });
    }

    registerUser(user) {
        console.log({'before encryption': user});
        return this.http.post(`${BFF_URL}/users/sign-up`, this.encrypt(user), {
            reportProgress: true,
            observe: 'response',
            headers: {'Access-Control-Allow-Origin': '*'},
        });
    }

    loginUser(loginCredentials: LoginCredentials) {
        // @ts-ignore
        return this.http.post(`${BFF_URL}/users/login`, this.encrypt(loginCredentials), {
            reportProgress: true,

            observe: 'response',
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
            },
        });
    }

    encrypt(value) {
        const key = CryptoJS.enc.Utf8.parse(this.keyCrypto);
        return CryptoJS.AES.encrypt(JSON.stringify(value), key, {iv: key}).toString();
    }

    loggedIn() {
        return !!localStorage.getItem('token');
    }

    getJwtData() {
        const token = localStorage.getItem('token');
        const jwtData = token.split('.')[1];
        const decodedJwtJsonData = window.atob(jwtData);
        this.JwtJsonData = JSON.parse(decodedJwtJsonData);
        return this.JwtJsonData;
    }

    logout() {
        localStorage.removeItem('token');
        localStorage.removeItem('itemPerPage');
        localStorage.removeItem('selecteditem');

        this.router.navigate(['']);
    }

    getUserInfo(id) {
        return this.http.get(`${BFF_URL}/users/${id}`, {
            headers: {'Access-Control-Allow-Origin': '*'},
        });
    }

    getAllUsers(): Observable<any> {
        return this.http.get<any>(`${BFF_URL}/users/all-users`, {
            headers: {'Access-Control-Allow-Origin': '*'},
        });
    }

    editUserInfo(editedUser, id) {
        return this.http.put(
            `${BFF_URL}/update_user?query_param=${id}`,
            editedUser,
            {
                headers: {'Access-Control-Allow-Origin': '*'},
            }
        );
    }

    getJwtToken() {
        return localStorage.getItem('token');
    }

    setJwtToken(token) {
        return localStorage.setItem('token', token);
    }

    isTokenExpired(token) {

        const expiry = JSON.parse(atob(token.split('.')[1])).exp;
        return Math.floor(new Date().getTime() / 1000) >= expiry;
    }

    getRefreshToken() {
        return localStorage.getItem('refresh_token');
    }


    refreshToken(refresh_token) {
        return this.http.get(`${BFF_URL}/users/refresh-token?token=${refresh_token}`, {
            reportProgress: true,
            observe: 'response',
            headers: {'Access-Control-Allow-Origin': '*', skip: 'true'},
        });
    }

    confirmCreateUser$() {
        return this.confirmUserCreate.asObservable();
    }
    confirmCreateUserValue(confirm) {
        return this.confirmUserCreate.next(confirm);
    }

    generateApiKey(apiKeyRequest: any): Observable<any> {
        return this.http.post(`${BFF_URL}/api/v1/apikey`, apiKeyRequest);
    }
    updateApiKey(apiKeyRequest: any): Observable<any> {
        return this.http.put(`${BFF_URL}/api/v1/apikey`, apiKeyRequest);
    }

    getAllApiKeys(): Observable<any> {
        return this.http.get(`${BFF_URL}/api/v1/all/apikey`, {
            headers: {'Access-Control-Allow-Origin': '*'},
        });
    }
    deleteApiKey(apiKey: string): Observable<any> {
        return this.http.delete<any>(`${BFF_URL}/api/v1/apikey`, {
            headers: {
                'Access-Control-Allow-Origin': '*',
                'api-key': apiKey,
            }
        });
    }


  reloadApiKeyDataTableBehave$() {
    return this.apiKeychanges.asObservable();
  }

  reloadApiKeyDataTableBehave(item) {
    return this.apiKeychanges.next(item);
  }

  clearFieldsBehave$() {
    return this.clearFieldsChanges.asObservable();
  }

  clearFieldsBehave(item) {
    return this.clearFieldsChanges.next(item);
  }
}
