import {Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild, ViewEncapsulation} from '@angular/core';
import {NgWaveformComponent} from 'ng-waveform';
import {FileUploadService} from 'src/shared/service/file-upload.service';
import {fileDetailsModeltext} from '../../../../shared/models/fileDetailsModeltext';
import * as moment from 'moment';
import {UserInfoService} from '../../../../shared/service/user-info/user-info.service';

interface Model {
    value: string;
}

declare var require: any;

@Component({
    selector: 'app-transcription',
    templateUrl: './transcription.component.html',
    styleUrls: ['./transcription.component.scss'],
    encapsulation: ViewEncapsulation.None,


})
export class TranscriptionComponent implements OnInit, OnChanges {
    @ViewChild('waveform') waveform: NgWaveformComponent;
    clickplay = false;
    uploading = false;
    audioStart: any;
    models: Model[] = [
        {value: 'model_kaldiASR.English'},
        {value: 'model_kaldiASR.English'},
    ];
    statuetextopen = 'textclicked';
    statuejsonopen = 'textnotclicked';
    filetestwav: any = 'src\shared\audio\test.wav';
    start = true;
    timer: number;fileUploaded$
    timerprogress: any;
    urlvideo: any = '';
    @Input() file: any;
    scrollableContainer: HTMLElement;
    responsetranscription: any = '';
    showJsonFormat;
    wordstohighlight: any;
    currentStyles: {};
    colorit = false;
    onpauseaudio = false;
    playaudiotest: boolean;
    ishttpLoaded = false;

    texttotest: any;
    errorMessage = ';';
    speech = '';
    checkedmodeltotext = true;
    testingjson: any;
    progresstranscription: any;
    progress: any;
    fileDetailsModeltext = new fileDetailsModeltext();
    blobUrlofaudio: any;
    statusClasssimple3 = 'active';
    ngformstart = false;
    @ViewChild('scrollPanel', {static: false}) scrollPanel: any;
    @Output() statusClasssimple3event = new EventEmitter<any>();
    @ViewChild('canvas', {static: true})
    uploadstart = 'startNotactive';
    transcriptioncompleted = false;
    @Output() transcriptioncompletedevent = new EventEmitter<any>();
    canvas: ElementRef<HTMLCanvasElement>;
    @Output() progresseventtranscription = new EventEmitter<any>();
    @Input() selectedValue;
    EndToENDtranscription: any = '';
    spanwordclicked: any;
    transcriptionstart = false;
    jsonEndtoEnd: any;
    fileId = '';
    userId = '';
    @Output() transcriptionstartevent = new EventEmitter<any>();
    options = {
        responsive: true,
        maintainAspectRatio: false
    };

    constructor(
        private fileUploadService: FileUploadService,
        private userInfo: UserInfoService
    ) {
    }


    ngOnInit(): void {
        console.log('this is the file of transcription in nginit', this.file);
        if (this.file) {
            this.urlvideo = this.file.urlvideo;
        }
        console.log('this is ng waveform', this.waveform);

        this.EndToENDtranscription = '';
        this.texttotest = '';

        this.transcriptioncompletedevent.emit(this.transcriptioncompleted);
        console.log('this is uploadinf', this.uploading);

        this.fileUploadService.fileUploaded$().subscribe(data => {
              if (data) {
                  console.clear();
                  console.table(data);
                this.fileId = data.file_id;
                this.userId = data.user_id;
              }
            }
        );

    }

    ngOnChanges() {
        console.log('Changes');
        if (this.file) {
            this.urlvideo = this.file.urlvideo;
        }

        console.log('this is file for transcription in ngchange', this.file);
        this.scrollToHighlight();
        console.log('this is ng waveform chhh', this.waveform);

    }

    getColor(statue: boolean): object | undefined {
        if (statue === false) {
            return {'background-color': 'white'};
        } else if (statue === true) {
            return {
                'background-color': '#39A9DA',
                color: 'white',
                'border-radius': '25px',
                'padding-left': '3px',
            };
        }
    }

    getprogress(event: any) {

        console.log('this is progress event', event);
        this.progress = event;
    }


    getaudiotimeron(tag: any) {
        //  this.urlvideo = "../../"
        console.log(tag);
        this.spanwordclicked = tag;

        this.timer = this.spanwordclicked.start;
        console.log('donee', this.waveform.setRegionStart(this.timer));
        console.log('this is region for waveform', this.waveform);
        this.waveform.setRegionStart;
    }

    isLastWord(tag: any) {
        return tag.start <= this.timer && tag.end >= this.timer;
    }

    getuploadstate($event) {
        this.uploading = $event;
        console.log('uploading', this.uploading);
    }

    getstartstate($event) {
        this.start = $event;
        console.log('start', this.start);
    }

    showJson() {
        this.showJsonFormat = true;
        this.statuetextopen = 'textnotclicked';
        this.statuejsonopen = 'textclicked';
    }

    showForm() {
        this.showJsonFormat = false;
        this.statuetextopen = 'textclicked';
        this.statuejsonopen = 'textnotclicked';
    }

    onDurationChange(event: any) {
        console.log('this is duration change', event);
        if (event !== 0) {
            this.ngformstart = true;
        }
        if (this.ngformstart === true) {
            this.uploadstart = 'startactive';
        }
    }

    onPlayButtonClick() {
        this.waveform.play();
    }

    onPauseButtonClick() {
        this.waveform.pause();
    }

    onPlayandOnpauseClick() {
        if (!this.clickplay) {
            this.onPlayButtonClick();
        } else if (this.clickplay) {
            this.onPauseButtonClick();
        }

        this.clickplay = !this.clickplay;
    }

    onUpload1(file: any) {
        this.ishttpLoaded = true;
        console.log('this is file for uploade1', file);


        this.fileDetailsModeltext.file_name = file.name;
        this.fileDetailsModeltext.model_id = this.selectedValue;
        this.fileDetailsModeltext.from_cache = this.checkedmodeltotext;

        this.progresstranscription = 0;
        this.progresseventtranscription.emit(this.progresstranscription);
        this.transcriptionstart = true;
        this.transcriptionstartevent.emit(this.transcriptionstart);
        console.warn('list attribute', this.fileDetailsModeltext.model_id, this.userId, this.fileId);
        const userInfoId = this.userInfo.getCookie('user-info-id');
        this.fileUploadService
            .transcribeNowDemo(this.fileDetailsModeltext.model_id, this.userId, this.fileId, userInfoId)
            .subscribe(
                (data) => {

                     if (data ) {

                            this.transcriptioncompleted = true;
                            this.transcriptioncompletedevent.emit(this.transcriptioncompleted);
                            this.wordstohighlight = data.transcription.words;
                            this.testingjson = JSON.stringify(this.wordstohighlight);
                            this.additemtowortds(this.wordstohighlight, this.colorit);
                            this.texttotest = this.highlight_word(this.timer);
                            this.transcriptionstart = false;
                            this.transcriptionstartevent.emit(this.transcriptionstart);
                        }



                },
                (error) => {
                    // Error callback
                    this.transcriptioncompleted = false;
                    this.transcriptioncompletedevent.emit(this.transcriptioncompleted);
                    this.errorMessage = error;
                }
            );


    }

    highlight_word(time: any) {
        const array: any[] = [];
        const arraystart: any[] = [];
        const arrayend: any[] = [];
        for (let i = 0; i < this.wordstohighlight.length; i++) {
            array[i] = this.wordstohighlight[i];
            arraystart[i] = this.wordstohighlight[i].start;
            arrayend[i] = this.wordstohighlight[i].end;
            if (arraystart[i] <= time && arrayend[i] >= time) {
                this.wordstohighlight[i].statue = true;
                let _lastTime;

                setTimeout(() => {
                    this.wordstohighlight[i].statue = false;
                    _lastTime =
                        this.wordstohighlight[i].end - this.wordstohighlight[i].start;
                }, (this.wordstohighlight[i].end - this.wordstohighlight[i].start) * 1000);

            }
        }

        return array;
    }

    scrollToHighlight() {
        if (!this.scrollPanel) {
            return;
        }

        const el: any = document.querySelector('.highlighted');
        const scrollableContainer = this.getScrollableContent();
        const clientHeight = (el && el.offsetTop) || null;
        if (clientHeight) {
            scrollableContainer.scrollTop = clientHeight;
        }
    }

    getScrollableContent() {
        if (!this.scrollableContainer || !this.scrollableContainer.isConnected) {
            this.scrollableContainer =
                this.scrollPanel.el.nativeElement.querySelector(
                    '.p-scrollpanel-content'
                );
        }

        return this.scrollableContainer;
    }


    additemtowortds(words: Array<any>, success: boolean) {
        for (let i = 0; i < words.length; i++) {
            words[i].statue = success; // Add "success": 2 to all objects in array
        }
    }

    testt(time: any) {
        this.timer = time.srcElement.currentTime;
    }

    testvideo(time: any) {
        this.timer = time.time;
        this.timerprogress = (100 * this.timer / this.waveform.region.end);
        const display = moment().startOf('day')
            .seconds(this.timer)
            .format('H:mm:ss');
        this.audioStart = display;
        this.scrollToHighlight();

    }
}


