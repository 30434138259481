export const environment = {
    production: true,
    // @ts-ignore
    GATEWAY_URL: window.env?.apiUrl || 'https://alspeech.dev.algobrain.ai/api',
    // @ts-ignore
    GATEWAY_URL_DEMO: window.env?.apiUrlDemo || 'https://alspeech.dev.algobrain.ai/demo',
    // @ts-ignore
    CRYPTO_KEY: window.env?.cryptoKey || 'hflORvtIfMsZHiU6',
    // @ts-ignore
    BFF_KEY: window.env?.bffkey || 'https://alspeech.algobrain.ai/bff',
    // @ts-ignore
    LIVE_KEY: window.env?.livekey || 'wss://alspeech.algobrain.ai/bff/wss',
    // @ts-ignore
    DEMO_WS: window.env?.Demows || 'wss://alspeech.algobrain.ai/ws',
    // @ts-ignore
    ENGLISH_DEMO_USER: window.env?.englishModelUser || '833d5278-dc23-4a3b-85c8-85873916402c',
    // @ts-ignore
    ARABIC_DEMO_USER: window.env?.arabicModelUser || '73446d44-9e40-42b1-96ff-c981f129be5f',
    // @ts-ignore
    FRENCH_DEMO_USER: window.env?.frenchModelUser || '2157bab5-5807-49b8-82a1-a42ca0e552b2'
};
