import { Component, OnChanges, OnInit, SimpleChanges } from "@angular/core";
import { SideBarEventService } from "src/shared/service/sideBarEvent/side-bar-event.service";

@Component({
  selector: "app-dashboard-layout",
  templateUrl: "./dashboard-layout.component.html",
  styleUrls: ["./dashboard-layout.component.css"],
})
export class DashboardLayoutComponent implements OnInit, OnChanges {
  showMyContainer: boolean = true;
  toggleSide = true;
  editPageOpened: any;
  sideBarClass = "side-bar-container";
  contentContainerBar = "content-container";
  constructor(private sideBarService: SideBarEventService) {}
  ngOnChanges(changes: SimpleChanges): void {
    this.sideBarService.stateSideBar$().subscribe((sideBarstate) => {
      this.toggleSide = sideBarstate;
    });
  }

  ngOnInit(): void {
    this.sideBarService.EditTranscription$().subscribe((data) => {
      this.editPageOpened = data;

      if (!this.editPageOpened) {
        this.contentContainerBar = "content-container";
      } else {
        this.contentContainerBar = "content-container-fixed";
      }
      this.getSideBarevent();
    });
    this.sideBarService.stateSideBar$().subscribe((sideBarstate) => {
      this.toggleSide = sideBarstate;
      this.getSideBarevent();
    });
  }

  getSideBarevent() {
    if (!this.toggleSide) {
      this.sideBarClass = "side-bar-container-small";
      this.contentContainerBar = "content-container-small";
    } else if (this.toggleSide) {
      this.sideBarClass = "side-bar-container";
      if (this.editPageOpened == false) {
        this.contentContainerBar = "content-container";
      } else {
        this.contentContainerBar = "content-container-fixed";
      }
    }
  }
}
