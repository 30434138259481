import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
    selector: 'app-api-keys-table',
    templateUrl: './api-keys-table.component.html',
    styleUrls: ['./api-keys-table.component.css']
})
export class ApiKeysTableComponent implements OnInit {
    @Input() APIList: any[];
    @Output() sendApiKey = new EventEmitter<any>();
    @Output() sendApiKeyDelete = new EventEmitter<any>();

    constructor() {
    }

    ngOnInit(): void {
    }

    updateApiKey(apikey: any) {
        scrollTo(0, 0);
        this.sendApiKey.emit(apikey);
    }

    deleteApiKey(apikey: any) {
        this.sendApiKeyDelete.emit(apikey);
    }

}
