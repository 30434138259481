import { Component, OnInit } from '@angular/core';
import {AuthService} from '../../../shared/service/auth/auth.service';
import {ModalService} from '../../../shared/service/modal.service';
import {MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-confirm-modal',
  templateUrl: './confirm-modal.component.html',
  styleUrls: ['./confirm-modal.component.css']
})
export class ConfirmModalComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<ConfirmModalComponent>,
      private authUser: AuthService) { }

  ngOnInit(): void { }

  confirm() {
    this.authUser.confirmCreateUserValue(true);
    this.dialogRef.close();
  }

}
