import { Injectable } from '@angular/core';
import {environment} from '../../../environments/environment.prod';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';

const GATEWAY_URL = environment.GATEWAY_URL_DEMO;
@Injectable({
  providedIn: 'root'
})
export class DemoTranscriptionsService {
  httpOptions = {
    headers: new HttpHeaders({'Access-Control-Allow-Origin': '*'}),
  };

  constructor(private http: HttpClient) {
  }
  getModelsForLiveDecoding() {
    return this.http.get<Map<string, any>>(`${GATEWAY_URL}/available_live_decoders`);
  }
  getAllDemoTranscriptionsByModel(modelId: string): Observable<any> {
    return this.http.get(`${GATEWAY_URL}/transcriptions-by-model?model-id=${modelId}`);
  }

  getTranscriptionsDemoByUser(userId: string): Observable<any> {
    return this.http.get(`${GATEWAY_URL}/transcription_demo_by_user/${userId}`);
  }
  getDemoStatistic() {
    return this.http.get(`${GATEWAY_URL}/statistics`);
}
}
