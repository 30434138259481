import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import * as moment from 'moment';
import {Observable, Subscription} from 'rxjs';
import {TableInterface} from 'src/shared/interfaces/table-header-cell.interface';
import {AuthService} from 'src/shared/service/auth/auth.service';
import {HistoryService} from 'src/shared/service/history/history.service';
import {NotificationService} from 'src/shared/service/notification/notification.service';
import {TrascriptionServiceService} from 'src/shared/service/transcriptions/trascription-service.service';
import {SearchProductRequest} from '../../../shared/interfaces/SearchProductRequest';

interface State {
    name: string;
    code: string;
}

interface Model {
    code: string;
    name: string;
}

@Component({
    selector: 'app-history',
    templateUrl: './history.component.html',
    styleUrls: ['./history.component.scss'],

})
export class HistoryComponent implements OnInit, OnDestroy, AfterViewInit {
    listofFiles: TableInterface | any = null;
    status: State[];
    models: Model[];
    isLoading = false;
    selectedStateToSearch: any;
    selectedFilterOption = 'file_name';
    user_id: any;
    filteredOptions: Observable<string[]>;
    options: any;
    dataFilter: SearchProductRequest = <SearchProductRequest>{};
    constructor(
        private historyService: HistoryService,
        private authService: AuthService,
        private notificationService: NotificationService,
        private transcriptionService: TrascriptionServiceService,
    ) {

        this.models = [
            {
                code: 'All_Models',
                name: 'Models',
            },
            {
                code: 'Kaldi_Arabic_medium',
                name: 'Live Arabic',
            },
            {
                code: 'Kaldi_English_medium',
                name: 'Live English',
            },
            {
                code: 'Kaldi_French_small',
                name: 'Live French',
            },
            {
                code: 'kaldi_asr_arabic_medium',
                name: 'ASR Arabic model',

            },
            {
                code: 'kaldi_asr_english_medium',
                name: 'ASR English model',

            },
            {
                code: 'kaldi_asr_french_small',
                name: 'ASR French model'

            }
        ];
        this.status = [
            {name: 'Status', code: 'status'},
            {name: 'Uploaded', code: 'Uploaded'},
            {name: 'Transcribed', code: 'Transcribed'},
            {name: 'Error', code: 'Error'},
            {name: 'In progress', code: 'InProgress'},
            {name: 'Submitted', code: 'Submitted'},
        ];

    }

    ngAfterViewInit(): void {
        document.getElementsByClassName('mat-datepicker-toggle-default-icon')[0].classList.add( 'ml-0');
        document.getElementsByClassName('mat-form-field-infix')[0].classList.add( 'pt-0');
        document.getElementsByClassName('mat-form-field-wrapper')[0].classList.add( 'pb-0');
        document.getElementsByClassName('mat-date-range-input-container')[0].classList.add('justify-content-end');
    }

    onChangeStatus(deviceValue) {
        this.dataFilter.status = deviceValue.target.value === 'status' ? undefined : deviceValue.target.value;
        this.dataFilter.offset = 0;
        this.searchByFilter();
    }

    ngOnDestroy(): void {
        this.listofFiles = [];
    }


    ngOnInit(): void {
        this.listofFiles = [];
        this.status = [
            {name: 'Status', code: 'status'},
            {name: 'Uploaded', code: 'Uploaded'},
            {name: 'Transcribed', code: 'Transcribed'},
            {name: 'Error', code: 'Error'},
            {name: 'In progress', code: 'InProgress'},
            {name: 'Submitted', code: 'Submitted'},
        ];

        this.user_id = this.getUser();

        this.historyService.isSortingState$().subscribe(data => {
            this.dataFilter.sorting_direction = data[0].sorting_options.sorting_direction;
            this.dataFilter.sorting_column = data[0].sorting_options.sorting_column;
            this.dataFilter.limit = data[0].limit;
            this.dataFilter.offset = data[0].offset;

            this.searchByFilter();
        });


        this.notificationService.pageHeaderContent('History');
        this.notificationService.showConsulting('History has been checked ');
        this.getSearchResults();
      //  this.searchByFilter();


    }

    getSelectedValueAutoCopmplete(event) {
        console.log('test selected value', event);
        this.dataFilter.file_name = event.source.value;
        this.dataFilter.offset = 0;
        this.searchByFilter();


    }

    getSearchResults(): void {
        this.transcriptionService.getFileNamesAutoComplete(this.user_id, this.dataFilter.file_name, true).subscribe((sr) => {
                this.filteredOptions = sr.body;
                console.log({sr: sr.body});
            }
        );
    }

    startChange(event: any) {
        this.dataFilter.date_range = [null, null];
        this.dataFilter.date_range[0] = moment(event.value).format('DD/MM/YYYY') === 'Invalid date' ? null : moment(event.value).format('DD/MM/YYYY');
        console.log('startChange', this.dataFilter.date_range[0]);
    }

    endChange(event: any) {
        (<HTMLElement>document.querySelector('mat-date-range-input')).style.display = 'block';
         document.getElementsByClassName('mat-form-field-type-mat-date-range-input')[0].classList.add('date-picker-inputs');

        this.dataFilter.date_range[1] = moment(event.value).format('DD/MM/YYYY') === 'Invalid date' ? null : moment(event.value).format('DD/MM/YYYY');
        this.dataFilter.offset = 0;
        this.searchByFilter();
    }

    searchByFileName() {
        this.getSearchResults();
        this.dataFilter.offset = 0;
        this.searchByFilter();
    }

    searchByFilter() {

        if (this.dataFilter.date_range && (this.dataFilter.date_range[0] == null || this.dataFilter.date_range[1] == null) ) {
            this.dataFilter.date_range = null;
        }
        this.historyService.searchHistory(this.user_id, this.dataFilter).subscribe(data => {
            console.log('this is data for user filtered', data);
            if (data.status === 200) {
                this.isLoading = true;
                this.listofFiles = JSON.parse(JSON.stringify(data.body));
                document.getElementById('custom-container').style.backgroundColor = 'white';

            }
        });
    }
    getUser() {
        let user_id = '';
        if (this.authService.loggedIn()) {
            const jwtData = this.authService.getJwtData();
            user_id = jwtData.user_id;
        }
        return user_id;
    }

    onChangeModel(event) {
        this.dataFilter.skill_id = event.target.value === 'All_Models' ? undefined : event.target.value;
        this.dataFilter.offset = 0;
        this.searchByFilter();
    }

}
