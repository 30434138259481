import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-alspeech-demo',
  templateUrl: './alspeech-demo.component.html',
  styleUrls: ['./alspeech-demo.component.css'],
  encapsulation:ViewEncapsulation.None
})
export class AlspeechDemoComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
