import {HttpClient, HttpResponse, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class AnalyticsService {

    BFF_URL = environment.BFF_KEY || 'http://localhost:8000';

    constructor(private client: HttpClient) {}

    searchChart(request): Observable<HttpResponse<any>> {
        return this.client.post(`${this.BFF_URL}analytics/chart`, request,  {
            observe: 'response',
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    getCharts(userId, dateRange): Observable<HttpResponse<any>> {
        console.log("user", userId);
        console.log("dateRange", dateRange);
        let params = new HttpParams().set('userId', userId).set('dateRange', dateRange);
        return this.client.get(`${this.BFF_URL}/alspeech-analytics/charts`,  {
            observe: 'response',
            headers: {
                'Content-Type': 'application/json'
            },
            params: params
        });
    }

}
