import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-not-available',
  templateUrl: './not-available.component.html',
  styleUrls: ['./not-available.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class NotAvailableComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
