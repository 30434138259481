import {Component, ElementRef, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import {DictateService} from 'src/shared/service/dictate-service';
import * as MicrophonePlugin from 'wavesurfer.js/dist/plugin/wavesurfer.microphone.js';
import WaveSurfer from 'wavesurfer.js';
import * as moment from 'moment';
import {ToastrService} from 'ngx-toastr';
import {UserInfoService} from '../../../../shared/service/user-info/user-info.service';
import {environment} from 'src/environments/environment';

const DEMO_WS = environment.DEMO_WS;

@Component({
    selector: 'app-record-vask',
    templateUrl: './record-vask.component.html',
    styleUrls: ['./record-vask.component.scss'],
    providers: [DictateService],
})
export class RecordVaskComponent implements OnInit, OnChanges, OnDestroy {
    @ViewChild('waveformtest', {static: false}) wavesurfer: any;

    @Input() selectedValue;
    @Input() selectedmodelchanged;
    statuerecord = 'boutonnotrecord';
    microOn = 'false';
    textDataBase = '';
    textData = '';
    AudioContext: any;
    webkitAudioContext: any;
    mediaRecorder: any;
    recordingBlob: any;
    time = 180;
    timestart: any;
    interval: any;
    canceled = false;
    // URL of Blob
    url;
    userInfo: any;
    @ViewChild('results') private results: ElementRef;
    private userInfoId: string;

    constructor(
        private dictateService: DictateService,
        private domSanitizer: DomSanitizer,
        private toester: ToastrService,
        private userInfoService: UserInfoService
    ) {
    }

    ngOnDestroy(): void {
        console.log('test');
    }

    ngOnChanges(changes: SimpleChanges): void {
        console.log('changed or not', this.dictateService.isInitialized());
        if (this.selectedmodelchanged && this.dictateService.isInitialized()) {
            this.reset();
        }
    }

    ngOnInit(): void {
        console.log('je suis la');
        const display = moment().startOf('day').seconds(this.time).format('H:mm:ss');
        console.log('diplay', display);
        this.statuerecord = 'boutonnotrecord';

        this.timestart = display;
    }


    getWaveContainer() {
        return this.wavesurfer.container;
    }

    stop() {
        this.wavesurfer.microphone.stop();
        this.statuerecord = 'boutonnotrecord';
        this.clearWaveContainer();
    }

    clearWaveContainer() {
        this.getWaveContainer().innerHTML = '';
    }

    start() {
        this.statuerecord = 'boutonrecord';
        const AudioContext = window.AudioContext;
        const context = new AudioContext();
        const processor = context.createScriptProcessor(1024, 1, 1);
        this.wavesurfer = WaveSurfer.create({
            container: '#waveformtest',
            waveColor: '#242e3a',

            barHeight: 3,

            interact: false,
            cursorWidth: 0,
            audioContext: context || null,
            audioScriptProcessor: processor || null,
            plugins: [
                MicrophonePlugin.create({
                    bufferSize: 4096,
                    numberOfInputChannels: 1,
                    numberOfOutputChannels: 1,
                    constraints: {
                        video: false,
                        audio: true,
                    },
                }),
            ],
        });

        this.wavesurfer.microphone.on('deviceReady', function () {
        });
        this.wavesurfer.microphone.on('deviceError', function (code) {
            console.warn('Device error: ' + code);
        });
        this.wavesurfer.microphone.start();
    }

    startTimer() {

        this.interval = setInterval(() => {
            this.time--;
            console.log('timing', this.time);
            const display = moment()
                .startOf('day')
                .seconds(this.time)
                .format('H:mm:ss');
            if (this.time === 0) {

                this.cancelRecordinng();
                this.toester.info('Time is up ! Live decoding time is limited to 3 minutes');
            }
            console.log('diplay', display);
            this.timestart = display;
        }, 1000);
    }

    pauseTimer() {
        clearInterval(this.interval);
    }

    endTimer() {
        clearInterval(this.interval);

        this.time = 180;
        const display = moment().startOf('day').seconds(this.time).format('H:mm:ss');
        console.log('diplay', display);
        this.timestart = display;
    }

    switchSpeechRecognition() {
      if (!this.dictateService.isInitialized()) {
        this.userInfoId = this.userInfoService.getCookie('user-info-id');
            if ( !this.userInfoId) {
                this.userInfoService.getUserInfo().subscribe((resp: any) => {
                    const info = {
                        ip_address: resp.ip,
                        browser: UserInfoService.getBrowser(),
                        browser_version: this.userInfoService.getBrowserVersion(),
                        country: resp.country_name,
                        country_code: resp.country_code,
                        timeZone: resp.time_zone,
                        regionCode: resp.region_code,
                        regionName: resp.region_name,
                        latitude: resp.latitude,
                        longitude: resp.longitude,
                        isLive: true,
                        decoding_model_id: this.selectedValue
                    };
                        this.userInfoService.sendUserInfo(info).subscribe(datas => {
                            this.userInfo = datas;
                            this.userInfoId = this.userInfo.id;
                            this.userInfoService.setCookie('user-info-id', this.userInfoId, 30);
                            this.startWebSocket();
                        });
                });
            } else {
                this.startWebSocket();
            }


      } else if (this.dictateService.isRunning()) {
        this.dictateService.resume();
        this.start();
        this.startTimer();
        this.microOn = 'true';
      } else {
        this.dictateService.pause();
        this.stop();
        this.statuerecord = 'boutonnotrecord';
        this.pauseTimer();
      }

    }

    cancelRecordinng() {
        this.canceled = true;
        this.dictateService.pause();
        this.dictateService.cancel();
        this.stop();
        this.statuerecord = 'boutonnotrecord';
        this.microOn = 'false';
        this.endTimer();
        this.time = 180;

    }

    reset() {
        if (this.dictateService.isInitialized()) {
            this.cancelRecordinng();
        }
        this.textDataBase = '';
        this.textData = '';

    }

    startWebSocket() {
        this.startTimer();
        this.start();
        this.dictateService.init({
            server:
                `${DEMO_WS}/ws_audio/${this.selectedValue}?user_info_id=${this.userInfoId}`,
            onResults: (hyp) => {
                this.textDataBase = this.textDataBase + hyp + '\n';
                this.textData = this.textDataBase;
                this.results.nativeElement.scrollTop =
                    this.results.nativeElement.scrollHeight;
                this.statuerecord = 'boutonrecord';
            },
            onPartialResults: (hyp) => {
                this.textData = this.textDataBase + hyp;
            },
            onError: (code, data) => {
                console.log(code, data);
            },
            onEvent: (code, data) => {
                if (code === 5) {
                    console.log('test for code 5', data);
                }
            },
        });
        this.microOn = 'true';
    }

}
