import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild,
    ViewEncapsulation,
} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import * as moment from 'moment';
import {Subscription} from 'rxjs';
import {AuthService} from 'src/shared/service/auth/auth.service';
import {ModalService} from 'src/shared/service/modal.service';
import {TrascriptionServiceService} from 'src/shared/service/transcriptions/trascription-service.service';
import {environment} from '../../../environments/environment.prod';

const BFF_URL = environment.BFF_KEY || 'http://localhost:8000';

@Component({
    selector: 'app-demo-popup-modal',
    templateUrl: './demo-popup-modal.component.html',
    styleUrls: ['./demo-popup-modal.component.css'],
    encapsulation: ViewEncapsulation.None,

})
export class DemoPopupModalComponent implements OnInit, OnChanges, OnDestroy {
    @ViewChild('target', {static: true}) target: ElementRef;


    @ViewChild('waveform', {static: false}) waveform: any;
    @ViewChild('testAudior', {static: false}) audioPlayer: ElementRef;
    speackers: any;
    urlvideo: any;
    texttotest: any;
    wordstohighlight: any;
    statuetextopen = 'textclicked';
    statuejsonopen = 'textnotclicked';
    start = true;
    timer: number;
    timerprogress: any;
    file: any;
    scrollableContainer: HTMLElement;
    responsetranscription: any = '';
    showJsonFormat;
    colorit = false;

    selectedValue: any = {};
    errorMessage = ';';
    speech = '';
    cellcolor = 'primary';
    checkedmodeltotext = true;
    testingjson: any;
    audiook = false;
    progress: any;
    urlAudioObs;
    @ViewChild('scrollPanel', {static: false}) scrollPanel: any;
    @Output() statusClasssimple1event = new EventEmitter<any>();
    @ViewChild('canvas', {static: true})
    isCheckedColor = false;
    spanwordclicked: any;
    ngformstart = false;
    modaluploadSelected = false;
    user_id: any;
    audioStart: any;
    audioEnd: any;
    @Input() id: string;
    @Input() element: any;
    getTranscription: Subscription;
    modalIsOpen: boolean;
    statusClasssimple1 = 'active';
    uploadstart = 'startNotactive';
    clickplay = false;
    uploading = false;
    testAudio: any;
    @Input() FileForModal;
    testColor;
    transcription_id;
    id_file_selected;
    name_file_selected;
    name_File: string;
    elementPressed: any;
    @Input() blobUrlofaudio;
    urlAudio;
    endoffile;
    confCode: any;
    speackerData: any;
    datashow: any;
    speakerCheked: boolean;
    extension: any;
    videoVtt: any = '';
    uriTranscription: any;

    constructor(
        private transcriptionService: TrascriptionServiceService,
        public authService: AuthService,
        private modalService: ModalService,
        private sanitizer: DomSanitizer,
    ) {
    }

    ngOnDestroy(): void {
        this.videoVtt = '';
        this.urlAudio = '';
        if (this.getTranscription) {
            this.getTranscription.unsubscribe();
        }
        if (this.urlAudioObs) {
            this.urlAudioObs.unsubscribe();
        }

        this.urlvideo = '';
        this.uploadstart = '';
        //  this.id_file_selected=''
        this.uploadstart = 'startNotactive';
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.testAudio.ontimeupdate = function (time) {
            console.log('time', time);
        };

        console.log('audio url', this.testAudio);
        this.testAudio.addEventListener('timeupdate', (currentTime) => {
            console.log('currenttime', currentTime);
        });
        this.testAudio.addEventListener(
            'timeupdate',
            function () {
              const currentTimeMs = this.testAudio.currentTime * 1000;
              console.log(currentTimeMs);
            },
            false
        );
        this.scrollToHighlight();

        console.log(this.urlvideo);
        const elem = document.querySelector('testAudior');
        console.log('elem', elem);


    }

    ngOnInit(): void {
        const elem = document.querySelector('testAudior');
        console.log('elem', elem);

        this.user_id = this.getUser();


        this.modalService.fileInoformation$().subscribe((test) => {
            console.log('thgis is informatuon from test', test);
            this.speakerCheked = test[3];
            this.transcription_id = test[1];
            this.id_file_selected = test[0];
            this.name_file_selected = test[2];
            this.extension = test[4];
            this.uriTranscription = test[5];
        });
        console.log('extension', this.extension);
        this.transcriptionService.isUrlAudio$().subscribe((url) => {
            this.urlAudio = url;
        });
        this.urlvideo = '';
        this.ngformstart = false;
        this.uploadstart = 'startNotactive';

        this.modalService.urlAudio$().subscribe(data => {
            if (data) {
                this.urlvideo = data;
            }
        });

        this.playAudio();
        this.name_File = this.truncate(this.name_file_selected, 70);

        console.log(this.transcription_id);

        this.scrollToHighlight();
        this.transcriptionService.isUrl().subscribe((url) => {
            this.elementPressed = url;
        });
        this.modalService.isOpen$().subscribe((isOpen) => {
            this.modalIsOpen = isOpen;
        });


        this.getTranscriptionById(this.transcription_id);

        console.log('url video', this.urlvideo);
        this.testAudio = document.getElementById('testAudior') as HTMLElement;
        console.log('audio url', this.testAudio);

        if (this.elementPressed && this.modalIsOpen) {
            this.ngformstart = false;
            this.testColor = '#fffff';
            this.urlvideo = '';
            this.uploadstart = 'startNotactive';

        }


    }

    getSubtitleForVideo() {
        console.log('fffff', this.videoVtt);

        this.download_Transcription_By_Id(this.transcription_id, 'vtt');
    }


    getKeyFromResponse(data) {
        this.speackerData = [];
        Object.keys(data).forEach((key) => {
            this.speackerData.push({
                spk: data[key].speaker_id,
                speakers: data[key].words,
            });
        });
        // this.wordstohighlight = this.speackerData.speakers
        console.log('erere', this.wordstohighlight);
        console.log('erere', this.speackerData[0].speakers);
        return this.speackerData;
    }

    getKEy(data) {
        this.speackers = [];
        Object.keys(data).forEach((key) => {
            this.speackers.push({
                speackers: data[key],
            });
        });
        console.log('"""""""', this.speackers);
        return this.speackers;
    }

    getUser() {
        let user_id = 'test';
        if (this.authService.loggedIn()) {
            const jwtData = this.authService.getJwtData();
            user_id = jwtData.user_id;
        }
        return user_id;
    }

    download_Transcription_By_Id(
        transcription_id: any,
        type: any
    ) {
        this.transcriptionService
            .download_Transcription_By_Id(transcription_id, type)
            .subscribe(
                (response) => {
                    console.log('test the response', response);
                    const binaryData = [];
                    binaryData.push(response);

                    let downloadLink;
                    downloadLink =
                        new Blob(binaryData, {type: 'text/plain; charset=utf-8'});
                    let a;
                    a = this.sanitizer.bypassSecurityTrustUrl(
                        URL.createObjectURL(downloadLink)
                    );
                    this.videoVtt = a;

                    console.log('test video tt ', this.videoVtt);
                },
                (error) => {
                    console.log('there is no Transcription');
                });
    }

    download_Transcription_By_Id_vtt(
        type: any
    ) {
        this.transcriptionService
            .download_Transcription_By_Id(this.transcription_id, type)
            .subscribe((response) => {
                console.log('test the response', response);
                const dataType = response.type;
                const binaryData = [];
                binaryData.push(response);
                const downloadLink = document.createElement('a');
                downloadLink.href = window.URL.createObjectURL(
                    new Blob(binaryData, {type: dataType})
                );
                downloadLink.setAttribute(
                    'download',
                    this.name_file_selected + '.' + type
                );
                document.body.appendChild(downloadLink);
                downloadLink.click();
            });
    }

    playAudio() {
        console.log('test urlk video', this.urlvideo);
        if (this.urlvideo) {
            setTimeout(() => {
                this.audiook = true;
                this.testAudio = <HTMLAudioElement>(
                    document.getElementById('testAudior')
                );
                this.testAudio.addEventListener('timeupdate', (currentTime) => {
                    console.log('currenttime', this.testAudio.currentTime);
                    this.timer = this.testAudio.currentTime;
                    console.log('currenttime', currentTime.timeStamp / 1000);
                });

            }, 1000);
        }
        if (this.audiook) {
            this.testAudio = <HTMLAudioElement>document.getElementById('testAudior');

        }

        const elem = document.querySelector('testAudior');
        console.log('elem', elem);

        this.testAudio = <HTMLAudioElement>document.getElementById('testAudior');
        console.log('show the audio url', this.testAudio);
    }

    getTranscriptionById(id_transcription) {
        const fromCache = (this.uriTranscription === undefined || this.uriTranscription === null);
        this.transcriptionService
            .Get_Transcription_By_Id(id_transcription, false, this.uriTranscription)
            .subscribe((response) => {
                if (response == null) {
                    this.texttotest = '';
                } else {
                    console.log({test: response});
                    if (response.transcription) {
                        this.wordstohighlight = response.transcription.words;
                    }
                    console.log({test: this.wordstohighlight});
                    this.testingjson = JSON.stringify(this.wordstohighlight);
                    this.additemtowortds(this.wordstohighlight, this.colorit);
                    this.texttotest = 'eeezze';
                    this.texttotest = this.highlight_word(this.timer);
                }
            });
    }

    ngDoCheck() {
        this.scrollToHighlight();
    }

    getColor(statue: boolean): object | undefined {
        if (!statue) {
            return {'background-color': ''};
        } else if (statue) {
            return {
                'text-decoration': this.getAudioConf(this.confCode),
                'text-underline-offset': '3px',
                'text-decoration-thickness': '4px',
                'background-color': this.getAudioConf(this.confCode),
                color: 'white',
                'border-radius': '5px',
                'padding-left': '3px',
            };
        }
    }

    getAudioConf(confCode) {
        if (this.isCheckedColor) {
            if (confCode >= 0.6) {
                return '  #8dcfaa'; // vert
            } else if (0.4 <= confCode && confCode < 0.6) {
                return '  #f88e55'; // jaune
            } else if (confCode < 0.4) {
                return '  red'; // red
            }
        } else {
            return '#39A9DA';
        }
    }


    getaudiotimeron(tag: any) {

        this.spanwordclicked = tag;

        this.timer = this.spanwordclicked.start;
        // console.log("donee", this.waveform.setRegionStart(this.timer));
        this.testAudio.currentTime = this.timer;
        //  this.waveform.setRegionStart;
    }

    isLastWord(tag: any) {
        this.confCode = tag.conf;
        return tag.start <= this.timer && tag.end >= this.timer;
    }

    getTimeForAudioTag() {
    }

    showJson() {
        this.showJsonFormat = true;
        this.statuetextopen = 'textnotclicked';
        this.statuejsonopen = 'textclicked';
        this.scrollToHighlight();
    }

    showForm() {
        this.showJsonFormat = false;
        this.statuetextopen = 'textclicked';
        this.statuejsonopen = 'textnotclicked';
        this.scrollToHighlight();
    }


    onPlayButtonClick() {
        this.waveform.play();
    }

    onPauseButtonClick() {
        this.waveform.pause();
    }

    onPlayandOnpauseClick() {
        if (!this.clickplay) {
            this.onPlayButtonClick();
        } else {
            this.onPauseButtonClick();
        }
        this.clickplay = !this.clickplay;
    }

    highlight_word(time: any) {
        const array: any[] = [];
        const arraystart: any[] = [];
        const arrayend: any[] = [];
        if (this.speakerCheked) {
            this.wordstohighlight = this.speackerData;
        }


        for (let i = 0; i < this.wordstohighlight.length; i++) {

            array[i] = this.wordstohighlight[i];
            arraystart[i] = this.wordstohighlight[i].start;
            arrayend[i] = this.wordstohighlight[i].end;
            if (arraystart[i] <= time && arrayend[i] >= time) {
                this.wordstohighlight[i].statue = true;
                let _lastTime;

                setTimeout(() => {
                    this.wordstohighlight[i].statue = false;
                    _lastTime =
                        this.wordstohighlight[i].end - this.wordstohighlight[i].start;
                }, (this.wordstohighlight[i].end - this.wordstohighlight[i].start) * 1000);
            }
        }

        return array;
    }

    scrollToHighlight() {
        if (!this.scrollPanel) {
            return;
        }

        const el: any = document.querySelector('.highlighted');

        const scrollableContainer = this.getScrollableContent();
        const clientHeight = (el && el.offsetTop) || null;

        if (clientHeight) {
            scrollableContainer.scrollTop = clientHeight - 40;
        }
    }

    getScrollableContent() {
        if (!this.scrollableContainer || !this.scrollableContainer.isConnected) {
            this.scrollableContainer =
                this.scrollPanel.el.nativeElement.querySelector(
                    '.p-scrollpanel-content'
                );
        }

        return this.scrollableContainer;
    }

    additemtowortds(words: Array<any>, success: boolean) {
        for (let i = 0; i < words.length; i++) {
            words[i].statue = success; // Add "success": 2 to all objects in array
        }
    }

    testt(time: any) {
        this.timer = time.srcElement.currentTime;
    }

    testvideo(time: any) {
        this.timer = time.time;

        if (this.waveform != null) {
            console.log(' region timer changed', time);
            this.timerprogress = (100 * this.timer) / this.waveform.region.end;
            this.audioStart = this.waveform.region.start;
          const display = moment()
              .startOf('day')
              .seconds(this.timer)
              .format('H:mm:ss');
          this.audioStart = display;
            if (time.progress >= 100) {
                this.clickplay = false;
            }
        }

        this.scrollToHighlight();
    }

    close(): void {
        this.modalService.ModalStateisclosed();
    }

    cancel(): void {
        this.close();
    }

    truncate(str: any, n: any) {
        if (str) {
            return str.length > n ? str.substr(0, n - 1) + '...' : str;
        }
    }
}
