import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import {MatSort, Sort} from '@angular/material/sort';

import {TableHeaderCellInterface, TableInterface} from '../interfaces/table-header-cell.interface';
import {HistoryService} from '../service/history/history.service';

@Component({
    selector: 'Alspeech-custom-table',
    templateUrl: './custom-table.component.html',
    styleUrls: ['./custom-table.component.scss'],
})
export class CustomTableComponent implements OnInit, OnChanges, OnDestroy {
    @Input() payload: TableInterface | any = null;
    @Input() isLoading;

    @Output() clickedFilename: EventEmitter<string> = new EventEmitter<string>();
    @Input() test;
    item: any = '';

    tableHeaders: TableHeaderCellInterface[] = [];
    tableHeadersNames: string[] = [];
    tableData: any[] = [];
    dataSource: any;
    sortItems: any;
    length = 1;
    pageSize = 10;
    pageSizeOptions: number[] = [10, 15, 20];
    offset = 0;
    previousPageIndex = 0;
    limitPage = 10;
    // MatPaginator Output
    @Output() pageEvent: PageEvent;
    @Output()
    page: EventEmitter<PageEvent>;
    @ViewChild(MatSort) sort: MatSort;

    @ViewChild(MatPaginator, {static: false}) paginator!: MatPaginator;

    constructor(private historyService: HistoryService) {
    }

    ngOnDestroy(): void {
        console.log('this is test custom');
        this.payload = [];
    }

    ngOnInit(): void {
        console.log('testing the loading OnInit', this.isLoading);

        const itemPerPAge = this.historyService.getselectedItemPerPage();

        if (itemPerPAge !== '0') {
            console.log(itemPerPAge);
            this.limitPage = Number(itemPerPAge);
            this.pageSize = Number(itemPerPAge);
            console.log('pageee', this.page);
        }
    }

    setPageSizeOptions(setPageSizeOptionsInput: string) {
        if (setPageSizeOptionsInput) {
            this.pageSizeOptions = setPageSizeOptionsInput
                .split(',')
                .map((str) => +str);
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        console.log('test changes', changes);
        console.log('testing the loading onchage', this.isLoading);
        if (this.isLoading) {
            if (changes.payload) {

                console.log('test changes inside test payload', changes);
                this.payload = changes.payload.currentValue;

                this.tableData = this.payload.values;

                this.length = this.payload.total;

                this.tableHeaders = this.payload.headers;
                if (window.innerWidth < 600) {
                    this.filterHeaderAllName();
                    document.getElementById('FileNameHistorys').style.width = '70px';
                    document.getElementById('FileNameHistory').style.width = '70px';
                } else if (window.innerWidth < 700) {
                    this.filterHeaderNameMediumSize();
                    document.getElementById('FileNameHistorys').style.width = '150px';

                    document.getElementById('FileNameHistory').style.width = '150px';
                } else if (window.innerWidth < 1200) {
                    this.filterHeaderNameSmallSize();
                } else {
                    this.tableHeadersNames = this.tableHeaders
                        .filter((header) => header.type !== 'id')
                        .map((header) => header.name);
                    setTimeout(() => {
                        document.getElementById('FileNameHistorys').style.width = '200px';
                        document.getElementById('FileNameHistory').style.width = '200px';
                    }, 500);

                }

            }
        }

    }

    truncate(str: any, n: any) {
        return str.length > n ? str.substr(0, n - 1) + '...' : str;
    }

    filterHeaderAllName() {
        this.tableHeadersNames = this.tableHeaders
            .filter(
                (header) =>
                    header.type !== 'id' &&
                    header.key !== 'size' &&
                    header.key !== 'updated_at' &&
                    header.key !== 'created_at' &&
                    header.key !== 'skill_name'
            )
            .map((header) => header.name);
    }

    filterHeaderNameMediumSize() {
        this.tableHeadersNames = this.tableHeaders
            .filter(
                (header) =>
                    header.type !== 'id' &&
                    header.key !== 'size' &&
                    header.key !== 'updated_at' &&
                    header.key !== 'created_at'
            )
            .map((header) => header.name);
    }

    filterHeaderNameSmallSize() {
        this.tableHeadersNames = this.tableHeaders
            .filter(
                (header) =>
                    header.type !== 'id' &&
                    header.key !== 'size' &&
                    header.key !== 'updated_at'
            )
            .map((header) => header.name);
    }

    onResize(event) {
        if (event.target.innerWidth < 600) {
            this.filterHeaderAllName();
            document.getElementById('FileNameHistorys').style.width = '70px';
            document.getElementById('FileNameHistory').style.width = '70px';
        } else if (event.target.innerWidth < 700) {
            this.filterHeaderNameMediumSize();
            document.getElementById('FileNameHistorys').style.width = '150px';
            document.getElementById('FileNameHistory').style.width = '150px';
        } else if (event.target.innerWidth < 1200) {
            this.filterHeaderNameSmallSize();
        } else {
            this.tableHeadersNames = this.tableHeaders
                .filter((header) => header.type !== 'id')
                .map((header) => header.name);
            document.getElementById('FileNameHistorys').style.width = '200px';
            document.getElementById('FileNameHistory').style.width = '200px';
        }
    }

    onPaginateChange($event) {
        this.limitPage = 0;
        this.offset = 0;
        this.length = 0;
        this.previousPageIndex = $event.previousPageIndex;
        this.offset = $event.pageIndex;
        this.limitPage = $event.pageSize;
        this.length = $event.length;
        this.sortItems = [
            {
                offset: this.limitPage * this.offset,
                limit: this.limitPage,
                cartegories: 'AlSPEECH',
                status: 'Uploaded',
                sorting_options: {
                    sorting_column: 'created_at',
                    sorting_direction: 'desc',
                },
            },
        ];
        $event.pageSize = this.limitPage;
        this.historyService.setselectedItemPerPage(this.limitPage);

        this.historyService.isSortingStateValues(this.sortItems);
    }

    onClickFile(filename: string) {
        this.clickedFilename.emit(filename);
    }

    /** Announce the change in sort state for assistive technology. */
    announceSortChange(sortState: Sort) {
        // This example uses English messages. If your application supports
        // multiple language, you would internationalize these strings.
        // Furthermore, you can customize the message to add additional
        // details about the values being sorted.
        if (sortState.active === 'Size/Length') {
            sortState.active = 'size';
        } else if (sortState.active === 'Processing Date') {
            sortState.active = 'updated_at';
        } else {
            sortState.active = 'created_at';
        }
        if (sortState.direction) {
            this.sortItems = [
                {
                    offset: this.limitPage * this.offset,
                    limit: this.limitPage,
                    cartegories: 'AlSPEECH',
                    status: 'Uploaded',
                    sorting_options: {
                        sorting_column: sortState.active,
                        sorting_direction: sortState.direction,
                    },
                },
            ];
            this.historyService.isSortingStateValues(this.sortItems);
            this.historyService.setselectedItemPerPage(this.limitPage);
        } else {
            this.sortItems = [
                {
                    offset: this.limitPage * this.offset,
                    limit: this.limitPage,
                    cartegories: 'AlSPEECH',
                    status: 'Uploaded',
                    sorting_options: {
                        sorting_column: sortState.active,
                        sorting_direction: 'desc',
                    },
                },
            ];
            this.historyService.isSortingStateValues(this.sortItems);
            this.historyService.setselectedItemPerPage(this.limitPage);
        }
    }
}
