import { Component } from '@angular/core';
import { Router } from "@angular/router";

@Component({
  selector: 'app-alspeech-landing',
  templateUrl: './alspeech-landing.component.html',
  styleUrls: ['./alspeech-landing.component.scss']
})
export class AlspeechLandingComponent  {

  constructor(
    private router: Router
  ) { }


  goToDemo() {
    this.router.navigate(["demo"]);
  }
}
