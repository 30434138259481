import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {BrowserModule} from '@angular/platform-browser';
import {RouterModule, Routes} from '@angular/router';


import {AlspeechLandingComponent} from './alspeech-landing/alspeech-landing.component';
import {AlspeechDemoComponent} from './alspeech-landing/alspeech-demo/alspeech-demo.component';
import {LoginComponent} from './login/login.component';
import {RegisterComponent} from './register/register.component';
import {DashboardComponent} from './dashboard/dashboard.component';
import {DashboardLayoutComponent} from './layouts/dashboard-layout/dashboard-layout.component';
import {AuthGuardService} from 'src/shared/service/auth-guard/auth-guard.service';
import {LoggedInGuardService} from 'src/shared/service/auth-guard/logged-in-guard.service';
import {ContactUsComponent} from './dashboard/contact-us/contact-us.component';
import {ProductsComponent} from './dashboard/products/products.component';
import {AlspeechProductComponent} from './dashboard/alspeech-product/alspeech-product.component';
import {HistoryComponent} from './dashboard/history/history.component';
import {AnalyticsComponent} from './dashboard/analytics/analytics.component';
import {PaymentComponent} from './dashboard/payment/payment.component';
import {NotificationComponent} from './dashboard/notification/notification.component';
import {EditTranscriptionsPageComponent} from './dashboard/edit-transcriptions-page/edit-transcriptions-page.component';
import {UserInfoComponent} from './admin/user-info/user-info.component';
import {DemoTranscriptionComponent} from './admin/demo-transcription/demo-transcription.component';
import {UserManagementComponent} from './admin/user-management/user-management.component';
import {AdminDashboardComponent} from './admin/admin-dashboard/admin-dashboard.component';
import {UserDashboardManagementComponent} from './admin/user-dashboard-management/user-dashboard-management.component';
import {WebhookDashboardComponent} from './webhook/webhook-dashboard/webhook-dashboard.component';
import { ApiDocumentationComponent } from './alspeech-landing/api-documentation/api-documentation.component';


const routes: Routes = [

    {
        path: '',
        pathMatch: 'full',
        component: AlspeechLandingComponent,
        canActivate: [LoggedInGuardService],
    },

    {
        path: 'login',
        pathMatch: 'prefix',
        component: LoginComponent,
        canActivate: [LoggedInGuardService],
    },

    {
        path: 'register',
        pathMatch: 'prefix',
        component: RegisterComponent,
        canActivate: [LoggedInGuardService],

    },
    {
        path: 'docs',
        pathMatch: 'prefix',
        component: ApiDocumentationComponent,
        canActivate: [LoggedInGuardService],
    },


    {
        path: 'dashboard',
        pathMatch: 'prefix',
        component: DashboardLayoutComponent,
        canActivate: [AuthGuardService],
        runGuardsAndResolvers: 'always',

        children: [
            {
                path: '',
                component: DashboardComponent,
                pathMatch: 'full',
            },
            /*    {
                  path: "AudioTranscripted",
                  component: AudioTranscriptedComponent,
                },*/
            {
                path: 'Products',
                component: ProductsComponent,
            },

            {
                path: 'ProductsAlspeech',
                component: AlspeechProductComponent,

            },
            {
                path: 'EditTranscription',
                component: EditTranscriptionsPageComponent,
            },
            {
                path: 'contact',
                component: ContactUsComponent,
            },
            {
                path: 'history',
                component: HistoryComponent,
            },
            {
                path: 'analytic',
                component: AnalyticsComponent,
            },
            {
                path: 'payment',
                component: PaymentComponent,
            },
            {
                path: 'notification',
                component: NotificationComponent,
            },
            {
                path: 'webhook',
                component: WebhookDashboardComponent
            }


        ],
    },
    {
        path: 'demo',
        pathMatch: 'prefix',
        component: AlspeechDemoComponent,
        canActivateChild: [LoggedInGuardService],
        children: [
            {
                path: '',
                component: AlspeechDemoComponent,
            },
        ]
    },

    {   path: 'admin',
        pathMatch: 'prefix',
        component: DashboardLayoutComponent,
        canActivate: [AuthGuardService],
        runGuardsAndResolvers: 'always',
        data: {
            role: 'ADMIN'
        },
        children: [
            {
                path: '',
                component: AdminDashboardComponent,
                pathMatch: 'full'
            },
            {
                path: 'user',
                component: UserDashboardManagementComponent,
                pathMatch: 'full'
            }
        ]
    }

];

@NgModule({
    imports: [
        CommonModule,
        BrowserModule,
        RouterModule.forRoot(routes, {useHash: true}),
    ],
    exports: [RouterModule],
})
export class AppRoutingModule {
}
