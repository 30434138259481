import {
  Component,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewEncapsulation,
} from "@angular/core";
import { Router } from "@angular/router";
import { AuthService } from "src/shared/service/auth/auth.service";
import { NotificationService } from "src/shared/service/notification/notification.service";

@Component({
  selector: "app-dashboard-nav-bar",
  templateUrl: "./dashboard-nav-bar.component.html",
  styleUrls: ["./dashboard-nav-bar.component.scss"],
  providers: [AuthService],
  encapsulation: ViewEncapsulation.None,
})
export class DashboardNavBarComponent implements OnInit, OnChanges {
  username: any;
  loggedIn: boolean = false;
  alerts = [];
  notifClicked = true;
  numberofalerts: any = 0;
  declenched = false;
  pageHeader: string;
  pageAdmin = false;
  constructor(
    private router: Router,
    private authService: AuthService,
    private notificationLogService: NotificationService
  ) {}
  ngOnChanges(changes: SimpleChanges): void {
    this.getNotification();
  }

  ngOnInit(): void {
    this.getPageHeader();
    const token = this.authService.getJwtData();
    if (token.user_type) {
      this.pageAdmin = true;
    }
    this.loggedIn = this.authService.loggedIn();
    if (this.loggedIn) {
      this.getNotification();
    }
    this.username = this.getfirstName(this.getUsername());
  }
  isClicked(alert) {
    console.log("this is alert", alert.seen);
    this.declenched = true;
    if (alert.seen == "isNotClicked") {
      this.numberofalerts = this.numberofalerts - 1;
    } else {
      this.numberofalerts = this.numberofalerts;
    }

    console.log("alerts number", this.numberofalerts);
    this.notifClicked = false;
    this.getNotifColor(alert);
    console.log("logs", this.notifClicked);
  }
  getNotifColor(alert) {
    if (!this.notifClicked) {
      console.log("yessss", this.notifClicked);
      alert.seen = "isClicked";
      console.log("this alert seen", alert.seen);
      // return { "background-color": "black" };
    }
  }
  getUsername() {
    let user_id = "test";
    if (this.authService.loggedIn()) {
      const jwtData = this.authService.getJwtData();
      user_id = jwtData.sub;
    }
    return user_id;
  }
  goToLogin() {
    this.router.navigate(["login"]);
  }
  goToRegister() {
    this.router.navigate(["register"]);
  }
  logout() {
    this.authService.logout();
  }
  goToHome() {
    if (this.authService.loggedIn()) {
      this.router.navigate(["dashboard"]);
    } else {
      this.router.navigate([""]);
    }
  }
  getfirstName(text: any) {
    var splitted = text.split(" ");
    return splitted[0];
  }
  getNotification() {
    this.notificationLogService
      .notificationBehavior$()
      .subscribe(
        (notificationMessage: {
          message: string;
          status: string;
          date: string;
          seen: any;
          icon: any;
        }) => {
          if (notificationMessage) {
            console.log(notificationMessage);
            this.alerts.push(notificationMessage);
            if (this.numberofalerts == 0 && this.declenched == false) {
              this.numberofalerts = this.alerts.length;
            } else {
              this.numberofalerts = this.numberofalerts + 1;
            }

            console.log("this is random", notificationMessage);
          }
        }
      );
  }
  getPageHeader(){
    this.notificationLogService.pageHeader$().subscribe((data)=>{
      this.pageHeader = data
    })
  }
}
