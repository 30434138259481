import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Organization } from "../../models/Organization";
import { environment } from "../../../environments/environment";
const GATEWAY_URL = environment.GATEWAY_URL || "http://localhost:8000";
const BFF_URL = environment.BFF_KEY || "http://localhost:8000";
@Injectable({
  providedIn: "root",
})
export class OrganizationService {

  constructor(private http: HttpClient) {}

  getAllOrganizations() {
    return this.http.get<{ organizations: Organization[] }>(
      `${BFF_URL}/organizations`
    );
  }
}
