import {Component, OnInit, ViewChild} from '@angular/core';
import {DemoTranscriptionsService} from '../../../shared/service/demo-transcriptions/demo-transcriptions.service';
import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import {MatDialog} from '@angular/material/dialog';
import {ModalService} from '../../../shared/service/modal.service';
import {WavePlayerComponent} from '../../dashboard/wave-player/wave-player.component';
import {environment} from '../../../environments/environment.prod';

const ENGLISH_DEMO_USER = environment.ENGLISH_DEMO_USER || '833d5278-dc23-4a3b-85c8-85873916402c';
const ARABIC_DEMO_USER = environment.ARABIC_DEMO_USER || '73446d44-9e40-42b1-96ff-c981f129be5f';
const FRENCH_DEMO_USER = environment.FRENCH_DEMO_USER || '2157bab5-5807-49b8-82a1-a42ca0e552b2';
const GATEWAY_URL = environment.GATEWAY_URL_DEMO;

@Component({
    selector: 'app-demo-transcription',
    templateUrl: './demo-transcription.component.html',
    styleUrls: ['./demo-transcription.component.css']
})
export class DemoTranscriptionComponent implements OnInit {
    dataSources;
    displayedColumns;
    models = [];
    totalTranscriptions;
    modelId: string;
    durationAverage;
    @ViewChild(MatPaginator) paginator: MatPaginator;
    name_file_selected = '';
    id_file_selected = '';
    transcription_id = '';
    speakerChecked = false;
    fileType = 'audio';
    modelUser = ENGLISH_DEMO_USER;
    uriTranscription: string;

    constructor(private demoTranscriptionService: DemoTranscriptionsService,
                public dialog: MatDialog,
                private modalService: ModalService) {
    }

    ngOnInit(): void {
        this.demoTranscriptionService.getModelsForLiveDecoding().subscribe(data => {
            this.extractModels(data);
            console.clear();
            console.table(this.models);
            this.modelId = this.models[0].id;
            this.getTranscriptionsByModel(this.modelId);
        });
        this.displayedColumns = ['created_at', 'extension', 'process_time', 'status', 'duration'];
    }

    onChangeModel($event) {
        this.modelId = $event.target.value;
        this.getTranscriptionsByModel(this.modelId);
        this.modelUser = this.chekModelUser('english') ? ENGLISH_DEMO_USER : this.chekModelUser('french') ?
            FRENCH_DEMO_USER : ARABIC_DEMO_USER;

        console.log(this.modelUser);
    }

    getTranscriptionsByModel(modelId) {
        const average = arr => arr.reduce((p, c) => p + c, 0) / arr.length;

        this.demoTranscriptionService.getAllDemoTranscriptionsByModel(modelId).subscribe(data => {
            this.dataSources = new MatTableDataSource<any>(data);
            this.dataSources.paginator = this.paginator;
            this.totalTranscriptions = this.dataSources.data.length;
            const durationArrays = data.map(d => d.duration);
            this.durationAverage = average(durationArrays);
        });
    }

    clickedRows(row) {
        console.error(row);
        this.name_file_selected = row.file_name;
        this.modalService.ModalStatePened();
        this.id_file_selected = row.file_id;
        this.transcription_id = row.id;
        this.uriTranscription = `${GATEWAY_URL}/transcription_by_id/${row.id}`;

        this.dialog.open(WavePlayerComponent, {
            panelClass: 'custom-dialog-container',
        });
        this.modalService.fileInoformation([
            this.id_file_selected,
            this.transcription_id,
            this.name_file_selected,
            this.speakerChecked,
            this.fileType,
            this.uriTranscription
        ]);
        this.modalService.urlAudioObservable(
            `${GATEWAY_URL}/videostreaming?file_id=${this.id_file_selected}&user_id=${this.modelUser}`
        );
    }

    private extractModels(data) {
        data.forEach((ele: any) => {
            this.models.push({name: ele.name, id: ele.id});
        });
    }


    private chekModelUser(str) {
        return this.modelId.toLocaleLowerCase().includes(str);
    }
}
