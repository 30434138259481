import {NgModule} from '@angular/core';
import {UserInfoComponent} from './user-info/user-info.component';
import {SharedModule} from '../../shared/shared.Module';
import {MatTableModule} from '@angular/material/table';
import {MatPaginatorModule} from '@angular/material/paginator';
import { DemoTranscriptionComponent } from './demo-transcription/demo-transcription.component';
import {BrowserModule} from '@angular/platform-browser';
import {CommonModule} from '@angular/common';
import { UserManagementComponent } from './user-management/user-management.component';
import {ReactiveFormsModule} from '@angular/forms';
import { ConfirmModalComponent } from './confirm-modal/confirm-modal.component';
import {MatDialogModule} from '@angular/material/dialog';
import { AdminDashboardComponent } from './admin-dashboard/admin-dashboard.component';
import {ScrollPanelModule} from 'primeng/scrollpanel';
import {MatButtonModule} from '@angular/material/button';
import {MatListModule} from '@angular/material/list';
import {DashboardModule} from '../dashboard/dashboard.module';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatFormFieldModule} from '@angular/material/form-field';
import { UserDashboardManagementComponent } from './user-dashboard-management/user-dashboard-management.component';
import { ApiKeyManagementComponent } from './api-key-management/api-key-management.component';
import { ModalConfirmGenerateApiKeyComponent } from './modal-confirm-generate-api-key/modal-confirm-generate-api-key.component';
import { ApiKeysTableComponent } from './api-keys-table/api-keys-table.component';
import {MatIconModule} from '@angular/material/icon';
import {MatSelectModule} from '@angular/material/select';


@NgModule({
    imports: [
        SharedModule,
        MatTableModule,
        MatPaginatorModule,
        BrowserModule,
        CommonModule,
        ReactiveFormsModule,
        MatDialogModule,
        ScrollPanelModule,
        MatButtonModule,
        MatListModule,
        DashboardModule,
        MatDatepickerModule,
        MatFormFieldModule,
        MatIconModule,
        MatSelectModule


    ],
    declarations: [
        UserInfoComponent,
        DemoTranscriptionComponent,
        UserManagementComponent,
        ConfirmModalComponent,
        AdminDashboardComponent,
        UserDashboardManagementComponent,
        ApiKeyManagementComponent,
        ModalConfirmGenerateApiKeyComponent,
        ApiKeysTableComponent
    ]
})
export class AdminModules {}
