import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

import { environment } from "../../environments/environment.prod";
import { Observable } from "rxjs";

const GATEWAY_URL = environment.GATEWAY_URL_DEMO || 'https://alspeech.dev.algobrain.ai/demo';
const BFF_URL = environment.BFF_KEY || 'http://localhost:8000';


@Injectable({
  providedIn: 'root',
})
export class RecordService {
  constructor(private http: HttpClient) {}
  getmodelsforlivedecoding() {
   return this.http.get<[]>(`${GATEWAY_URL}/available_live_decoders`);
  }
  getmodelsforlivedecodingFromBff(): Observable<any> {
    return this.http.get<any[]>(`${BFF_URL}/task/available_live_decoders`);

   }
}
