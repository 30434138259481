import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ModalService {
    test: any[] = [];
    state: BehaviorSubject<boolean> = new BehaviorSubject(false);
    modalState: BehaviorSubject<boolean> = new BehaviorSubject(false);
    urlAUdio: BehaviorSubject<any> = new BehaviorSubject(null);
    fileInformation: BehaviorSubject<any> = new BehaviorSubject(this.test);
    private modals: any[] = [];

    add(modal: any) {
        // add modal to array of active modals
        this.modals.push(modal);
    }

    remove(id: string) {
        // remove modal from array of active modals
        this.modals = this.modals.filter(x => x.id !== id);
    }

    open(id: string) {
        // open modal specified by id
        const modal = this.modals.find(x => x.id === id);
        modal.open();
    }

    close(id: string) {
        // close modal specified by id
        const modal = this.modals.find(x => x.id === id);
        modal.close();
    }

    ModalStatePened() {
        this.state.next(true);
    }

    ModalStateclosed() {
        this.state.next(false);
    }

    ModalStateisclosed() {
        this.modalState.next(false);
    }

    ModalIsOpened() {
        this.modalState.next(true);
    }

    ModalIsOpenorClosed$() {
        return this.modalState.asObservable();
    }

    isOpen$() {
        return this.state.asObservable();
    }

    fileInoformation(url: any[]) {
        return this.fileInformation.next(url);
    }

    fileInoformation$() {
        return this.fileInformation.asObservable();
    }

    urlAudioObservable(url) {
        return this.urlAUdio.next(url);
    }

    urlAudio$() {
        return this.urlAUdio.asObservable();
    }
}
