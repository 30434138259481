import {DatePipe} from '@angular/common';
import {Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewEncapsulation} from '@angular/core';

// tslint:disable-next-line:no-duplicate-imports
// @ts-ignore
import {ModalService} from 'src/shared/service/modal.service';
import {TrascriptionServiceService} from 'src/shared/service/transcriptions/trascription-service.service';
import {AuthService} from 'src/shared/service/auth/auth.service';
import {MatDialog} from '@angular/material/dialog';
import {WavePlayerComponent} from '../wave-player/wave-player.component';
import {NotificationService} from 'src/shared/service/notification/notification.service';
import {SideBarEventService} from 'src/shared/service/sideBarEvent/side-bar-event.service';
import {RecordeDashboardComponent} from '../recorde-dashboard/record-dashboard.component';
import * as moment from 'moment';
import {Observable} from 'rxjs';
import {DateRange} from '@angular/material/datepicker';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {SearchProductRequest, SortingDirection} from '../../../shared/interfaces/SearchProductRequest';


interface State {
    name: string;
    code: string;
}

interface Model {
    code: string;
    name: string;
}

@Component({
    selector: 'app-alspeech-product',
    templateUrl: './alspeech-product.component.html',
    styleUrls: ['./alspeech-product.component.css'],
    animations: [
        trigger('rotatedState', [
            state('default', style({transform: 'rotate(0)'})),
            state('rotated', style({transform: 'rotate(-180deg)'})),
            transition('rotated => default', animate('600ms ease-out')),
            transition('default => rotated', animate('600ms ease-in'))
        ])
    ],
    providers: [DatePipe],
    encapsulation: ViewEncapsulation.None,
})
export class AlspeechProductComponent implements OnInit {
    productClicked = true;
    calenderclicked = false;
    categorieClicked = false;
    results: any[] = [];
    files: any;
    selectedOption: any;
    type = 'Id';
    ishttpLoaded = false;
    name: string;
    color: string;
    closed: boolean;
    test: any;
    isUpload: any;
    status: State[];
    models: Model[];
    selectedState: State;
    selectedModel: Model;
    selectedStateToSearch: any;
    selectedFilterOption = 'created_at';
    dataFilter: any;
    user_id: any;
    filteredOptions: Observable<string[]>;
    options: any;
    orderByList;
    selectedOrder = 'created_at';
    rotationState = 'default';
    typeFile:any
    searchProductRequest: SearchProductRequest = <SearchProductRequest>{};
    @Input() selectedRangeValue: DateRange<Date> | undefined;
    @Output() selectedRangeValueChange = new EventEmitter<DateRange<Date>>();

    constructor(
        private transcriptionService: TrascriptionServiceService,
        public authService: AuthService,
        private modalService: ModalService,
        public dialog: MatDialog,
        private notificationService: NotificationService,
        private sideBarItem: SideBarEventService
    ) {

        this.typeFile = [
            {name: 'Type', code: 'type'},   
        {name: 'Video', code: 'video'},
        {name: 'Audio', code: 'audio'},]
        this.status = [
            {name: 'Status', code: 'status'},
            {name: 'Uploaded', code: 'Uploaded'},
            {name: 'Transcribed', code: 'Transcribed'},
            {name: 'Error', code: 'Error'},
            {name: 'In progress', code: 'InProgress'},
            {name: 'Submitted', code: 'Submitted'},
        ];
        this.models = [
            {
                code: 'All_Models',
                name: 'Models',
            }, {
                code: 'Kaldi_Arabic_medium',
                name: 'Live Arabic',
            },
            {
                code: 'Kaldi_English_medium',
                name: 'Live English',
            },
            {
                code: 'Kaldi_French_small',
                name: 'Live French',
            },
            {
                code: 'ASR_Arabic_Model_Arabic_Medium_Kaldi_ASR_1.1',
                name: 'Arabic model',
            },
            {
                code: 'ASR_English_Model_English_Medium_Kaldi_ASR_1.1',
                name: 'English model',
            },
            {
                code: 'ASR_French_Model_French_Small_Kaldi_ASR_1.1',
                name: 'French model',
            }
        ];
        this.orderByList = [
            {id: 'desc', name: 'Order By'},
            {id: 'created_at', name: 'Uploading'},
            {id: 'updated_at', name: 'Process date'},
            {id: 'file_name', name: 'File name'},
            {id: 'size', name: 'File size'},
            {id: 'process_time', name: 'Process time'}
        ];
        this.searchProductRequest = <SearchProductRequest>{};
        this.searchProductRequest.offset = 0;
        this.searchProductRequest.limit = 10;
        this.searchProductRequest.sorting_direction = SortingDirection.desc;
        this.searchProductRequest.sorting_column = 'created_at';
    }

    ngOnInit(): void {
        this.modalService.urlAudioObservable(null);
        console.log('changed data init', this.dataFilter);
        this.user_id = this.getUser();
        this.sideBarItem.returnSelected(1);
        this.notificationService.pageHeaderContent('Products');
        this.getstatusProductclicked(this.productClicked);
        this.getstatusCategorieclicked(this.categorieClicked);
        this.transcriptionService.isUpload$().subscribe((isUploading) => {
            this.isUpload = isUploading;
        });
        this.transcriptionService.isSortingStateProduct$().subscribe(sorting => {
            console.log('test sort', sorting);
            if (sorting !== undefined) {
                this.searchProductRequest.offset = sorting.offset;
                this.searchProductRequest.limit = sorting.limit;
                this.searchByFilter();
            }
        });
        this.transcriptionService.isTranscriptionListNeedRefresh$().subscribe(data => {
            if (data) {
                this.searchByFilter();
            }
        });
        this.transcriptionService.needRefreshAfterUpdateBehave$().subscribe(data => {
            if (data) {
                this.searchByFilter();
            }
        });

        // this.getSearchResults();
        this.searchByFilter();
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.sideBarItem.returnSelected(1);
        this.getstatusProductclicked(this.productClicked);
        this.getstatusCategorieclicked(this.categorieClicked);
        this.transcriptionService.isUpload$().subscribe((isUploading) => {
            this.isUpload = isUploading;
        });

        console.log('changed data', this.dataFilter);
    }

    /*
        rotate() {
            this.rotationState = (this.rotationState === 'default' ? 'rotated' : 'default');
            this.searchProductRequest.sorting_direction = this.rotationState === 'default' ? SortingDirection.desc : SortingDirection.asc;
            this.searchProductRequest.offset = 0;
            this.transcriptionService.resetPageIndexBehave(true);
            this.searchByFilter();
        }*/

    startChange(event: any) {
        this.searchProductRequest.date_range = [null, null];
        this.searchProductRequest.date_range[0] = moment(event.value).format('DD/MM/YYYY') === 'Invalid date' ? null
            : moment(event.value).format('DD/MM/YYYY');
    }

    endChange(event: any) {

        (<HTMLElement>document.querySelector('mat-date-range-input')).style.display = 'block';
        document.getElementsByClassName('mat-form-field-type-mat-date-range-input')[0].classList.add('date-picker-inputs');

        this.searchProductRequest.date_range[1] = moment(event.value).format('DD/MM/YYYY') === 'Invalid date' ? null
            : moment(event.value).format('DD/MM/YYYY');
        this.searchProductRequest.offset = 0;
        this.transcriptionService.resetPageIndexBehave(true);
        this.searchByFilter();
    }

    calendarClicked() {
        this.calenderclicked = true;
    }

    searchByFileName(event) {
        this.getSearchResults();
        this.searchByFilter();
    }

    onChange(deviceValue) {
        this.searchProductRequest.status = deviceValue.target.value === 'status' ? undefined : deviceValue.target.value;
        this.searchProductRequest.offset = 0;
        this.transcriptionService.resetPageIndexBehave(true);

        this.searchByFilter();
    }
    onChangeType(deviceValue) {
        console.log('testing the type of the file',deviceValue); 
        this.searchProductRequest.file_type = deviceValue.target.value === 'type' ? undefined : deviceValue.target.value;
        this.searchProductRequest.offset = 0;
        this.transcriptionService.resetPageIndexBehave(true);

        this.searchByFilter();
    }

    onChangeModel(event) {
        this.searchProductRequest.skill_id = event.target.value === 'All_Models' ? undefined : event.target.value;
        this.searchProductRequest.offset = 0;
        this.transcriptionService.resetPageIndexBehave(true);
        this.searchByFilter();
    }

    openDialogRecord() {
        const dialogRef = this.dialog.open(RecordeDashboardComponent, {
            panelClass: 'custom-dialog-container',
            disableClose: true
        });

        dialogRef.afterClosed().subscribe((result) => {
            console.log(`Dialog result: ${result}`);
        });
    }

    testCalender() {
        console.log('this is selected dataFilter', this.dataFilter);
    }

    getUser() {
        let user_id = '';
        if (this.authService.loggedIn()) {
            const jwtData = this.authService.getJwtData();
            user_id = jwtData.user_id;
        }
        return user_id;
    }

    getstatusProductclicked(event) {
        this.productClicked = event;
    }

    getstatusCategorieclicked(event) {
        this.categorieClicked = event;
    }

    getDataFromCalender() {
        console.log('filterrrr', this.dataFilter);
    }

    openDialog() {
        console.log('test');
        const dialogRef = this.dialog.open(WavePlayerComponent);

        dialogRef.afterClosed().subscribe((result) => {
            console.log(`Dialog result: ${result}`);
        });
    }

    searchByFilter() {
        // tslint:disable-next-line:max-line-length
        if (this.searchProductRequest.date_range && (this.searchProductRequest.date_range[0] == null || this.searchProductRequest.date_range[1] == null)) {
            this.searchProductRequest.date_range = null;
        }
       // this.transcriptionService.returnIdFileSelectedDelete([]);
        this.transcriptionService.needRefreshAfterUpdateBehave(false);
        this.transcriptionService
            .filterTranscriptionByUser(this.user_id, this.searchProductRequest)
            .subscribe((data) => {
                console.log('this is data for user filtered', data);
                if (data.status === 200) {
                    console.log('filter', data);
                    this.transcriptionService.dataFiltredHandled(data);
                    this.transcriptionService.itemsProductValues(data);
                }
            });
    }

    selectFilterOption(filteringOption) {
        this.searchProductRequest.sorting_column = filteringOption.target.value;
        console.warn(filteringOption.target.value);
    }

    autoCompleteEnter() {
        this.searchProductRequest.offset = 0;
        this.searchByFilter();
    }

    getSelectedValueAutoCopmplete(event) {
        console.log('test selected value', event);
        this.searchProductRequest.file_name = event.source.value;
        this.searchProductRequest.offset = 0;
        this.searchByFilter();
    }

    getSearchResults(): void {
        this.transcriptionService.getFileNamesAutoComplete(this.user_id, this.searchProductRequest.file_name, false).subscribe((sr) => {
                this.filteredOptions = sr.body;
                console.log({sr: sr.body});
            }
        );
    }

    searchOnKeyUp(event) {
        this.getSearchResults();

    }

    searchFromArray(arr, regex) {
        // tslint:disable-next-line:prefer-const
        let matches = [], i;
        for (i = 0; i < arr.length; i++) {
            if (arr[i].match(regex)) {
                matches.push(arr[i]);
            }
        }
        return matches;
    }

    openModal(id: string) {
        this.modalService.ModalStatePened();
        this.modalService.open(id);
    }

    selectedChange(m: any) {
        if (!this.selectedRangeValue?.start || this.selectedRangeValue?.end) {
            this.selectedRangeValue = new DateRange<Date>(m, null);
        } else {
            const start = this.selectedRangeValue.start;
            const end = m;
            if (end < start) {
                this.selectedRangeValue = new DateRange<Date>(end, start);
            } else {
                this.selectedRangeValue = new DateRange<Date>(start, end);
            }
        }
        this.selectedRangeValueChange.emit(this.selectedRangeValue);
    }

    loadSpinner(isloadded) {
        this.ishttpLoaded = isloadded;
    }

    selectChangeHandler(event: any) {
        this.type = event.target.value;
    }

    handleselectedvalue(selectedvaluemodel: any) {
        this.files[selectedvaluemodel.index].selectedModel =
            selectedvaluemodel.selectedModel;
    }

    onChangeOrderBY($event) {
        if ($event.target.value === 'desc') {
            this.searchProductRequest.sorting_direction = SortingDirection.desc;
            this.searchProductRequest.sorting_column = 'created_at';
        } else {
            this.searchProductRequest.sorting_column = $event.target.value;
        }
        this.searchByFilter();
    }
}
