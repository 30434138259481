import {HttpClient, HttpHeaders, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {environment} from 'src/environments/environment';
import {SearchProductRequest} from '../../interfaces/SearchProductRequest';


const BFF_URL = environment.BFF_KEY || "http://localhost:8000";

@Injectable({
    providedIn: 'root'
})

export class HistoryService {
    item: any = '';
    dataFiltredHistory: any = '';
    sendFiltredDataHistory: BehaviorSubject<any> = new BehaviorSubject(this.dataFiltredHistory);
    sortItems = [{
        'offset': 0,
        'limit': this.getselectedItemPerPage() || 10,
        'cartegories': 'AlSPEECH',
        'status': 'Uploaded',
        'sorting_options': {
            'sorting_column': 'created_at',
            'sorting_direction': 'desc'
        }
    }];
    testing: BehaviorSubject<any> = new BehaviorSubject(this.item);
    sortingState: BehaviorSubject<any> = new BehaviorSubject(this.sortItems);
    httpOptions = {
        observe: 'response',
        headers: new HttpHeaders(
            {'Access-Control-Allow-Origin': '*'}
        )
    };

    constructor(private http: HttpClient) {
    }

    ischecked$() {
        return this.testing.asObservable();
    }

    checkcolumn(item) {
        return this.testing.next(item);
    }

    searchHistory(userId: any, dataFilter: SearchProductRequest): Observable<any> {
        const request = new HttpRequest('POST',

            `${BFF_URL}/transcription/infos_of_history_by_user_id/${userId}`, dataFilter
        );

        return this.http.request(request);
    }

    isSortingState$() {
        return this.sortingState.asObservable();
    }

    isSortingStateValues(items) {
        return this.sortingState.next(items);
    }

    getselectedItemPerPage() {

        return localStorage.getItem('itemPerPage');
    }

    setselectedItemPerPage(itemPerPage) {

        return localStorage.setItem('itemPerPage', itemPerPage);
    }

    isDataFiltredHistory$() {
        return this.sendFiltredDataHistory.asObservable();

    }

    dataFiltredHandledHistory(data) {
        return this.sendFiltredDataHistory.next(data);
    }
}
