import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
    ViewEncapsulation,
} from '@angular/core';
import {AuthService} from 'src/shared/service/auth/auth.service';
import {ModalService} from 'src/shared/service/modal.service';
import {TrascriptionServiceService} from 'src/shared/service/transcriptions/trascription-service.service';
import {ToastrService} from 'ngx-toastr';
import * as moment from 'moment';
import {AudioFilesService} from 'src/shared/service/AudioSocket/audio-files.service';
import {DomSanitizer} from '@angular/platform-browser';
import {FileUploadService} from 'src/shared/service/file-upload.service';
import {MatDialog} from '@angular/material/dialog';
import {WavePlayerComponent} from '../wave-player/wave-player.component';
import {NotificationService} from 'src/shared/service/notification/notification.service';
import {Router} from '@angular/router';
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {environment} from 'src/environments/environment';
import {VideoPlayerComponent} from '../video-player/video-player.component';
import {EditModelComponent} from '../edit-model/edit-model.component';

const LIVE_KEY = environment.LIVE_KEY

;
const BFF_URI = environment.BFF_KEY;

declare var require: any;

@Component({
    selector: 'app-file-description',
    templateUrl: './file-description.component.html',
    styleUrls: ['./file-description.component.css'],
    encapsulation: ViewEncapsulation.None,
})
export class FileDescriptionComponent
    implements OnInit, OnChanges, OnDestroy, AfterViewInit {
    @ViewChild('testAudior', {static: false}) audioPlayer: ElementRef;
    idFileToStream: any;
    selectedValuecheked = [];
    selectedValuechekedDelete = [];
    filterLogicFiles: any = [];
    name_file_selected;
    Filedetails: any = [];
    chunks: any;
    source;
    blobUrlofaudio: any;
    speakerChecked: any;
    statusButtonFile = 'statueUploaded';
    p = 0;
    t = 0;
    s = 0;
    id_file_selected: any;
    pageIndex: any;
    bsn: any;
    data: any;
    user_id: any;
    fileType: any;
    status: string;
    errorMessage: string;
    TranscribeNowClicked: boolean;
    id_File_Selected: any = 0;
    transcription_id;
    ws: any;
    context = new AudioContext();
    soundSource: any;
    nextStartTime = 0;
    pauseClicked: boolean;
    stopClicked = true;
    modalState: boolean;
    modalIsOpen: boolean;
    audioStack: any;
    sample_rate: any;
    extension: any;
    modal;
    playStreamClicked = false;
    stateSocket: any;
    newmodalState = false;
    length = 1;
    pageSize = 5;
    pageSizeOptions: number[] = [2, 5, 10];
    offset = 0;
    previousPageIndex = 0;
    limitPage = 10;
    audioStream: any;
    // MatPaginator Output
    @Output() pageEvent: PageEvent;
    @Output()
    page: EventEmitter<PageEvent>;
    @ViewChild(MatSort) sort: MatSort;
    sortItems: any;
    @ViewChild(MatPaginator, {static: false}) paginator!: MatPaginator;
    @Input() id: string;
    @Input() maxSize: number;
    @Output() pageChange: EventEmitter<number>;
    @Output() pageBoundsCorrection: EventEmitter<number>;
    private audioObj = new Audio();

    constructor(
        private modalService: ModalService,
        private uploadFile: FileUploadService,
        private transcriptionService: TrascriptionServiceService,
        public authService: AuthService,
        private toester: ToastrService,
        private readFileService: AudioFilesService,
        public dialog: MatDialog,
        private sanitizer: DomSanitizer,
        private notificationService: NotificationService,
        private route: Router
    ) {
    }

    ngAfterViewInit() {
        const divToMeasureWidth = document.getElementById('divToMeasure');
        console.log('this is div to measure', divToMeasureWidth);
    }

    ngOnDestroy(): void {
        if (this.context.state === 'running') {
            this.stop(this.id_File_Selected);
        }
        this.stateSocket.close();
    }

    ngOnChanges(): void {
        this.modalService.ModalIsOpenorClosed$().subscribe((event) => {

            return event;
        });
        this.getStateFromSocket();
        this.socketMessage();
        this.modalService.isOpen$().subscribe((isOpen) => {
            if (isOpen && this.context.state === 'running') {
                this.stop(this.id_File_Selected);
            }
        });
    }

    change(e, id_file, transcription_id) {

        if (e.checked) {
            this.selectedValuecheked.push(id_file);
            this.selectedValuechekedDelete.push(transcription_id);
        } else {
            const index = this.selectedValuecheked.indexOf(id_file);
            this.selectedValuecheked.splice(index, 1);
            this.selectedValuechekedDelete.splice(index, 1);
        }
        this.transcriptionService.returnIdFileSelectedDelete(
            this.selectedValuechekedDelete
        );
        this.uploadFile.returnIdFileSelected(this.selectedValuecheked);
    }

    getUser() {
        let user_id = '';
        if (this.authService.loggedIn()) {
            const jwtData = this.authService.getJwtData();
            user_id = jwtData.user_id;
        }
        return user_id;
    }

    ngOnInit(): void {
        this.offset = 0;
        this.playStreamClicked = false;
        this.transcriptionService.setselectedItemPerPageProductOffset(this.offset);
        const itemPerPAge = this.transcriptionService.getselectedItemPerPageProduct();
        console.log('itemPerPAge', itemPerPAge);
        if (itemPerPAge !== '0') {
            console.log(itemPerPAge);
            this.limitPage = Number(itemPerPAge);
            this.pageSize = Number(itemPerPAge);
            console.log('pageee', this.page);
        }
        this.user_id = this.getUser();
        this.transcriptionService.isSortingStateProduct$().subscribe((sorting) => {
            console.log('test sort', sorting);
            if (sorting !== undefined) {
                this.sortItems = sorting;
                console.log('test sort', this.sortItems);
                this.getFileDetails();
            }
        });

        this.transcriptionService.isDataFiltred$().subscribe((data) => {
            if (data.body == null || data.body === undefined) {
                this.getFileDetails();
            } else {

                this.Filedetails = [];
                this.filterLogicFiles = [];
                this.getKEy(data.body.values);
            }

        });
        this.notificationService.pageHeaderContent('alSpeech');
        this.notificationService.showConsulting(
            'Alspeech Products has been checked '
        );
        this.id_File_Selected = 0;

        this.uploadFile.fileSelected$().subscribe((item) => {
            this.selectedValuecheked = item;
        });
        this.getStateFromSocket();
        this.readFileService.readFileStateCompleted();
        this.readFileService.isReading$().subscribe((isReadnig) => {
            console.log('testttt', isReadnig);
            this.pauseClicked = isReadnig;
        });
        this.modalService.isOpen$().subscribe((isOpen) => {
            if (isOpen && this.context.state === 'running') {
                this.stop(this.id_File_Selected);
            }
        });
        this.transcriptionService.resetPageIndexBehave$().subscribe(data => {
            if (data) {
                this.paginator.firstPage();
                this.transcriptionService.resetPageIndexBehave(false);
            }
        });
    }

    getStateFromSocket() {
        if (this.stateSocket) {
            this.stateSocket.close();
        }
        this.stateSocket = new WebSocket(
            `${LIVE_KEY}` +'/wsupdate/ws_update/' + this.user_id
        );
        this.socketMessage();
    }

    socketMessage() {
        this.stateSocket.onmessage = (event) => {
            console.log('this is websocket akrem',event)
            const testStatus = JSON.parse(event.data);
            console.log('this is websocket akrem1',testStatus)
            this.changeTranscriptionStatesforSocket(
                this.findTranscriptionbyId(testStatus.reference_id),
                testStatus.status
            );
        };
    }

    findTranscriptionbyId(id) {
        return this.Filedetails.filter((object) => {
            return object['transcription_id'] === id;
        });
    }

    onPaginateChange($event) {
        console.log('offset', this.offset);
        this.limitPage = $event.pageSize;
        this.transcriptionService.setselectedItemPerPageProduct(this.limitPage);

        this.previousPageIndex = $event.previousPageIndex;
        this.pageIndex = $event.pageIndex;
        console.log('pageIndex', this.pageIndex);
        if (this.pageIndex === 0) {
            this.offset = 0;
            this.transcriptionService.setselectedItemPerPageProductOffset(
                this.offset
            );
            this.sortItems = {
                offset: this.offset,
                limit: this.limitPage,
            };
            console.log('test');
            this.transcriptionService.isSortingStateValuesProduct(this.sortItems);
        } else if (this.pageIndex < this.previousPageIndex) {
            this.offset = this.offset - this.limitPage;
            this.transcriptionService.setselectedItemPerPageProductOffset(
                this.offset
            );
            this.sortItems = {
                offset: this.offset,
                limit: this.limitPage,
            };
            this.transcriptionService.isSortingStateValuesProduct(this.sortItems);
        } else {
            this.offset = this.offset + this.limitPage;
            this.sortItems = {
                offset: this.offset,
                limit: this.limitPage,
            };
            this.transcriptionService.setselectedItemPerPageProductOffset(
                this.offset
            );
            this.transcriptionService.isSortingStateValuesProduct(this.sortItems);
        }
        this.transcriptionService.setselectedItemPerPageProduct(this.limitPage);
    }

    getFileDetails() {
        this.Filedetails = [];
        this.filterLogicFiles = [];
        this.transcriptionService.itemsProductObs$().subscribe(data => {
            console.log('data subscribed in file_description: ', data);
            if (data) {

                this.getKEy(data.body.values);
                this.length = data.body.total;
                this.transcriptionService.refreshCompleted();
                this.transcriptionService.returnTranscriptionNumber(
                    data.body.total
                );

            }

        });
    }

    openModal(id: string, id_file, transcription_id, name, options) {
        this.name_file_selected = name;
        this.modalService.ModalStatePened();
        this.id_file_selected = id_file;
        this.transcription_id = transcription_id;
        this.speakerChecked = options;
        this.modalService.open(id);
        if (this.context.state === 'running') {
            this.stop(id_file);
        }
    }

    getColor(statue: any): object | any {
        if (statue === 'Uploaded') {
            return 'statueUploaded';
        } else if (statue === 'Submitted') {
            return 'statueSubmitted';
        } else if (statue === 'Finished') {
            return 'statueFinished';
        }
    }

    openDialog(id_file, transcription_id, name, options, type) {
        this.name_file_selected = name;
        this.modalService.ModalStatePened();
        this.id_file_selected = id_file;
        this.speakerChecked = options;
        this.transcription_id = transcription_id;
        this.fileType = type;
        console.log('testing the extension', this.id_file_selected);
        let dialogRef: any;
        if (this.audioStream) {
            console.log('test stoping the stream when the modal is open');
            this.pause(this.idFileToStream);
        }
        if (type === 'video') {
            dialogRef = this.dialog.open(VideoPlayerComponent, {
                panelClass: 'custom-dialog-container',
            });
        } else {
            dialogRef = this.dialog.open(WavePlayerComponent, {
                panelClass: 'custom-dialog-container',
            });
        }

        this.modalService.ModalStatePened();
        console.log('avant go', [
            this.id_file_selected,
            this.transcription_id,
            this.name_file_selected,
            this.fileType
        ]);
        console.log('testiinggg', this.speakerChecked);
        this.modalService.fileInoformation([
            this.id_file_selected,
            this.transcription_id,
            this.name_file_selected,
            this.speakerChecked,
            this.fileType
        ]);
        dialogRef.afterClosed().subscribe((result) => {
            console.log(`Dialog result: ${result}`);
        });
    }

    openDialogForEdit(id_file, transcription_id, name, process_time, uploadingDate, ProcessingDate, model) {
        this.name_file_selected = name;
        this.transcriptionService.setselectedFileNameToUpdate(
            this.name_file_selected
        );

        this.modalService.ModalStatePened();
        this.id_file_selected = id_file;
        this.transcriptionService.setselectedIdToUpdate(this.id_file_selected);
        this.transcription_id = transcription_id;
        this.transcriptionService.setselectedIdTranscriptionToUpdate(
            this.transcription_id
        );
        this.modalService.ModalStatePened();
        this.modalService.fileInoformation([
            this.id_file_selected,
            this.transcription_id,
            this.name_file_selected,
            this.speakerChecked,
            this.fileType,
            process_time,
            uploadingDate,
            ProcessingDate,
            model
        ]);

        this.route.navigate(['dashboard/EditTranscription']);
    }

    changeTranscriptionStatesforSocket(t, statue: string) {
        if (statue === 'Transcribed' || statue === 'Uploaded' || statue === 'Error') {
            t[0].status = statue;
            if (t[0].status === 'Transcribed') {
                this.notificationService.showSuccess('Transcribed successfully');
            }
        } else {
            console.log('status 111');
            
            if (statue === 'InProgress') {
                console.log('status 222');
                t[0].progress = 0;
            } else {
                
                t[0].progress = statue;
                console.log('status 333',  t[0].progress);
                
            }
           t[0].status = 'InProgress';
        }
    }

    changeTranscriptionState(t, statue: string) {
        if (this.TranscribeNowClicked) {
            t.status = statue;
        }
    }

    getKEy(data) {
        this.Filedetails = [];

        Object.keys(data).forEach((key) => {
            this.Filedetails.push({
                extension: data[key].extension,
                // num_channels: data[key].num_channels,
                sample_rate: data[key].sample_rate,
                process_time: this.GetDurationFormat(data[key].process_time),
                name: this.truncate(data[key].file_name, 15),
                names: this.truncate(data[key].file_name, 19),
                namesmall: this.truncate(data[key].file_name, 5),
                truncname: this.truncate(data[key].file_name, 27),
                truncnamelong: this.truncate(
                    data[key].file_name,
                    40
                ),
                size: data[key].size,
                speakers: data[key].speakers,
                modelName: data[key].skill_name,
                logical: data[key].logical_delete,
                longname: data[key].file_name,
                id: Number(key) + 1,
                transcription_id: data[key].id,
                id_file: data[key].file_id,
                status: data[key].status,
                duration: this.GetDurationFormat(
                    data[key].duration
                ),
                type: data[key].file_type,
                RTF: data[key].rtf,
                dateUpload: this.getdateupload(data[key].created_at),
                model: data[key].skill_id,
                updated: this.getdateupload(data[key].updated_at),
                progress: 0,
                urlStream: BFF_URI + '/streaming_media_file?file_id='
                    + data[key].file_id + '&user_id=' + this.user_id

            });
        });

        return this.Filedetails;
    }

    splitTime(second: any) {
        const splitted = second.toString().split('.');

        return splitted[0];
    }

    GetDurationFormat(seconds) {
        if (seconds == undefined) {
            seconds = 0;
        }
        return moment('2015-01-01')
            .startOf('day')
            .seconds(seconds)
            .format('H:mm:ss');
    }

    download_Transcription_By_Id(
        transcription_id: any,
        file_name: any,
        type: any
    ) {
        this.transcriptionService
            .download_Transcription_By_Id(transcription_id, type)
            .subscribe(
                (response) => {
                    console.log('test the response', response);
                    const dataType = response.type;
                    const binaryData = [];
                    binaryData.push(response);
                    const downloadLink = document.createElement('a');
                    downloadLink.href = window.URL.createObjectURL(
                        new Blob(binaryData, {type: dataType})
                    );
                    if (file_name) {
                        downloadLink.setAttribute(
                            'download',
                            this.nameToDownload(file_name) + '.' + type
                        );
                    }
                    document.body.appendChild(downloadLink);
                    downloadLink.click();
                },
                (error) => {
                    console.log('this file is not yet transcribed');
                }
            );
    }

    getFileToDisplay(id_file) {
        this.transcriptionService
            .downloadFile(id_file, this.user_id)
            .subscribe((response) => {
                this.playAudio(response);
            });
    }

    getBorderColor(t) {

        return 'idFileAudio';

    }

    download_Transcription_Json_By_Id(transcription_id: any, file_name: any) {
        this.download_Transcription_By_Id(transcription_id, file_name, 'json');
    }

    download_Transcription_Stm_By_Id(transcription_id: any, file_name: any) {
        this.download_Transcription_By_Id(transcription_id, file_name, 'stm');
    }

    download_Transcription_Txt_By_Id(transcription_id: any, file_name: any) {
        this.download_Transcription_By_Id(transcription_id, file_name, 'txt');
    }

    download_Transcription_vtt_By_Id(transcription_id: any, file_name: any) {
        this.download_Transcription_By_Id(transcription_id, file_name, 'vtt');
    }

    nameToDownload(str) {
        return str.slice(0, -4);
    }

    playAudio(binary: any) {
        const blob = new Blob([binary]);
        this.blobUrlofaudio = this.sanitizer.bypassSecurityTrustUrl(
            URL.createObjectURL(blob)
        );
    }

    download(file_id: any, file_name: any) {
        this.transcriptionService
            .downloadFile(file_id, this.user_id)
            .subscribe((response) => {
                const dataType = response.type;
                const binaryData = [];
                binaryData.push(response);
                const downloadLink = document.createElement('a');
                downloadLink.href = window.URL.createObjectURL(
                    new Blob(binaryData, {type: dataType})
                );
                if (file_name) {
                    downloadLink.setAttribute(
                        'download',
                        this.nameToDownload(file_name) + '.mp3'
                    );
                }
                document.body.appendChild(downloadLink);
                downloadLink.click();
            });
    }

    truncate(str: any, n: any) {
        return str.length > n ? str.substr(0, n - 1) + '...' : str;
    }

    getdateupload(time: any) {
        if (time != null) {
            const splitted = time.split('T');
            return splitted[0];
        } else {
            return null;
        }
    }

    LogicalDeleteTranscription(transcription_id: any, name: any) {
        this.transcriptionService
            .LogicalDeleteTranscription(transcription_id)
            .subscribe({
                next: (data) => {
                    if (data.type != 0) {
                        this.status = 'Delete successful';
                        this.transcriptionService.refreshListTranscription();
                        this.notificationService.showInfo(
                            'the file ' + name + ' has been deleted'
                        );
                    }
                },
                error: (error) => {
                    this.errorMessage = error.message;
                    this.toester.error('There was an error!', this.errorMessage);
                },
            });
    }

    Transcript_Now(t: any, event) {
        this.TranscribeNowClicked = true;
        this.transcriptionService
            .transcribe_Now(t.transcription_id, t.speakers)
            .subscribe((data) => {
                if ((data.status == 200)) {
                    this.changeTranscriptionState(t, 'Submitted');
                }
            });
        this.getStateFromSocket();
    }

    /**
     *  will be used in the audio webSocket
     */
    async play(id_file, url) {
        this.pauseClicked = false;
        this.playStreamClicked = true;
        this.stopClicked = false;
        this.idFileToStream = id_file;
        console.log(url);

        setTimeout(() => {
            this.audioStream = document.getElementById(id_file) as HTMLAudioElement;
            console.log('test', this.audioStream);
            this.audioStream.play();
        }, 500);


    }

    pause(id_file) {
        console.log(id_file);
        this.playStreamClicked = false;
        this.pauseClicked = true;
        this.stopClicked = false;
        this.audioStream.pause();
    }

    stop(id_file) {
        this.playStreamClicked = false;
        this.pauseClicked = false;
        this.stopClicked = true;
        // const audioStreamm =<HTMLAudioElement> document.getElementById(id_file) ;
        if (this.audioStream) {
            this.audioStream.pause();
            this.audioStream.currentTime = 0;
        }

    }

    openModelToChangeModel(urlVideo, extension, name, transcriptionModel, uploadingDate,
                           ProcessingDate, TranscriptionTime, speaker, transcription_id, skill_id) {
        this.dialog.open(EditModelComponent, {
            panelClass: 'custom-dialog-containers',
            data: {
                urlVideo: urlVideo,
                extension: extension,
                name: name,
                transcriptionModel: transcriptionModel,
                uploadingDate: uploadingDate,
                ProcessingDate: ProcessingDate,
                TranscriptionTime: TranscriptionTime,
                speaker: speaker,
                transcription_id: transcription_id,
                skill_id: skill_id
            }
        });
    }
}
