import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {AuthService} from '../auth/auth.service';

@Injectable({
    providedIn: 'root',
})
export class AuthGuardService implements CanActivate {
    constructor(private authService: AuthService, private router: Router) {
    }

    canActivate(next: ActivatedRouteSnapshot,
                state: RouterStateSnapshot) {
        if (this.authService.loggedIn()) {
           return this.checkUserLogin(next, state.url);
        } else {
            this.router.navigate(['/login']);
            return false;
        }
    }

    checkUserLogin(route: ActivatedRouteSnapshot, url: any): boolean {
        if (this.authService.loggedIn()) {
            const token = this.authService.getJwtData();
            if (route.data.role && !token.user_type) {
               this.authService.logout();
                return false;
            }
            return true;
        }

        this.router.navigate(['/login']);
        return false;
    }
}
