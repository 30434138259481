import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WebhookDashboardComponent } from './webhook-dashboard/webhook-dashboard.component';
import {ReactiveFormsModule} from '@angular/forms';



@NgModule({
  declarations: [WebhookDashboardComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule
  ]
})
export class WebhookModule { }
