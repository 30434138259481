import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MatDialog} from '@angular/material/dialog';
import {ConfirmModalComponent} from '../confirm-modal/confirm-modal.component';
import {AuthService} from '../../../shared/service/auth/auth.service';
import {Subscription} from 'rxjs';
import {NotificationService} from '../../../shared/service/notification/notification.service';

@Component({
    selector: 'app-user-management',
    templateUrl: './user-management.component.html',
    styleUrls: ['./user-management.component.css']
})
export class UserManagementComponent implements OnInit, OnDestroy {
    formGroup: FormGroup;
    submitted: boolean;
    userRequest;
    userSubscription: Subscription;

    constructor(private formBuilder: FormBuilder,
                private dialog: MatDialog,
                private authUser: AuthService,
                private notificationService: NotificationService
    ) {
    }

  ngOnDestroy(): void {
        this.userSubscription.unsubscribe();
    }

    get f() {
        return this.formGroup.controls;
    }

    ngOnInit(): void {
        this.formGroup = this.formBuilder.group({
            role: ['', Validators.required],
            email: ['', [Validators.required, Validators.email]],
            firstName: ['', [Validators.required, Validators.maxLength(50)]],
            lastName: ['', [Validators.required, Validators.maxLength(50)]],
            password: ['', [Validators.required, Validators.minLength(8)]],
            confirmPassword: ['', [Validators.required, Validators.minLength(8)]],
            organizations: ['', [Validators.required, Validators.maxLength(50)]],
        }, {
            validator: mustMatch('password', 'confirmPassword')
        });
        this.authUser.confirmCreateUserValue(false);
        this.userSubscription = this.authUser.confirmCreateUser$().subscribe(confirm => {
            if (confirm) {
              this.registerUser();
            }
        });

    }

    onSubmit() {
        this.submitted = true;

        // stop here if form is invalid
        if (this.formGroup.invalid) {
            return;
        }

        const formValue = this.formGroup.value;
        this.userRequest = {
            password: formValue['password'],
            email: formValue['email'],
            first_name: formValue['firstName'],
            last_name: formValue['lastName'],
            role: 'user',
            organization_name: formValue['organizations'],
            is_admin: formValue['role'] === 'admin'
        };
        const dialogRef = this.dialog.open(ConfirmModalComponent);

        dialogRef.afterClosed().subscribe((result) => {
            console.log(`Dialog result: ${result}`);
        });
    }

    resetForm() {
        this.submitted = false;
        this.formGroup.reset();
    }

    registerUser() {
      this.authUser.registerUser(this.userRequest).subscribe(data => {
        this.notificationService.showSuccess('User added successfully');
          this.submitted = false;
          this.formGroup.reset();
          this.authUser.confirmCreateUserValue(false);

      }, error => {
          const errorMsg = typeof error.error === 'string' ? error.error : 'Error, please try again';
          this.notificationService.showError(errorMsg);
      });
    }


}

function mustMatch(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {
        const control = formGroup.controls[controlName];
        const matchingControl = formGroup.controls[matchingControlName];
        if (matchingControl.errors && !matchingControl.errors.mustMatch) {
            // return if another validator has already found an error on the matchingControl
            return;
        }
        if (control.value !== matchingControl.value) {
            matchingControl.setErrors({mustMatch: true});
        } else {
            matchingControl.setErrors(null);
        }
    };
}
