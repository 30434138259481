import { Component, Input, OnInit, SimpleChange } from '@angular/core';

@Component({
  selector: 'app-upload-loading',
  templateUrl: './upload-loading.component.html',
  styleUrls: ['./upload-loading.component.css']
})
export class UploadLoadingComponent implements OnInit {

 
  prog: string;
  @Input() progress :any;
  constructor() {
   
  }

  ngOnInit() {}
  ngOnChanges() {
    console.log({ SpinnerChanges: this.progress });
  }
  
}
