import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/shared/service/auth/auth.service';

@Component({
  selector: 'app-alspeech-landing-bar',
  templateUrl: './alspeech-landing-bar.component.html',
  styleUrls: ['./alspeech-landing-bar.component.scss'],
  providers:[AuthService]
})
export class AlspeechLandingBarComponent implements OnInit {
  loggedIn: boolean = false;

  constructor(private router: Router, private authService: AuthService) { }

  ngOnInit(): void {
    this.loggedIn = this.authService.loggedIn();
  }
  goToLogin() {
    this.router.navigate(["login"]);
  }
  goToRegister() {
    this.router.navigate(["register"]);
  }
  logout() {
    this.authService.logout();
  }
  goToHome() {
    if (this.authService.loggedIn()) {
      this.router.navigate(["dashboard"]);
    } else {
      this.router.navigate([""]);
    }

    
  }
  goToDocs(){
    this.router.navigate(["docs"]);
  }
}
