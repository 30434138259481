import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";
import { trigger, transition, animate, style } from "@angular/animations";
import { DomSanitizer } from "@angular/platform-browser";
import { Router } from "@angular/router";
import * as moment from "moment";
import { Subscription } from "rxjs";
import { environment } from "src/environments/environment";
import { AuthService } from "src/shared/service/auth/auth.service";
import { ModalService } from "src/shared/service/modal.service";
import { TrascriptionServiceService } from "src/shared/service/transcriptions/trascription-service.service";
import { SideBarEventService } from "src/shared/service/sideBarEvent/side-bar-event.service";
import { Player } from "@vime/angular";
import { MatSlideToggleChange } from "@angular/material/slide-toggle";
const BFF_URL = environment.BFF_KEY || "http://localhost:8000";
export enum KEY_CODE {
  RIGHT_ARROW = 39,
  LEFT_ARROW = 37,
}
@Component({
  selector: "app-edit-transcriptions-page",
  templateUrl: "./edit-transcriptions-page.component.html",
  styleUrls: ["./edit-transcriptions-page.component.scss"],
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger("slideInOut", [
      transition(":enter", [
        style({ transform: "translateY(-100%)" }),
        animate(
          "0.25s ease-in",
          style({ transform: "translateY(0%)", transition: "0.25s" })
        ),
      ]),
      transition(":leave", [
        animate(
          "0.25s ease-in",
          style({ transform: "translateY(-100%)", transition: "0.25s" })
        ),
      ]),
    ]),
  ],
})
export class EditTranscriptionsPageComponent
  implements OnInit, OnChanges, OnDestroy, AfterViewInit
{
  @ViewChild("player") player!: Player;
  @ViewChild("waveform", { static: false }) waveform: any;
  @ViewChild("testAudior", { static: false }) audioPlayer: ElementRef;
  @ViewChild("testAudioPlayer", { static: false }) testAudioPlayer: ElementRef;
  underlineForWords = false;
  previousUnderlineForWords: any;
  italiqueForWords = false;
  previousitaliqueForWords: any;
  showIcon = true;
  tAudioPlayer: any;
  wordIndex: any = 0;
  urlvideo: any;
  texttotest: any;
  wordstohighlight: any;
  videoVtt: any = "";
  start: boolean = true;
  timer: number;
  timerprogress: any;
  file: any;
  scrollableContainer: HTMLElement;
  responsetranscription: any = "";
  showJsonFormat;
  colorit: boolean = false;
  onpauseaudio: boolean = false;
  playaudiotest: boolean;
  ishttpLoaded = false;
  selectedValue: any = {};
  errorMessage: string = ";";
  speech: string = "";
  cellcolor = "primary";
  checkedmodeltotext: boolean = true;
  testingjson: any;
  speakerCheked = false;
  audiook = false;
  progress: any;
  @ViewChild("scrollPanel", { static: false }) scrollPanel: any;
  @Output() statusClasssimple1event = new EventEmitter<any>();
  @ViewChild("canvas", { static: true })
  isCheckedColor = false;
  spanwordclicked: any;
  ngformstart = false;
  user_id: any;
  audioStart: any;
  audioEnd: any;
  words = "";
  @Input() id: string;
  @Input() element: any;
  getTranscription: Subscription;
  modalIsOpen: boolean;
  statusClasssimple1 = "active";
  uploadstart = "startNotactive";
  clickplay: boolean = false;
  uploading: boolean = false;
  testAudio: any;
  @Input() FileForModal;
  testColor;
  transcription_id;
  id_file_selected;
  name_file_selected;
  name_File: string;
  elementPressed: any;
  @Input() blobUrlofaudio;
  urlAudio;
  endoffile;
  urlWaveForm: any;
  visible = true;
  confCode: any;
  TextStyleingWavePlayer = "textjustifierEdit";
  private lastKnownWordSelectionOffset: number;
  wordsChanged = [];
  Bold: boolean = false;
  iTaliqueCss: boolean = false;
  Underline: boolean = false;
  editedTranscriptionData: any;
  isVisible = "isVisible";
  searchText = "";
  searchText1 = "";
  TextStyleing = "arabicTextEdit";
  skillID = "NotArabic";
  datadDisplayDranscription = "data-display-transcription";
  wordStyleCss = { "background-color": "white", color: "black" };
  boldForWords = true;
  previousValboldForWords: any;
  fromWordId: any;
  toWordID: any;
  currentTime: any = 0;
  speackerData: any;
  filetype: any;
  process_time: string;
  uploadingDate: string;
  ProcessingDate: string;
  model: string;
  segementNumber;
  wordsNumber = 1;
  vmPlayer: any;
  notSelected = true;
  previousIndexValue: any;
  dataInformationAvailable = true;
  constructor(
    private transcriptionService: TrascriptionServiceService,
    public authService: AuthService,
    private modalService: ModalService,
    private el: ElementRef,
    private router: Router,
    private sanitizer: DomSanitizer,
    private sideBarService: SideBarEventService
  ) {
    this.element;
  }
  getwordColors() {
    console.log("test color checking", this.confCode);

    this.WordToHighlightStyle(1);
  }
  WordToHighlightStyle(tag) {
    this.confCode = tag.conf;
    return {
      "text-decoration": this.getAudioConf(this.confCode),
      "text-underline-offset": "3px",
      "text-decoration-thickness": "4px",

      color: "black",
      "border-radius": "5px",
      "padding-left": "3px",
    };
  }
  ngOnInit(): void {
    this.getwordColors();
    this.getSelectionData();
    this.sideBarService.EditTranscriptionOpened();
    this.getInformation();
    this.getSubtitleForVideo();
    console.log("testing the selected item", this.id_file_selected);

    if (this.id_file_selected == undefined) {
      console.log("testing the selected item 0", this.id_file_selected);
      this.id_file_selected = this.transcriptionService.getselectedIdToUpdate();
      console.log("testing the selected item 1", this.id_file_selected);
    }
    if (this.transcription_id == undefined) {
      this.transcription_id =
        this.transcriptionService.getselectedIdTranscriptionToUpdate();
    }
    if (this.name_file_selected == undefined) {
      this.name_file_selected =
        this.transcriptionService.getselectedFileNameToUpdate();
    }
    this.ngformstart = false;
    this.uploadstart = "startNotactive";
    this.user_id = this.getUser();
    this.name_File = this.truncate(this.name_file_selected, 70);
    this.transcriptionService.isUrl().subscribe((url) => {
      this.elementPressed = url;
    });
    this.modalService.isOpen$().subscribe((isOpen) => {
      this.modalIsOpen = isOpen;
    });
    this.playAudio();
    this.getTranscriptionById(this.transcription_id);
    if (this.urlvideo != null) {
      this.testAudio = document.getElementById("testAudior") as HTMLElement;
      this.tAudioPlayer = document.getElementById(
        "testAudioPlayer"
      ) as HTMLElement;
    }
    if (this.elementPressed && this.modalIsOpen) {
      this.ngformstart = false;
      this.testColor = "#fffff";
      this.urlvideo = "";
      this.uploadstart = "startNotactive";
      //  this.getFileToDisplay('')
    }
  }
  getSelectionData() {
    document.addEventListener("selectionchange", () => {
      var test: any = window.getSelection();
      if (test.baseNode.parentElement.id != "") {
        this.notSelected = false;
        this.fromWordId = test.baseNode.parentElement.id;
        this.toWordID = test.focusNode.parentElement.id;
      }
    });
  }
  getStartEndSelectedWords() {
    let debut;
    let fin;
    if (this.fromWordId > this.toWordID) {
      debut = this.toWordID;
      fin = this.fromWordId;
    } else {
      debut = this.fromWordId;
      fin = this.fromWordId;
    }
  }
  getValuesForSelectedItems() {
    if (this.previousValboldForWords === this.wordIndex) {
      this.boldForWords = !this.boldForWords;
    }
    let debut;
    let fin;
    if (this.fromWordId > this.toWordID) {
      debut = this.toWordID;
      fin = this.fromWordId;
    } else {
      debut = this.fromWordId;
      fin = this.toWordID;
    }
    for (let i = debut; i <= fin; i++) {
      this.wordIndex = i;
      if (this.boldForWords) {
        this.previousValboldForWords = i;
        document.getElementById(i + "").style.fontWeight = "bold";
        this.wordstohighlight[i].styleCSS["font-weight"] = "bold";
      } else {
        this.previousValboldForWords = i;
        document.getElementById(i + "").style.fontWeight = "normal";
        this.wordstohighlight[i].styleCSS["font-weight"] = "normal";
      }
    }
  }

  getValuesForSelectedItemsForUnderline() {
    if (this.previousUnderlineForWords === this.wordIndex) {
      this.underlineForWords = !this.underlineForWords;
    }
    let debut;
    let fin;
    if (this.fromWordId > this.toWordID) {
      debut = this.toWordID;
      fin = this.fromWordId;
    } else {
      debut = this.fromWordId;
      fin = this.toWordID;
    }
    for (let i = debut; i <= fin; i++) {
      this.wordIndex = i;
      if (this.underlineForWords) {
        this.previousUnderlineForWords = i;
        document.getElementById(i + "").style.textDecoration =
          "underline green";
        this.wordstohighlight[i].styleCSS["text-decoration"] =
          "underline green";
        this.wordstohighlight[i].styleCSS["text-underline-offset"] = "4px";
      } else {
        this.previousUnderlineForWords = i;
        document.getElementById(i + "").style.textDecoration = "none";
        this.wordstohighlight[i].styleCSS["text-decoration"] = "none";
      }
    }
  }

  getValuesForSelectedItemsForItalique() {
    if (this.previousitaliqueForWords === this.wordIndex) {
      this.italiqueForWords = !this.italiqueForWords;
    }
    let debut;
    let fin;
    if (this.fromWordId > this.toWordID) {
      debut = this.toWordID;
      fin = this.fromWordId;
    } else {
      debut = this.fromWordId;
      fin = this.toWordID;
    }
    for (let i = debut; i <= fin; i++) {
      this.wordIndex = i;
      if (this.italiqueForWords) {
        this.previousitaliqueForWords = i;
        document.getElementById(i + "").style.fontStyle = "italic";
        this.wordstohighlight[i].styleCSS["font-style"] = "italic";
      } else {
        this.previousitaliqueForWords = i;
        document.getElementById(i + "").style.fontStyle = "normal";
        this.wordstohighlight[i].styleCSS["font-style"] = "normal";
      }
    }
  }
  onToggle(event) {
    this.getwordColors();
  }
  getAudioConf(confCode) {
    if (this.isCheckedColor) {
      if (confCode >= 0.6) {
        return "underline  #8dcfaa"; //vert
      } else if (0.4 <= confCode && confCode < 0.6) {
        return "underline  #f88e55"; //jaune
      } else if (confCode < 0.4) {
        return "underline  red"; //red
      }
    } else {
      return "underline #39A9DA";
    }
  }
  ngAfterViewInit(): void {
    console.log("testing the audio player", this.audioPlayer);
  }

  ngOnDestroy(): void {
    this.sideBarService.EditTranscriptionClosed();
    this.urlAudio = "";
    if (this.getTranscription) {
      this.getTranscription.unsubscribe();
    }
    this.urlvideo = "";
    this.uploadstart = "";
    //  this.id_file_selected=''
    this.uploadstart = "startNotactive";
  }

  download_Transcription_By_Id_vtt(transcription_id: any, type: any) {
    this.transcriptionService
      .download_Transcription_By_Id(transcription_id, type)
      .subscribe(
        (response) => {
          if (type == "json") {
            let dataType = response.type;
            let binaryData = [];
            binaryData.push(response);
            let downloadLink = document.createElement("a");
            downloadLink.href = window.URL.createObjectURL(
              new Blob(binaryData, { type: dataType })
            );
            downloadLink.setAttribute(
              "download",
              this.name_file_selected + "." + type
            );
            document.body.appendChild(downloadLink);
            downloadLink.click();
          } else if (type == "vtt") {
            let binaryData = [];
            binaryData.push(response);
            let downloadLink;
            downloadLink = new Blob(binaryData, {
              type: "text/plain; charset=utf-8",
            });
            var a;
            a = this.sanitizer.bypassSecurityTrustUrl(
              URL.createObjectURL(downloadLink)
            );
            this.videoVtt = a;
          }
        },
        (error) => {
          console.log("there is no Transcription", error);
        }
      );
  }
  getSubtitleForVideo() {
    if (this.filetype != "audio") {
      this.download_Transcription_By_Id_vtt(this.transcription_id, "vtt");
    }
  }

  testVisible() {
    this.visible = !this.visible;
    if (this.visible) {
      this.isVisible = "isVisible";
      this.datadDisplayDranscription = "data-display-transcription";
      this.testAudio.currentTime = this.currentTime;
    } else {
      this.isVisible = "isNotVisible";
      this.datadDisplayDranscription = "data-display-transcription-height";
      this.testAudio.currentTime = this.currentTime;
    }
  }

  ngOnChanges(): void {
    let elem = document.querySelector("testAudior");
    console.log("elem", elem);
    console.log("eeseeaqqaaqs", this.testAudio);
    this.testAudio.addEventListener("timeupdate", (currentTime) => {
      this.currentTime = currentTime;
    });
    this.getTranscriptionById(this.transcription_id);
    this.getwordColors();
  }

  formatLabel(value: number) {
    if (value >= 1000) {
      return Math.round(value / 1000) + "%";
    }
    return value;
  }

  onTimeUpdate(event: CustomEvent<number>) {
    // this.testAudio.pause()
    this.timer = event.detail;
    this.currentTime = event.detail;
    //this.player.play()
  }

  getInformation() {
    this.modalService.fileInoformation$().subscribe((test) => {
      if (test.length === 0) {
        this.dataInformationAvailable = false;
        var data = this.transcriptionService.getDataForEditPage();
        var objectData = { data };
        var ar = objectData.data.split(",");
        for (let i = 0; i < ar.length; i++) {
          this.setDataValues(ar);
        }
      } else {
        this.dataInformationAvailable = true;
        setTimeout(() => {
          if (this.dataInformationAvailable) {
            this.transcriptionService.setDataForEditPage(test);
            this.setDataValues(test);
          }
        }, 200);
      }
    });
  }

  setDataValues(ar) {
    this.transcription_id = ar[1];
    this.id_file_selected = ar[0];
    this.name_file_selected = ar[2];
    this.process_time = ar[5];
    this.uploadingDate = ar[6];
    this.ProcessingDate = ar[7];
    this.model = ar[8];
  }
  insertSpaces(string) {
    string = string.replace(/([a-z])([A-Z])/g, "$1 $2");
    string = string.replace(/([A-Z])([A-Z][a-z])/g, "$1 $2");
    return string;
  }

  getUser() {
    let user_id = "test";
    if (this.authService.loggedIn()) {
      const jwtData = this.authService.getJwtData();
      user_id = jwtData.user_id;
    }
    return user_id;
  }

  fileTypeDisplay() {
    if (this.filetype == "audio") {
      this.visible = false;
      this.isVisible = "isNotVisible";
      this.datadDisplayDranscription = "data-display-transcription-height";
      this.showIcon = false;
    } else {
      this.visible = true;
    }
  }

  testPlay($event) {
    /*if($event.returnValue){
  this.testAudio.currentTime = this.currentTime
  this.testAudio.play()
}*/
  }

  download_Transcription_By_Id(
    transcription_id: any,
    file_name: any,
    type: any
  ) {
    let binaryData = [];
    binaryData.push(this.editedTranscriptionData);
    let downloadLink = document.createElement("a");
    downloadLink.href = window.URL.createObjectURL(
      new Blob(binaryData, { type: "application/json" })
    );
    downloadLink.setAttribute("download", this.name_file_selected + "." + type);
    document.body.appendChild(downloadLink);
    downloadLink.click();
  }

  goToProducts() {
    this.router.navigate(["/dashboard/ProductsAlspeech"]);
  }

  download_Transcription_Json_By_Id() {
    this.download_Transcription_By_Id(
      this.transcription_id,
      this.name_file_selected,
      "json"
    );
  }

  removeForbiddenCharacters(input) {
    let forbiddenChars = ['"', ","];

    for (let char of forbiddenChars) {
      input = input.split(char).join(" ");
    }
    return input;
  }

  playAudio() {
    this.urlvideo = `${BFF_URL}/streaming_media_file?file_id=${this.id_file_selected}&user_id=${this.user_id}`;
    if (this.urlvideo) {
      this.urlvideo;
      setTimeout(() => {
        this.audiook = true;
        this.testAudio = <HTMLAudioElement>(
          document.getElementById("testAudior")
        );
        this.vmPlayer = document.getElementById("player");
        this.testAudio.addEventListener("timeupdate", () => {
          this.timer = this.testAudio.currentTime;
        });
      }, 1000);
    }
    if (this.audiook == true) {
      this.testAudio = <HTMLAudioElement>document.getElementById("testAudior");
    }
    let elem = document.querySelector("testAudior");
    console.log("elem", elem);
    this.testAudio = <HTMLAudioElement>document.getElementById("testAudior");
    console.log("show the audio url", this.testAudio);
  }

  getTranscriptionById(id_transcription) {
    this.transcriptionService
      .Get_Transcription_By_Id(id_transcription)
      .subscribe((response) => {
        if (response == null) {
          this.texttotest = "";
        } else {
          this.filetype = response.file_type;
          this.skillID = response.skill_name;
          if (this.skillID == "ASR Arabic Model") {
            this.TextStyleing = "arabicTextEdit";
          } else {
            this.TextStyleing = "normalText";
          }
          if (response.edited_transcription == null) {
            this.cssForWaveText();
            if (this.speakerCheked) {
              this.getKeyFromResponse(response.transcription.speakers);
            } else {
              this.getKEy(response.transcription.words);
            }
            this.segementNumber = response.transcription.speakers.length;
            this.texttotest = this.highlight_word(this.timer);
          } else {
            this.getKEy(response.edited_transcription.words);
            this.segementNumber = response.edited_transcription.speakers.length;
            this.SetHeighlightedWords();
          }
        }
      });
  }
  cssForWaveText() {
    if (this.skillID == "ASR Arabic Model") {
      this.TextStyleingWavePlayer = "arabicTextEdit";
    } else {
      this.TextStyleingWavePlayer = "textjustifierEdit";
    }
  }
  getKeyFromResponse(data) {
    this.speackerData = [];
    Object.keys(data).forEach((key) => {
      this.speackerData.push({
        spk: data[key].speaker_id,
        color: this.randomColor(this.authService.encrypt(data[key].speaker_id)),
        speakers: data[key].words,
      });
    });
    return this.speackerData;
  }
  getKEy(data) {
    this.wordstohighlight = [];
    Object.keys(data).forEach((key) => {
      this.wordstohighlight.push({
        conf: data[key].conf,
        end: data[key].end,
        start: data[key].start,
        statue: this.colorit,
        word: "&nbsp; " + data[key].word,
        styleCSS: { "background-color": "white", color: "black" },
      });
    });
    this.wordsNumber = this.wordstohighlight.length;
    return this.wordstohighlight;
  }
  randomColor(str) {
    var hash = 0;
    for (var i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    var colour = "#";
    for (var i = 0; i < 3; i++) {
      var value = (hash >> (i * 8)) & 0xff;
      colour += ("00" + value.toString(16)).substr(-2);
    }
    return colour;
  }
  ngDoCheck() {
    //  this.scrollToHighlight();
  }
  speakerColor(tag) {
    if (this.skillID === "ASR Arabic Model") {
      return {
        "color": "white",
        "background-color": tag,
        "font-style": "italic;",
        "font-size": "20px",
        "border-radius": "77px",
        "margin-left": "1%",
        "padding-right": "1%",
        "padding-left": "0px",
        "width": "20px",
      };
    } else {
      return {
        "color": "white",
        "background-color": tag,
        "font-size": "20px",
        "border-radius": "77px",
        "margin-right": "2%",
        "padding-right": "1%",
        "padding-left": "1%",
      };
    }
  }
  SetHeighlightedWords() {
    setTimeout(() => {
      this.testingjson = JSON.stringify(this.wordstohighlight);
      this.texttotest = this.highlight_word(this.timer);
      this.editedTranscriptionData = {
        edited_transcription: {
          words: this.texttotest,
        },
      };
    }, 500);
  }
  changedSpeaker($event: MatSlideToggleChange) {
    this.getTranscriptionById(this.transcription_id);
  }
  addBoldToWord() {
    if (!this.notSelected) {
      this.getValuesForSelectedItems();
    } else {
      if (this.previousIndexValue === this.wordIndex) {
        this.Bold = !this.Bold;
      }

      if (this.Bold) {
        this.previousIndexValue = this.wordIndex;
        document.getElementById(this.wordIndex + "").style.fontWeight = "bold";
        this.wordstohighlight[this.wordIndex].styleCSS["font-weight"] = "bold";
      } else {
        this.previousIndexValue = this.wordIndex;
        document.getElementById(this.wordIndex + "").style.fontWeight =
          "normal";
        this.wordstohighlight[this.wordIndex].styleCSS["font-weight"] =
          "normal";
      }
    }
  }

  addUnderlineToWord() {
    if (!this.notSelected) {
      this.getValuesForSelectedItemsForUnderline();
    } else {
      if (this.previousUnderlineForWords === this.wordIndex) {
        this.Underline = !this.Underline;
      }

      if (this.Underline) {
        document.getElementById(this.wordIndex + "").style.textDecoration =
          "underline green";
        this.wordstohighlight[this.wordIndex].styleCSS["text-decoration"] =
          "underline green";
        this.wordstohighlight[this.wordIndex].styleCSS[
          "text-underline-offset"
        ] = "4px";
      } else {
        document.getElementById(this.wordIndex + "").style.textDecoration =
          "none";
        this.wordstohighlight[this.wordIndex].styleCSS["text-decoration"] =
          "none";
      }
    }
  }

  styleBoldToWord() {
    this.Bold = !this.Bold;
    if (this.Bold) {
      return { "font-weight": "bold" };
    } else {
      return { "font-weight": "normal" };
    }
  }

  addItaliqueToWord() {
    if (!this.notSelected) {
      this.getValuesForSelectedItemsForItalique();
    } else {
      if (this.previousIndexValue === this.wordIndex) {
        this.iTaliqueCss = !this.iTaliqueCss;
      }

      if (this.iTaliqueCss) {
        document.getElementById(this.wordIndex + "").style.fontStyle = "italic";
        this.wordstohighlight[this.wordIndex].styleCSS["font-style"] = "italic";
      } else {
        document.getElementById(this.wordIndex + "").style.fontStyle = "normal";
        this.wordstohighlight[this.wordIndex].styleCSS["font-style"] = "normal";
      }
    }
  }
  getColor(statue: boolean, index, tag): object | undefined {
    this.confCode = tag.conf;
    if (!statue) {
      if (index === this.wordIndex) {
        this.wordstohighlight[index].styleCSS = {
          "background-color": "white",
          color: "black",
          "font-style": this.addItaliqueToWord(),
          "font-weight": this.addBoldToWord(),
          "text-decoration": this.addUnderlineToWord(),
        };
        return this.wordstohighlight[index].styleCSS;
      } else {
        return this.wordstohighlight[index].styleCSS;
      }
    } else {
      if (index != undefined) {
        return {
          "text-decoration": this.getAudioConf(this.confCode),
          "text-underline-offset": "3px",
          "text-decoration-thickness": "4px",
          color: "black",

          "border-radius": "5px",
          "padding-left": "3px",
        };
      } else {
        return this.wordstohighlight[index].styleCSS;
      }
    }
  }

  trackByStartTime(index, item) {
    return item.start;
  }

  getprogress(event: any) {
    this.progress = event;
  }

  getaudiotimeron(tag: any, index) {
    //  this.urlvideo = "../../"
    this.notSelected = true;
    this.wordIndex = index;
    this.spanwordclicked = tag;
    this.timer = this.spanwordclicked.start;
    // console.log("donee", this.waveform.setRegionStart(this.timer));
    this.player.currentTime = this.timer;
    this.testAudio.currentTime = this.timer;
    //  this.waveform.setRegionStart;
  }
  onBlur($event): void {}
  isLastWord(tag: any) {
    this.confCode = tag.conf;
    return tag.start <= this.timer && tag.end >= this.timer;
  }
  getuploadstate($event) {
    this.uploading = $event;
  }
  getstartstate($event) {
    this.start = $event;
  }
  onEdit(tag, i, $event) {
    $event.preventDefault();
    $event.stopPropagation();
    let textEdited = document.getElementById(i).innerText;
    this.texttotest[i].word = textEdited;
    // this.wordsChanged = this.texttotest.word
    for (let j = 0; j < this.texttotest.length; j++) {
      var test = this.texttotest[j].word;
      JSON.stringify(test).replace('"', " ");
      this.wordsChanged.push(test);
    }

    let x = this.removeForbiddenCharacters(JSON.stringify(this.wordsChanged));
    this.words = x;
  }
  onEditc(tag, i) {
    let textEdited = document.getElementById(i).innerText;
    this.texttotest[i].word = textEdited;
  }

  onCellKeyPress(e) {
    var keyPressed = e.event.key;
    if (keyPressed === "s") {
      var rowNode = e.node;
      var newSelection = !rowNode.selected;
      rowNode.setSelected(newSelection);
    }
  }
  onCursorChange(e: KeyboardEvent, tag, index) {
    const currentSelection = document.getSelection();
    const textValue = currentSelection.anchorNode.nodeValue;
    const anchorOffset = currentSelection.anchorOffset;
    const isStartReached =
      anchorOffset == 1 && anchorOffset === this.lastKnownWordSelectionOffset;
    const isEndReached = textValue.length == anchorOffset;
    console.log("start reached ", isStartReached);
    console.log("end reached ", isEndReached);
    if (isStartReached || isEndReached) {
      const spanElement = e.target as HTMLSpanElement;
      const nextWordIndex = isEndReached ? index + 1 : index - 1;
      const siblin: any = isEndReached
        ? spanElement.nextSibling
        : spanElement.previousSibling;
      this.getaudiotimeron(this.texttotest[nextWordIndex], null);
      siblin.focus();
      isStartReached && this.setSelectionOffSetInTheEnd(siblin);
      this.lastKnownWordSelectionOffset = null;
      return;
    }
    this.lastKnownWordSelectionOffset = anchorOffset;
  }

  setSelectionOffSetInTheEnd(sibling: HTMLSpanElement) {
    const range = document.createRange();
    range.selectNodeContents(sibling);
    range.collapse(false);
    const selection = document.getSelection();
    selection.removeAllRanges();
    selection.addRange(range);
  }

  onCellKeyDown(e) {
    console.log("onCellKeyDown", e);
  }
  onTrackRendered($event) {
    console.log("this is time for waveform rendering", $event);
    if ($event != 0 && this.urlvideo) {
      this.ngformstart = true;

      this.uploadstart = "startactive";
      this.getTranscription.unsubscribe();
    }
  }

  onPlayButtonClick() {
    this.waveform.play();
  }

  onPauseButtonClick() {
    this.waveform.pause();
  }

  onPlayandOnpauseClick() {
    if (!this.clickplay) {
      this.onPlayButtonClick();
    } else {
      this.onPauseButtonClick();
    }
    this.clickplay = !this.clickplay;
  }

  highlight_word(time: any) {
    const array: any[] = [];
    const arraystart: any[] = [];
    const arrayend: any[] = [];
    if (this.speakerCheked) {
      this.wordstohighlight = this.speackerData;
    }
    for (let i = 0; i < this.wordstohighlight.length; i++) {
      array[i] = this.wordstohighlight[i];
      arraystart[i] = this.wordstohighlight[i].start;
      arrayend[i] = this.wordstohighlight[i].end;
      if (arraystart[i] <= time && arrayend[i] >= time) {
        this.wordstohighlight[i].statue = true;
        let _lastTime;
        setTimeout(() => {
          this.wordstohighlight[i].statue = false;
          _lastTime =
            this.wordstohighlight[i].end - this.wordstohighlight[i].start;
        }, Math.abs(this.wordstohighlight[i].end - this.wordstohighlight[i].start) * 1000);
      }
    }
    console.log("this is css to add", array);
    return array;
  }
  saveEditedTranscription() {
    console.log("this is edited transcription after save button pressed");
    console.log("testing text changed a", this.texttotest);
    console.log("jjjjj", this.transcription_id);

    console.log("testing text changed", this.wordsChanged);
    this.editedTranscriptionData = {
      edited_transcription: {
        text: this.words,
        words: this.texttotest,
      },
    };
    this.transcriptionService
      .editTranscription(this.editedTranscriptionData, this.transcription_id)
      .subscribe((test) => console.log(test));
  }
  scrollToHighlight() {
    if (!this.scrollPanel) return;

    const el: any = document.querySelector(".highlighted");
    console.log("this is el", el, { ctnr: this.getScrollableContent() });
    const scrollableContainer = this.getScrollableContent();
    const clientHeight = (el && el.offsetTop) || null;
    console.log("achrefff is testing here", clientHeight);
    if (clientHeight) scrollableContainer.scrollTop = clientHeight;
  }

  getScrollableContent() {
    if (!this.scrollableContainer || !this.scrollableContainer.isConnected) {
      this.scrollableContainer =
        this.scrollPanel.el.nativeElement.querySelector(
          ".p-scrollpanel-content"
        );
    }
    return this.scrollableContainer;
  }

  editTranscription() {
    console.log("this is Transcription edited");
  }

  additemtowortds(words: Array<any>, success: boolean) {
    for (var i = 0; i < words.length; i++) {
      words[i].statue = success;

      // Add "success": 2 to all objects in array
    }
  }

  testt(time: any) {
    this.timer = time.srcElement.currentTime;
  }

  testvideo(time: any) {
    this.timer = time.time;

    if (this.waveform != null) {
      console.log(" region timer changed", time);
      this.timerprogress = (100 * this.timer) / this.waveform.region.end;
      this.audioStart = this.waveform.region.start;
      var display = moment()
        .startOf("day")
        .seconds(this.timer)
        .format("H:mm:ss");
      this.audioStart = display;
      if (time.progress >= 100) {
        this.clickplay = false;
      }
    }

    this.scrollToHighlight();
  }

  onRegionChange($event) {
    console.log("region changed", $event.end);
    this.endoffile = $event.end;
  }

  close(): void {
    this.modalService.ModalStateisclosed();
  }

  cancel(): void {
    this.close();
  }

  truncate(str: any, n: any) {
    if (str) {
      return str.length > n ? str.substr(0, n - 1) + "..." : str;
    }
  }
}
