import {Component, EventEmitter, HostListener, Input, OnChanges, OnInit, Output, ViewChild, ViewEncapsulation} from '@angular/core';
import {Router} from '@angular/router';
import {SideBarEventService} from 'src/shared/service/sideBarEvent/side-bar-event.service';
import {AuthService} from '../../../shared/service/auth/auth.service';

@Component({
    selector: 'app-side-bar',
    templateUrl: './side-bar.component.html',
    styleUrls: ['./side-bar.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class SideBarComponent implements OnChanges, OnInit {
    Productclicked = 'activet';
    ProductclickedDiv = 'activeDiv';
    productClicked = true;
    contactUsClickedCss = 'side-bar-icon';
    contactUsclickedDiv = 'NotactiveDiv';
    contactUSClicked = false;
    textdashboardstyle = 'textGras';
    textContactstyle = 'side-bar-link mx-3 ';
    @Input() toggleSide;
    isSelected = 0;

    @Output() isProductClicked = new EventEmitter<any>();
    @Output() isCategorieClicked = new EventEmitter<any>();

    @ViewChild('sidenav', {static: true}) public el: any;
    menu = [
        {value: 'Dashboard', icon: 'Home.svg', icons: 'HomeBlanc.svg', route: '/dashboard/Products'},
        {value: 'Products', icon: 'ProductNormal.svg', icons: 'ProductBlanc.svg', route: '/dashboard/Products'},
        {value: 'History', icon: 'Historysvg.svg', icons: 'DiscoveryBlanc.svg', route: '/dashboard/history'},
        {value: 'Analytics', icon: 'Analyticsvg.svg', icons: 'GraphBlanc.svg', route: '/dashboard/analytic'},
        {value: 'Notification', icon: 'Notification.svg', icons: 'notificationwhite.svg', route: '/dashboard/notification'},
        {value: 'Payment', icon: 'Wallet.svg', icons: 'Wallet.svg', route: '/dashboard/payment'},
        {value: 'Contact us', icon: 'Contact.svg', icons: 'Contact.svg', route: '/dashboard/contact'},
        {value: 'Webhook', icon: 'Historysvg.svg', icons: 'Historysvg.svg', route: '/dashboard/webhook'}
    ];
    adminMenu = [
        {value: 'Admin View', icon: 'Settingnav.svg', icons: 'Settingnav.svg', route: '/admin'},
        {value: 'User Management', icon: 'Settingnav.svg', icons: 'Settingnav.svg', route: '/admin/user'}
    ];

    constructor(private router: Router, private authService: AuthService,
                private sideBarService: SideBarEventService) {
    }

    @HostListener('swiperight', ['$event'])
    public swipePrev(event: any) {
        this.el.show();
    }

    ngOnInit(): void {
        const token = this.authService.getJwtData();
        if (token.user_type) {
            this.menu =  this.menu.concat(this.adminMenu);
        }
        this.sideBarService
            .stateSideBar$()
            .subscribe((sideBarstate) => {
                console.log(sideBarstate);
            });
        this.sideBarService
            .stateSideBarselected$()
            .subscribe((item) => {

                console.log('test', item);
                this.isSelected = item;

            });

    }


    selectItem(i) {
        this.isSelected = i;
        this.sideBarService.returnSelected(i);
    }

    goToDashboard() {
        this.router.navigate(['dashboard']);
    }

    goToUpload() {
        this.router.navigate(['demo']);
    }

    signOut() {
        this.authService.logout();
    }

    changeDisplay() {
        if (this.toggleSide) {
            this.toggleSide = false;
            this.sideBarService.StateClosed();

        } else if (!this.toggleSide) {
            this.toggleSide = true;
            this.sideBarService.StateOpen();

        }
        //  this.changeSideBarDisplay.emit(this.toggleSide);
    }

    ngOnChanges(): void {
        this.sideBarService
            .stateSideBar$()
            .subscribe((sideBarstate) => {
                console.log(sideBarstate);
            });
    }
}
