import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";

import { AuthService } from "src/shared/service/auth/auth.service";

@Component({
  selector: "app-uploadcomponent",
  templateUrl: "./uploadcomponent.component.html",
  styleUrls: ["./uploadcomponent.component.scss"],
})
export class UploadcomponentComponent implements OnInit, OnDestroy {
  panelOpenState = false;
  file: any = [];
  @Input() selectedmodelchanged;

  loading: boolean = true;
  @Input() uploadloading;
  @Input() msg = "";
  tst: string;
  @Input() uploadcompleted;
  @Input() fileIsReady;
  @Input() progress;
  @Output() sendFile = new EventEmitter<any>();
  @ViewChild("content", { static: false }) el!: ElementRef;
  @ViewChild("fileDropRef", { static: false }) fileDropEl: any;
  showFile: boolean = false;
  constructor(public authService: AuthService) {}
  ngOnDestroy(): void {
    this.file = [];
    this.showFile = false;
  }
  ngOnInit(): void {
    this.file = [];
    this.showFile = false;
  }
  ngAfterViewInit(): void {
    this.file = [];
    this.showFile = false;
  }
  checkType(mimeType) {
    const mimeTypeNotAudio = mimeType.match(/audio\/*/) == null 
    const mimeTypeNotVideo = mimeType.match(/video\/mp4/) == null 
    if (mimeTypeNotAudio && mimeTypeNotVideo ) {
      this.msg = "Only audio and video  files are supported";
      return false;
    }
    return true;
  }

  fileBrowseHandler($event) {
    this.file = $event.target.files[0];
    if (this.checkType(this.file.type)) {
      let reader = new FileReader();
      reader.readAsDataURL(this.file);
      this.sendFile.emit(this.file);
      reader.onload = () => {
        this.msg = "";
        this.showFile = true;
      };
      if (!this.selectedmodelchanged) {
        this.tst = "please choose a model";
      } else {
        this.tst = "";
      }
    }
  }
}
